import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import CaseListPage from '@/case-list/CaseListPage.vue';
import $a from '@/common/services/analytics/analytics';
import { authGuardFactory, authService, legaliAdminAuthGuardFactory } from '@/common/services/auth/auth.service';
import { PERMISSIONS } from '@/common/services/auth/permissions';
import { withoutInvalidProps } from '@/common/services/common.utils';
import logger from '@/common/services/logging';

const routes: RouteRecordRaw[] = [
  {
    name: 'root',
    path: '/',
    component: () => import('@/app/components/EmptyView.vue'),
    beforeEnter: async () => {
      const tenantCanonicalName = await authService.getDefaultTenantCanonicalName();
      if (!tenantCanonicalName) return false;

      await authService.authorize(tenantCanonicalName);

      if (authService.hasRole('EXTERNAL_BASIC_ROLE') || authService.hasRole('EXTERNAL_COORDINATOR_ROLE')) {
        return {
          name: 'requests',
          params: {
            tenant: tenantCanonicalName,
          },
        };
      }

      return {
        name: 'case-list',
        params: {
          tenant: tenantCanonicalName,
        },
      };
    },
  },
  {
    name: 'callback',
    path: '/callback',
    beforeEnter: async () => {
      logger.info('Handle auth callback');
      const path = await authService.handleCallback();
      if (!path) {
        logger.warn(`Invalid path returned: ${path}`);
        appService.accessDenied();
        return false;
      }
      logger.info(`Continuing to path: ${path}`);
      return path;
    },
    component: () => import('@/app/components/EmptyView.vue'),
  },
  {
    name: 'case-list',
    path: '/c/:tenant',
    // NOTe(dp): Static component import causes jest to fail; while dynamic import causes FE to fail on first hot reload
    // TODO(LEG-6294): remove ignore comments when migrated from jest to vitest
    // eslint-disable-next-line
    // @ts-ignore
    component: CaseListPage,
    meta: { title: () => $t('CaseList.title') },
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_LIST),
  },
  {
    name: 'requests',
    path: '/c/:tenant/requests',
    component: () => import('@/requests/TicketsOverviewPage.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_COLLABORATION_OVERVIEW),
    meta: { title: () => $t('Tickets.requestsOverview') },
  },

  {
    name: 'case',
    path: '/c/:tenant/case/:caseId',
    component: () => import('@/case-detail/CaseDetailPage.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_DETAIL),
    // page title is set from component
  },
  // handle legacy links with `initialDocId` and `initialPage` being params
  // change date: November 6, 2024
  {
    path: '/c/:tenant/case/:caseId/:initialDocId/:initialPage?',
    redirect(to) {
      // build query from params with valid values only
      const query = withoutInvalidProps({
        initialDocId: to.params.initialDocId,
        initialPage: to.params.initialPage,
      });
      return {
        name: 'case',
        params: { tenant: to.params.tenant, caseId: to.params.caseId },
        query,
      };
    },
  },

  {
    name: 'notebook',
    path: '/c/:tenant/notebook/:caseId/:notebookId/:contextId',
    props: true,
    component: () => import('@/case-detail/subviews/notebook/DetachedNotebookWindow.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_DETAIL),
    // page title is set from component
  },
  {
    name: 'ticket',
    path: '/c/:tenant/ticket/:caseId/:ticketId/:contextId',
    props: true,
    component: () => import('@/case-detail/subviews/collaboration/components/TicketDetailViewDetached.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_DETAIL_COLLABORATION),
    // page title is set from component
  },
  {
    name: 'copilot',
    path: '/c/:tenant/copilot/:caseId/:contextId',
    props: true,
    component: () => import('@/case-detail/subviews/copilot/DetachedCopilotWindow.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_DETAIL),
  },
  {
    name: 'explorer',
    path: '/c/:tenant/explorer/:caseId?/:initialDocId?/:initialPage?',
    component: () => import('@/case-explorer/CaseExplorerPage.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_DETAIL),
  },
  {
    name: 'organization',
    path: '/c/:tenant/org',
    component: () => import('@/workspace-admin/organization/OrganizationPage.vue'),
    meta: { title: () => $t('Organization.title') },
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_ORGANIZATION_SETTINGS),
  },
  {
    name: 'settings',
    path: '/c/:tenant/settings',
    component: () => import('@/workspace-admin/settings/SettingsPage.vue'),
    meta: { title: () => $t('Settings.title') },
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_WORKSPACE_SETTINGS),
    children: [
      {
        name: 'settings-workspace',
        path: 'workspace',
        component: () => import('@/workspace-admin/settings/workspace/WorkspaceSettings.vue'),
      },
      {
        name: 'settings-features',
        path: 'features',
        component: () => import('@/workspace-admin/settings/features/FeatureSettings.vue'),
      },
      {
        name: 'settings-integration',
        path: 'integration',
        component: () => import('@/workspace-admin/settings/integration/IntegrationSettings.vue'),
      },
      {
        name: 'settings-mappings',
        path: 'mappings',
        component: () => import('@/workspace-admin/settings/mappings/MappingsSettings.vue'),
      },
      {
        name: 'settings-branding',
        path: 'branding',
        component: () => import('@/workspace-admin/settings/branding/BrandingSettings.vue'),
        beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
      },
      {
        name: 'settings-users',
        path: 'users',
        component: () => import('@/workspace-admin/settings/user/UsersSettings.vue'),
        beforeEnter: authGuardFactory(PERMISSIONS.USER_CRUD),
      },
      {
        name: 'settings-danger-zone',
        path: 'danger-zone',
        component: () => import('@/workspace-admin/settings/danger-zone/DangerZone.vue'),
        beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
      },
      {
        name: 'settings-data-labeling',
        path: 'data-labeling',
        component: () => import('@/workspace-admin/settings/data-labeling/DataLabeling.vue'),
        beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
      },
    ],
  },
  {
    name: 'user-settings',
    path: '/c/:tenant/user-settings',
    component: () => import('@/workspace-admin/settings/user/UserCustomSettings.vue'),
    beforeEnter: authGuardFactory(),
    meta: { title: () => $t('Settings.title') },
  },
  {
    name: 'audit-log',
    path: '/c/:tenant/audit-log',
    component: () => import('@/workspace-admin/audit/AuditLogPage.vue'),
    meta: { title: () => $t('Audit.title') },
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_AUDIT_LOG),
  },
  {
    name: 'reporting',
    path: '/c/:tenant/reporting',
    component: () => import('@/workspace-admin/reporting/ReportingPage.vue'),
    meta: { title: () => $t('Report.title') },
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_REPORTING),
  },
  {
    name: 'analytics',
    path: '/c/:tenant/analytics',
    component: () => import('@/workspace-admin/analytics/AnalyticsPage.vue'),
    meta: { title: () => $t('App.Bar.TenantMenu.analytics') },
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_ANALYTICS),
  },
  {
    name: 'similar',
    path: '/c/:tenant/similar/:caseId/',
    component: () => import('@/similar-cases/SimilarCasesPage.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.ACCESS_CASE_DETAIL),
  },

  // ADMIN / DEMO
  {
    name: 'split',
    path: '/c/:tenant/split/:caseId/:sourceFileId',
    component: () => import('@/legali-admin/splitting/SplitPage.vue'),
    props: true,
    beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
  },
  {
    name: 'workbench',
    path: '/c/:tenant/workbench',
    component: () => import('@/legali-admin/workbench/WorkbenchPage.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
  },
  {
    name: 'consistency',
    path: '/c/:tenant/consistency',
    component: () => import('@/legali-admin/consistency/ConsistencyPage.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
  },
  {
    name: 'workbench-split',
    path: '/c/:tenant/workbench-split/:caseId/:sourceFileId',
    component: () => import('@/legali-admin/workbench/WorkbenchSplitPage.vue'),
    props: true,
    beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
  },
  {
    name: 'create-workspace',
    path: '/a/create-workspace',
    component: () => import('@/legali-admin/create-workspace/CreateWorkspacePage.vue'),
    beforeEnter: legaliAdminAuthGuardFactory(),
  },
  {
    name: 'transfer',
    path: '/c/:tenant/transfer',
    component: () => import('@/case-list/components/TransferDialog.vue'),
    beforeEnter: authGuardFactory(PERMISSIONS.LEGALI_ADMIN),
  },

  // PUBLIC
  {
    name: 'sharing',
    path: '/c/:tenant/sharing/:exportId',
    props: true,
    component: () => import('@/sharing/SharingPage.vue'),
    meta: { isPublic: true }, // page title is set from component
  },

  {
    name: 'empty',
    path: '/c',
    component: () => import('@/app/components/EmptyView.vue'),
  },
  {
    name: 'catch-all',
    path: '/:_(.*)',
    beforeEnter: () => {
      logger.warn('Unrecognized path, status set to access denied');
      appService.accessDenied();
    },
    component: () => import('@/app/components/EmptyView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  appService.setLoading(true);
});

router.afterEach((to, from) => {
  // dynamic page title
  const titleParts: string[] = [];
  if (to.meta.title) {
    // @ts-expect-error it is a function
    titleParts.push(to.meta.title());
  }
  const tenant = authService.state.data?.tenant;
  if (tenant?.name) {
    titleParts.push(tenant.name);
  }
  appService.setTitle(to.name as string, titleParts);

  appService.setLoading(false);
  if (config.INTERCOM.ENABLED && authService.state.isLoggedIn) {
    // @ts-expect-error: Intercom injected manually
    window.Intercom('update', {
      user_id: authService.state.userId,
    });
  }
  if (authService.state.isLoggedIn) {
    const prevRouteKey = $a.getRouteKey(from.name);
    const toRouteKey = $a.getRouteKey(to.name);
    if (prevRouteKey !== toRouteKey) {
      $a.l(`PAGE_${toRouteKey}`, { page: toRouteKey });
    }
  }
});
export default router;
