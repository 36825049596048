import { Core, WebViewerInstance } from '@pdftron/webviewer';
import Color from 'color';

import diagnosisService from '@/case-detail/subviews/diagnosis/services/diagnosis.service';
import { isDiagnosisCodeMatchesFilter } from '@/case-detail/subviews/document/services/document.helper';
import { API } from '@/common/types/api.types';

export type CreateAnnotationOptions = {
  parentId: string;
  author?: string;
  page: number;
  color: string;
  coords?: string | null;
  rect?: string | null;
  quads?: string | null;
  opacity: number;
  contents?: string;
};

class PdftronAnnotationService {
  webViewer: WebViewerInstance | null;

  CUSTOM_DATA_ANNOTATION_ID = 'annotation-id';

  CUSTOM_DATA_ANNOTATION_KEY = 'annotation-key';

  DEFAULT_ANNOTATION_OPACITY = 1.0;

  XFDF_ANNOTATION_TAGS = ['highlight', 'underline', 'strikeout', 'text'];

  constructor() {
    this.webViewer = null;
  }

  init(webViewer: WebViewerInstance) {
    this.webViewer = webViewer;
  }

  clear() {
    this.webViewer = null;
  }

  /** HELPER */

  getAnnotations() {
    if (!this.webViewer) return [];
    const { annotationManager } = this.webViewer.Core;
    return annotationManager.getAnnotationsList();
  }

  getAnnotationById(annotationId: string) {
    const annotations = this.getAnnotations();
    return annotations.find((a) => a.getCustomData(this.CUSTOM_DATA_ANNOTATION_ID) === annotationId);
  }

  getSelectedAnnotation() {
    if (!this.webViewer) return null;
    const selectedAnnotations = this.webViewer.Core.annotationManager.getSelectedAnnotations();
    if (selectedAnnotations.length > 0) {
      return selectedAnnotations[0];
    }
    return null;
  }

  getSelectedAnnotationId() {
    const selectedAnnotation = this.getSelectedAnnotation();
    if (!selectedAnnotation) return null;

    return selectedAnnotation.getCustomData(this.CUSTOM_DATA_ANNOTATION_ID);
  }

  getSelection() {
    if (!this.webViewer) return null;
    const selectedText = this.webViewer.Core.documentViewer.getSelectedText();
    /**
     * getSelectedTextQuads returns an object with page numbers as keys and an array of quads as values
     * Quads objects look like this:
     * {x1:264, x2:337.656, x3:337.656, x4:264, y1:111.36000000000001, y2:111.36000000000001, y3:95.36000000000001, y4:95.36000000000001}
     */
    const selectedTextQuads = this.webViewer.Core.documentViewer.getSelectedTextQuads();
    const page = parseInt(Object.keys(this.webViewer.Core.documentViewer.getSelectedTextQuads()).slice(-1)[0], 10);
    const quads = selectedTextQuads[page];

    return {
      contents: selectedText,
      page, // page is 1-based
      quads,
      coords: quads ? this.parseQuadsToCoords(quads) : '',
    };
  }

  getAnnotationId(annotation: Core.Annotations.Annotation) {
    if (!annotation) return null;
    return annotation.getCustomData(this.CUSTOM_DATA_ANNOTATION_ID);
  }

  getAnnotationKey(annotation: Core.Annotations.Annotation) {
    if (!annotation) return null;
    return annotation.getCustomData(this.CUSTOM_DATA_ANNOTATION_KEY);
  }

  getAnnotationIndexById(annotationId: string) {
    const annotations = this.getAnnotations();
    for (let i = 0; i < annotations.length; i++) {
      if (this.getAnnotationId(annotations[i]) === annotationId) {
        return i;
      }
    }

    return -1;
  }

  matchesIcd10ChapterFilter(annotationKey: string, filterValue: string) {
    const prefix = 'MEDICAL_ICD10_';
    if (!annotationKey.startsWith(prefix) || !filterValue.startsWith(prefix) || !filterValue.includes('-')) {
      return false;
    }

    const codeToVerify = annotationKey.slice(prefix.length);
    const filterCode = filterValue.slice(prefix.length);
    return isDiagnosisCodeMatchesFilter(codeToVerify, filterCode);
  }

  getNextAnnotationIndexByPrefix(index: number, annotationKeyPrefix: string, annotationQualifications?: string[]) {
    const annotations = this.getAnnotations();

    const startingIndex = index + 1 >= annotations.length ? 0 : index + 1;

    // define indexes to loop over
    // NOTE(ndv): starting from index + 1, then looping to the end of the array, then looping from the beginning to index
    const indexes = [
      ...Object.keys(annotations)
        .slice(startingIndex, annotations.length)
        .map((i) => parseInt(i, 10)),
      ...Object.keys(annotations)
        .slice(0, startingIndex)
        .map((i) => parseInt(i, 10)),
    ];

    // NOTE(ndv): a bit of a hack, but it works
    // if the annotationKeyPrefix ends in '_', the trailing '_' char is removed using a negative lookahead regex, e.g. 'USER_COMMENT_' becomes 'USER_COMMENT'
    // otherwise, then the annotationKey must match exactly
    const cleanedAnnotationKeyPrefix = annotationKeyPrefix.replace(/(_)(?!.*\1)/, '');

    for (const i of indexes) {
      const annotationKey = annotations[i].getCustomData(this.CUSTOM_DATA_ANNOTATION_KEY);

      if (
        (annotationKeyPrefix.endsWith('_') && annotationKey.startsWith(cleanedAnnotationKeyPrefix)) ||
        annotationKey === annotationKeyPrefix ||
        this.matchesIcd10ChapterFilter(annotationKey, annotationKeyPrefix)
      ) {
        if (!annotationQualifications || !annotationQualifications.length) {
          return i;
        }

        const diagnosisId = annotations[i].getCustomData(this.CUSTOM_DATA_ANNOTATION_ID);
        const diagnosis = diagnosisService.state.extracts.find((e) => e.id === diagnosisId);
        if (diagnosis?.tags && annotationQualifications.every((q) => diagnosis.tags.includes(q))) {
          return i;
        }
      }
    }

    return -1;
  }

  getCurrentPage() {
    if (!this.webViewer) return 1;
    return this.webViewer.Core.documentViewer.getCurrentPage();
  }

  getPageSize(pageNumber: number) {
    if (!this.webViewer) return null;
    return {
      width: this.webViewer.Core.documentViewer.getPageWidth(pageNumber),
      height: this.webViewer.Core.documentViewer.getPageHeight(pageNumber),
    };
  }

  // HACK(ndv): hackish method to get text of the currently selected annotation; used for copilot
  async getCurrentAnnotationText() {
    const annotation = this.getSelectedAnnotation();
    if (!this.webViewer || !annotation) return null;

    const { PDFNet, documentViewer } = this.webViewer.Core;

    await PDFNet.initialize();
    const doc = await documentViewer.getDocument().getPDFDoc();
    const page = await doc.getPage(this.getCurrentPage());

    const txt = await PDFNet.TextExtractor.create();
    const rectObject = annotation.getRect();
    const pageHeight = this.webViewer.Core.documentViewer.getPageHeight(this.getCurrentPage());
    const rect = new PDFNet.Rect(rectObject.x1, pageHeight - rectObject.y1, rectObject.x2, pageHeight - rectObject.y2);
    txt.begin(page, rect); // read the page.

    // Extract words one by one.
    let text = '';
    let line = await txt.getFirstLine();
    for (; await line.isValid(); line = await line.getNextLine()) {
      for (let word = await line.getFirstWord(); await word.isValid(); word = await word.getNextWord()) {
        text += ` ${await word.getString()}`;
      }
    }

    return text;
  }

  /** JUMPING / SELECTION */

  jumpToAnnotation(pdftronAnnotation: Core.Annotations.Annotation) {
    if (!this.webViewer) return;
    const { annotationManager } = this.webViewer.Core;

    annotationManager.deselectAllAnnotations();
    annotationManager.selectAnnotation(pdftronAnnotation);
    annotationManager.jumpToAnnotation(pdftronAnnotation);
  }

  jumpToAnnotationById(annotationId: string) {
    const annotation = this.getAnnotationById(annotationId);
    if (!annotation) return;
    this.jumpToAnnotation(annotation);
  }

  deselectAllAnnotations() {
    if (!this.webViewer) return;
    const { annotationManager } = this.webViewer.Core;
    annotationManager.deselectAllAnnotations();
  }

  /** UI HELPERS */

  showElements(elements: string[]) {
    if (!this.webViewer) return;
    this.webViewer.UI.enableElements(elements);
  }

  hideElements(elements: string[]) {
    if (!this.webViewer) return;
    this.webViewer.UI.disableElements(elements);
  }

  /** XFDF HELPERS  */

  async getAnnotationXfdf(annotation: Core.Annotations.Annotation) {
    if (!this.webViewer) return null;
    const { annotationManager } = this.webViewer.Core;

    const exportOptions = {
      annotationList: [annotation],
    };
    return await annotationManager.exportAnnotations(exportOptions);
  }

  getAnnotationCoords(xfdf: string) {
    return this.getAttributeFromXfdf(xfdf, 'coords');
  }

  getAnnotationRect(xfdf: string) {
    return this.getAttributeFromXfdf(xfdf, 'rect');
  }

  getAnnotationContents(xfdf: string) {
    const pattern = /<contents>(.*?)<\/contents>/;
    const matches = xfdf.match(pattern);
    return matches ? matches[1] : '';
  }

  parseQuadsToCoords(quads: Core.Math.Quad[]) {
    if (!this.webViewer) return null;
    let coords = '';
    const height = this.webViewer.Core.documentViewer.getPageHeight(1);

    for (let i = 0; i < quads.length; i++) {
      coords += `${quads[i].x1},${quads[i].y2},${quads[i].x2},${quads[i].y3},${quads[i].x1},${height - quads[i].y1},${quads[i].x2},${height - quads[i].y2}`;
      if (i < quads.length - 1) {
        coords += ', ';
      }
    }

    return coords;
  }

  // NOTE(ndv): given a string of comma-separated coords, returns an array of quads
  // coords are one or more series of 8 numbers, each of the 8 points represents a point on a quad, i.e. x1, y1, x2, y2, x3, y3, x4, y4
  parseCoordsToQuads(coords: string): { x1: number; x2: number; x3: number; x4: number; y1: number; y2: number; y3: number; y4: number }[] {
    if (!this.webViewer) return [];
    const coordsArray = coords.split(',').map((coord) => parseFloat(coord.trim()));

    const height = this.webViewer.Core.documentViewer.getPageHeight(1);
    const quads: { x1: number; x2: number; x3: number; x4: number; y1: number; y2: number; y3: number; y4: number }[] = [];
    for (let i = 0; i < coordsArray.length; i += 8) {
      // NOTE(ndv): normalize the coords from PDF-space to viewer-space

      quads.push({
        x4: coordsArray[i],
        y4: height - coordsArray[i + 1],
        x3: coordsArray[i + 2],
        y3: height - coordsArray[i + 3],
        x1: coordsArray[i + 4],
        y1: height - coordsArray[i + 5],
        x2: coordsArray[i + 6],
        y2: height - coordsArray[i + 7],
      });
    }

    return quads;
  }

  // NOTE(ae): given a string of comma-separated coords,
  // returns the bounding box as a string of comma-separated coords
  getBoundingBoxFromCoords(coords: string) {
    const coordsArray = coords.split(',').map(Number);

    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < coordsArray.length; i += 8) {
      const x4 = coordsArray[i];
      const y4 = coordsArray[i + 1];
      const x3 = coordsArray[i + 2];
      const y3 = coordsArray[i + 3];
      const x1 = coordsArray[i + 4];
      const y1 = coordsArray[i + 5];
      const x2 = coordsArray[i + 6];
      const y2 = coordsArray[i + 7];

      minX = Math.min(minX, x1, x2, x3, x4);
      maxX = Math.max(maxX, x1, x2, x3, x4);
      minY = Math.min(minY, y1, y2, y3, y4);
      maxY = Math.max(maxY, y1, y2, y3, y4);
    }

    return `${minX},${maxY},${maxX},${maxY},${minX},${minY},${maxX},${minY}`;
  }

  parseToRectArray(rect: string) {
    return rect.split(',').map((coord) => parseFloat(coord.trim()));
  }

  parseRectToPosition(rect: string, pageOneBased: number) {
    const rectArray = this.parseToRectArray(rect);
    const pageSize = this.getPageSize(pageOneBased);
    if (!pageSize) return null;
    return {
      x: [rectArray],
      y: pageSize.height - rectArray[3],
    };
  }

  async exportSelectionAsXfdf() {
    if (!this.webViewer) return null;
    const { annotationManager } = this.webViewer.Core;

    const selection = this.getSelection();
    if (!selection) return null;

    const annotation = this.createAnnotation('', {
      parentId: '',
      author: '',
      page: selection.page,
      color: '#ffffff',
      quads: selection.quads,
      opacity: 0,
      contents: '',
    });

    if (!annotation) return null;
    const exportOptions = {
      annotationList: [annotation],
    };
    return await annotationManager.exportAnnotations(exportOptions);
  }

  /** CRUD */

  /* creates a custom user annotation (via custom annotation buttons) and returns its xfdf string */
  async create(annotationKey: string, options: CreateAnnotationOptions) {
    if (!this.webViewer) return null;
    const { annotationManager } = this.webViewer.Core;

    const annotation = this.createAnnotation(annotationKey, options);
    if (!annotation) return '';

    annotationManager.addAnnotation(annotation);

    return await this.getAnnotationXfdf(annotation);
  }

  /** RENDERING */

  // NOTE(ndv): render a list of RenderAnnotation objects (see API)
  async render(renderAnnotations: API.Document.RenderAnnotation[]) {
    if (!this.webViewer) return;
    const { annotationManager } = this.webViewer.Core;

    // clear all annotations
    annotationManager.hideAnnotations(annotationManager.getAnnotationsList());
    annotationManager.deleteAnnotations(annotationManager.getAnnotationsList(), { force: true });

    // add annotations
    for (const renderAnnotation of renderAnnotations) {
      const options = {
        parentId: renderAnnotation.parentId,
        author: renderAnnotation.author,
        page: renderAnnotation.page + 1,
        color: renderAnnotation.color,
        coords: renderAnnotation.coords,
        rect: renderAnnotation.rect,
        opacity: renderAnnotation.opacity,
        contents: renderAnnotation.contents,
      };
      const annotation = this.createAnnotation(renderAnnotation.annotationKey, options);
      if (!annotation) continue;

      // add to pdftron
      annotationManager.addAnnotation(annotation);
    }
    await annotationManager.drawAnnotationsFromList(annotationManager.getAnnotationsList());
  }

  // NOTE(ndv): this is used to import integration annotations via the XFDF we received
  importAnnotationsXfdf(xfdf: string) {
    if (!this.webViewer) return;
    const { annotationManager } = this.webViewer.Core;
    annotationManager.importAnnotations(xfdf);
  }

  /* NOTE(ndv): this method is a hack and used only for the TEMP annotations and should NOT be used anywhere else
   */
  async forceRender(annotations: Core.Annotations.Annotation[]) {
    if (!this.webViewer) return;
    const { annotationManager } = this.webViewer.Core;

    annotationManager.deleteAnnotations(annotationManager.getAnnotationsList(), { force: true });

    annotationManager.addAnnotations(annotations);
    annotationManager.drawAnnotationsFromList([...annotationManager.getAnnotationsList()]);
  }

  /** PRIVATE */

  /* NOTE: creates a pdftron annotation based on the parameters given
   * @param {string} annotationKey - the annotation key
   * @param {string} parentId - the id of the parent (i.e., annotation or diagnosis)
   * @param {string} author - the author of the annotation
   * @param {number} page - the page number (1-based)
   * @param {string} color - the color of the annotation
   * @param {string} coords - optional. the coordinates of the annotation -- format 'x1,y1,x2,y2,x3,y3,x4,y4, ...'
   * @param {string} rect - optional if coords or quads are provided. the rect coords of the annotation -- format: 'x1,x2,y1,y2'
   * @param {string} quads - optional. the array of Quad objects of the annotation (can be provided instead of coords)
   * @param {number} opacity - the opacity of the annotation
   * @param {string} contents - the contents of the annotation
   * @returns {Object} - the pdftron annotation object
   */
  createAnnotation(annotationKey: string, options: CreateAnnotationOptions) {
    if (!this.webViewer) return null;

    const { parentId, author, page, color, coords, rect, quads, opacity, contents } = options;
    const { Annotations } = this.webViewer.Core;
    const rgbColor = Color(color).object();

    // create annotation object
    const properties: any = {
      StrokeColor: new Annotations.Color(rgbColor.r, rgbColor.g, rgbColor.b),
      PageNumber: page,
      Opacity: opacity || this.DEFAULT_ANNOTATION_OPACITY,
      Printable: false,
      ReadOnly: !annotationKey.startsWith('USER_'),
    };

    if (quads || coords) {
      properties.Quads = quads || this.parseCoordsToQuads(coords!);
    }

    if (rect) {
      const rectArray = this.parseToRectArray(rect);

      const { Rect } = this.webViewer.Core.Math;
      properties.Rect = new Rect(rectArray[0], rectArray[1], rectArray[2], rectArray[3]);
    }

    if (author) {
      properties.Author = author;
    }

    if (annotationKey === 'USER_COMMENT') {
      properties.StrokeThickness = 2;
    }

    if (annotationKey === 'USER_COMMENT_FREE' && rect) {
      const position = this.parseRectToPosition(rect, page);
      if (position) {
        properties.X = position.x;
        properties.Y = position.y;
      }
    }

    let annotation;
    if (annotationKey === 'USER_STRIKEOUT') {
      annotation = new Annotations.TextStrikeoutAnnotation(properties);
    } else if (annotationKey === 'USER_COMMENT') {
      annotation = new Annotations.TextUnderlineAnnotation(properties);
    } else if (annotationKey === 'USER_COMMENT_FREE') {
      annotation = new Annotations.StickyAnnotation(properties);
    } else {
      annotation = new Annotations.TextHighlightAnnotation(properties);
    }
    annotation.setContents(contents ?? '');
    annotation.setCustomData(this.CUSTOM_DATA_ANNOTATION_ID, parentId);
    annotation.setCustomData(this.CUSTOM_DATA_ANNOTATION_KEY, annotationKey);
    return annotation;
  }

  getAttributeFromXfdf(xfdf: string, attributeName: string) {
    const domParser = new DOMParser();
    const xfdfDoc = domParser.parseFromString(xfdf, 'text/xml');

    let annotationElement = null;
    for (const tagName of this.XFDF_ANNOTATION_TAGS) {
      annotationElement = xfdfDoc.getElementsByTagName(tagName)[0];
      if (annotationElement) {
        break;
      }
    }
    if (!annotationElement) {
      return null;
    }

    return annotationElement.getAttribute(attributeName);
  }
}

export default new PdftronAnnotationService();
