<template>
  <v-hover>
    <template #default="{ isHovering, props }">
      <div class="d-flex justify-start align-start" v-bind="props">
        <span v-tippy="{ content: tooltip, placement: 'top' }" class="overflow-hidden text-truncate text-body-2">
          <template v-if="code">
            <code>{{ textComputed }}</code>
          </template>
          <template v-else>
            {{ textComputed }}
          </template>
        </span>

        <div v-show="isHovering">
          <v-icon v-tippy="{ content: $t('Common.copy'), placement: 'top' }" end size="x-small" density="compact" link @click.stop="copy()">
            mdi-content-copy
          </v-icon>
        </div>
      </div>
    </template>
  </v-hover>
</template>

<script>
import { copyToClipboard } from '@/common/services/common.utils';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
    password: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textComputed() {
      return this.password ? `${this.text.substring(0, 7)}...` : this.text;
    },
  },
  methods: {
    copy() {
      copyToClipboard(this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
code {
  font-size: 0.875rem;
  line-height: 1.2;
}

.copy-button-wrapper {
  width: 25px;
}
</style>
