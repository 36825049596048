<template>
  <div
    v-tippy="{
      content: renderTooltipContent,
      allowHTML: true,
      interactive: true,
      arrow: true,
      appendTo: element,
      placement: placement,
      maxWidth: 300,
      theme: 'default light',
    }"
  >
    <slot>
      <v-icon :color="iconColor" variant="outlined" rounded>{{ icon }}</v-icon>
    </slot>
  </div>
</template>

<script>
import { h } from 'vue';

import InteractiveTooltipContent from '@/common/components/tooltip/components/InteractiveTooltipContent.vue';

export default {
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    buttonLink: {
      type: String,
    },
    articleId: {
      type: String,
    },
    placement: {
      type: String,
      default: 'right',
    },
    icon: {
      type: String,
      default: 'mdi-help-circle-outline',
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
  },

  data() {
    return {
      element: 'parent',
    };
  },
  computed: {
    renderTooltipContent() {
      return h(InteractiveTooltipContent, {
        title: this.title,
        text: this.text,
        buttonText: this.buttonText,
        showButton: this.showButton,
        onButtonClick: () => {
          if (this.articleId) {
            window.Intercom('showArticle', this.articleId);
            return;
          }
          if (this.buttonLink) {
            window.open(this.buttonLink, '_blank');
          }
        },
      });
    },
    showButton() {
      return !!((this.buttonText && this.buttonLink) || (this.buttonText && this.articleId));
    },
  },
  mounted() {
    this.element = window.document.body;
  },
};
</script>
<style lang="scss" scoped></style>
