import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { ticketService } from '@/case-detail/subviews/collaboration/services/ticket.service';
import diagnosisService from '@/case-detail/subviews/diagnosis/services/diagnosis.service';
import icd10Service from '@/case-detail/subviews/diagnosis/services/icd10.service';
import pdftronAnnotationService, { CreateAnnotationOptions } from '@/case-detail/subviews/document/annotations/services/pdftron.annotation.service';
import userAnnotationService from '@/case-detail/subviews/document/annotations/services/user.annotation.service';
import documentApiService from '@/case-detail/subviews/document/services/document.api.service';
import { checkUnseenDocuments, filterByBackendFilters } from '@/case-detail/subviews/document/services/document.helper';
import pagereadService from '@/case-detail/subviews/document/services/pageread.service';
import viewerService from '@/case-detail/subviews/document/services/viewer.service';
import documentFilterService from '@/case-detail/subviews/documents-list/services/filter/document.filter.service';
import { allDocumentFilterKeys, DocumentFilterKey } from '@/case-detail/subviews/documents-list/services/filter/document.filters';
import documentSortService from '@/case-detail/subviews/documents-list/services/sort/document.sort.service';
import duplicatesReviewService from '@/case-detail/subviews/duplicates/services/duplicates.review.service';
import exportService from '@/case-detail/subviews/export/services/export.service';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { mergeIntoReactive } from '@/common/services/common.utils';
import { folderService } from '@/common/services/folder.service';
import preferencesService from '@/common/services/preferences.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export const listViews = {
  MINIMAL: {
    key: 'MINIMAL',
    titleKey: 'CaseDetail.Document.ListViews.MINIMAL',
    icon: 'mdi-view-list',
    adminOnly: false,
    enabled: () => true,
  },
  CARD: {
    key: 'CARD',
    titleKey: 'CaseDetail.Document.ListViews.CARD',
    icon: 'mdi-format-list-text',
    adminOnly: false,
    enabled: () => true,
  },
  THUMBNAIL: {
    key: 'THUMBNAIL',
    titleKey: 'CaseDetail.Document.ListViews.THUMBNAIL',
    icon: 'mdi-square-rounded-outline',
    adminOnly: false,
    enabled: () => true,
  },
  SUMMARY: {
    key: 'SUMMARY',
    titleKey: 'CaseDetail.Document.ListViews.SUMMARY',
    icon: 'mdi-text-short',
    adminOnly: true,
    enabled: () => authService.hasFeature('ENABLE_MEDINSIGHTS_DOCLIST_SUMMARY'),
  },
};
export type DocumentListView = keyof typeof listViews;

export interface DocumentDiagnosis extends API.Document.Diagnosis {
  title: string;
}

export interface Document extends API.Document.Response {
  duplicateMatched: boolean;
  diagnoses: DocumentDiagnosis[];
}

export interface WorkInability {
  gap?: boolean;
  id: UUID;
  documentId?: UUID;
  from: Dayjs;
  to: null | Dayjs;
  percent: null | string;
  days: null | number;
  warning?: boolean;
  error?: boolean;
  author?: string;
  metadataValue?: string;
  issue_date?: string;
}

interface ServiceState {
  documents: Document[];
  filteredDocuments: Document[];
  outOfSync: boolean;

  documentsCache: Map<UUID, Document>;

  selected: Document | null;

  isProcessing: boolean;
  documentsLoaded: boolean;
  firstDocumentLoaded: boolean;

  duplicateReviewDocuments: { document1: Document; document2: Document } | null;

  listView: DocumentListView;
  working: boolean;

  selectionMode: boolean;
  selection: UUID[];
}

const initialState: ServiceState = {
  // All document objects
  documents: [],
  filteredDocuments: [],
  outOfSync: false,

  // Map for all documents by id for fast access
  documentsCache: new Map(),

  selected: null,

  isProcessing: false,
  documentsLoaded: false,
  firstDocumentLoaded: false,

  duplicateReviewDocuments: null,

  listView: 'CARD',
  working: false,

  selectionMode: false,
  selection: [],
};

class DocumentService {
  state: ServiceState;

  LIST_VIEWS = listViews;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
  }

  // GETTERS

  isLoading() {
    return !this.state.documentsLoaded || this.state.isProcessing;
  }

  isWorking() {
    return this.state.working;
  }

  isOutOfSync() {
    return this.state.outOfSync;
  }

  areDocumentsLoaded() {
    return this.state.documentsLoaded;
  }

  getListView() {
    return this.state.listView;
  }

  // Selection

  getSelected() {
    return this.state.selected;
  }

  getSelection() {
    return this.state.selection;
  }

  isInSelectionMode() {
    return this.state.selectionMode;
  }

  // Documents

  getDocuments() {
    return this.state.documents;
  }

  getDocumentById(id: string): Document | undefined {
    return this.state.documentsCache.get(id);
  }

  getActiveDocuments() {
    return this.state.documents.filter((d) => d.status === 'ACTIVE');
  }

  getCurrentFolderDocuments() {
    const validFilters: DocumentFilterKey[] = ['deleted', 'folder'];
    return this.getFilteredDocuments(allDocumentFilterKeys.filter((filterKey) => !validFilters.includes(filterKey)));
  }

  getAllDeletedDocuments() {
    return this.getDocuments().filter((d) => d.status.startsWith('DELETED'));
  }

  getDeletedDocuments() {
    return this.getFilteredDocuments([], true).filter((d) => d.status.startsWith('DELETED'));
  }

  getFilteredDocuments(ignoredFilters: DocumentFilterKey[] = [], filteredDocuments = false) {
    if (ignoredFilters.length || filteredDocuments) {
      return this.filteredDocuments(ignoredFilters, filteredDocuments);
    }
    return this.state.filteredDocuments;
  }

  getDocumentsCache() {
    return this.state.documentsCache;
  }

  getDuplicates() {
    return this.state.documents.flatMap((doc) => doc.duplicates);
  }

  // Complicated getters

  getFolders(all = false, allInCase = false) {
    let allFolders = folderService.getLocalizedFolders().map((folder) => ({ value: folder.value, documentsCount: 0, allDocumentsCount: 0 }));

    // fill counts
    const allDocs = documentFilterService.isFilterActive('deleted') ? this.getAllDeletedDocuments() : this.getActiveDocuments();
    const filteredDocs = this.getFilteredDocuments(['folder']);

    for (const folder of allFolders) {
      folder.allDocumentsCount = allDocs.filter((d) => d.metadata.FOLDER.value === folder.value).length;
      folder.documentsCount = filteredDocs.filter((d) => d.metadata.FOLDER.value === folder.value).length;
    }

    if (allInCase) {
      allFolders = allFolders.filter((f) => f.allDocumentsCount);
    } else if (!all) {
      allFolders = allFolders.filter((f) => f.documentsCount);
    }

    return allFolders.map((f) => ({ folder: f.value, count: f.documentsCount }));
  }

  getAuthors() {
    const authorsMap = new Map<
      string,
      {
        author: string;
        authorInstitution?: string;
        authorDepartment?: string;
        authorSpeciality?: string;
        dates: Dayjs[];
        labels: Set<string>;
        occurrences: number;
      }
    >();

    const authorOccurrencesMap = new Map<string, number>();

    for (const d of this.state.documents) {
      const author = d.metadata.AUTHOR.value;
      const labels = d.labels || [];

      if (author) {
        const authorInstitution = d.metadata.AUTHOR_INSTITUTION?.value;
        const authorDepartment = d.metadata.AUTHOR_DEPARTMENT?.value;
        const authorSpeciality = d.metadata.AUTHOR_SPECIALITY?.value;

        let key = author + authorInstitution + authorDepartment + authorSpeciality;
        key = key.normalize('NFD').replace(/\p{Diacritic}/gu, '');

        if (!authorsMap.has(key)) {
          authorsMap.set(key, {
            author,
            authorInstitution,
            authorDepartment,
            authorSpeciality,
            dates: [],
            labels: new Set(),
            occurrences: 0,
          });
        }

        const authorKey = author.normalize('NFD').replace(/\p{Diacritic}/gu, '');

        if (!authorOccurrencesMap.has(authorKey)) {
          authorOccurrencesMap.set(authorKey, 0);
        }

        authorOccurrencesMap.set(authorKey, authorOccurrencesMap.get(authorKey)! + 1);

        if (d.metadata.ISSUE_DATE?.value) {
          authorsMap.get(key)!.dates.push(dayjs(d.metadata.ISSUE_DATE.value));
        }

        labels.forEach((label) => authorsMap.get(key)!.labels.add(label));
      }
    }

    const authors = [];
    for (const [key, value] of authorsMap.entries()) {
      value.dates.sort((a, b) => a.diff(b));
      const authorKey = value.author.normalize('NFD').replace(/\p{Diacritic}/gu, '');
      authors.push({
        key,
        author: value.author,
        authorInstitution: value.authorInstitution,
        authorDepartment: value.authorDepartment,
        authorSpeciality: value.authorSpeciality,
        dates: value.dates,
        labels: Array.from(value.labels),
        occurrences: authorOccurrencesMap.get(authorKey) ?? 0,
      });
    }

    return authors;
  }

  getAuthorInstitutions() {
    const institutionsMap = new Map<
      string,
      {
        institution: string;
        dates: Dayjs[];
        labels: Set<string>;
        occurrences: number;
      }
    >();

    const institutionOccurrencesMap = new Map<string, number>();

    for (const d of this.state.documents) {
      const institution = d.metadata.AUTHOR_INSTITUTION?.value;
      const labels = d.labels || [];

      if (institution) {
        const key = institution.normalize('NFD').replace(/\p{Diacritic}/gu, '');

        if (!institutionsMap.has(key)) {
          institutionsMap.set(key, {
            institution,
            dates: [],
            labels: new Set(),
            occurrences: 0,
          });
        }

        if (!institutionOccurrencesMap.has(institution)) {
          institutionOccurrencesMap.set(institution, 0);
        }

        institutionOccurrencesMap.set(institution, institutionOccurrencesMap.get(institution)! + 1);

        if (d.metadata.ISSUE_DATE?.value) {
          institutionsMap.get(key)!.dates.push(dayjs(d.metadata.ISSUE_DATE.value));
        }

        labels.forEach((label) => institutionsMap.get(key)!.labels.add(label));
      }
    }

    const institutions = [];
    for (const [key, value] of institutionsMap.entries()) {
      value.dates.sort((a, b) => a.diff(b));
      institutions.push({
        key,
        institution: value.institution,
        dates: value.dates,
        labels: Array.from(value.labels),
        occurrences: institutionOccurrencesMap.get(value.institution) ?? 0,
      });
    }
    return institutions;
  }

  getRecipients() {
    const recipientsMap = new Map<
      string,
      {
        recipient: string;
        recipientInstitution?: string;
        dates: Dayjs[];
        labels: Set<string>;
        occurrences: number;
      }
    >();

    const recipientOccurrencesMap = new Map<string, number>();

    for (const d of this.state.documents) {
      const recipient = d.metadata.RECIPIENT?.value;
      const recipientInstitution = d.metadata.RECIPIENT_INSTITUTION?.value;
      const labels = d.labels || [];

      if (recipient) {
        const key = (recipient + recipientInstitution).normalize('NFD').replace(/\p{Diacritic}/gu, '');

        if (!recipientsMap.has(key)) {
          recipientsMap.set(key, {
            recipient,
            recipientInstitution,
            dates: [],
            labels: new Set(),
            occurrences: 0,
          });
        }

        if (!recipientOccurrencesMap.has(recipient)) {
          recipientOccurrencesMap.set(recipient, 0);
        }

        recipientOccurrencesMap.set(recipient, recipientOccurrencesMap.get(recipient)! + 1);

        if (d.metadata.ISSUE_DATE?.value) {
          recipientsMap.get(key)!.dates.push(dayjs(d.metadata.ISSUE_DATE.value));
        }

        labels.forEach((label) => recipientsMap.get(key)!.labels.add(label));
      }
    }

    const recipients = [];
    for (const [key, value] of recipientsMap.entries()) {
      value.dates.sort((a, b) => a.diff(b));
      recipients.push({
        key,
        recipient: value.recipient,
        recipientInstitution: value.recipientInstitution,
        dates: value.dates,
        labels: Array.from(value.labels),
        occurrences: recipientOccurrencesMap.get(value.recipient) ?? 0,
      });
    }
    return recipients;
  }

  getRecipientInstitutions() {
    const institutionsMap = new Map<
      string,
      {
        institution: string;
        dates: Dayjs[];
        labels: Set<string>;
        occurrences: number;
      }
    >();

    const institutionOccurrencesMap = new Map<string, number>();

    for (const d of this.state.documents) {
      const recipientInstitution = d.metadata.RECIPIENT_INSTITUTION?.value;
      const labels = d.labels || [];

      if (recipientInstitution) {
        const key = recipientInstitution.normalize('NFD').replace(/\p{Diacritic}/gu, '');

        if (!institutionsMap.has(key)) {
          institutionsMap.set(key, {
            institution: recipientInstitution,
            dates: [],
            labels: new Set(),
            occurrences: 0,
          });
        }

        if (!institutionOccurrencesMap.has(recipientInstitution)) {
          institutionOccurrencesMap.set(recipientInstitution, 0);
        }

        institutionOccurrencesMap.set(recipientInstitution, institutionOccurrencesMap.get(recipientInstitution)! + 1);

        if (d.metadata.ISSUE_DATE?.value) {
          institutionsMap.get(key)!.dates.push(dayjs(d.metadata.ISSUE_DATE.value));
        }

        labels.forEach((label) => institutionsMap.get(key)!.labels.add(label));
      }
    }

    const institutions = [];
    for (const [key, value] of institutionsMap.entries()) {
      value.dates.sort((a, b) => a.diff(b));
      institutions.push({
        key,
        institution: value.institution,
        dates: value.dates,
        labels: Array.from(value.labels),
        occurrences: institutionOccurrencesMap.get(value.institution) ?? 0,
      });
    }
    return institutions;
  }

  getDocumentSourceFileId(docId: UUID) {
    return this.getDocuments().find((d) => d.id === docId)?.sourceFileId;
  }

  getDocumentsInSameSourceFile(docId: UUID) {
    const sourceFileId = this.getDocumentSourceFileId(docId);
    return this.getDocuments().filter(({ sourceFileId: id }) => id === sourceFileId);
  }

  // SETTERS & ACTIONS

  async load(caseId: UUID) {
    this.setListView(preferencesService.getListView());
    documentSortService.loadSavedSorting();

    const documentPromise = documentApiService.getAllDocuments(caseId);
    const documentReadPromise = pagereadService.readRead(caseId);

    const [documents] = await Promise.all([documentPromise, documentReadPromise]);
    this.state.documents = documents.map(this.mapToDocument);

    this.initDocumentsCache();

    // NOTE(kt): if we are accessing doc directly via link, we disable initial "new documents" fitler
    // it's a case, when we want to make sure that document which is not new can be seen correctly.
    if (!detailViewRouteService.getFromQuery('initialDocId')) {
      checkUnseenDocuments();
    }

    this.updateFilteredDocuments();
    this.state.documentsLoaded = true;
  }

  getDocumentPaginationNumber(documentId: UUID): number | null {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) {
      return null;
    }
    return parseInt(document.metadata.EXPORT_PAGINATION_NO?.value, 10) || null;
  }

  getDocumentPaginationId(documentId: UUID): string | null {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) {
      return null;
    }
    return document.metadata.EXPORT_PAGINATION_ID?.value || null;
  }

  mapToDocument(documentResponse: API.Document.Response): Document {
    return {
      ...documentResponse,
      duplicateMatched: false,
      diagnoses: documentResponse.diagnoses.map((diagnosis) => ({
        ...diagnosis,
        title: icd10Service.getDiagnosis(diagnosis.icd10Code)?.title ?? '',
      })),
    };
  }

  setOutOfSync(isOutOfSync: boolean) {
    this.state.outOfSync = isOutOfSync;
  }

  setFirstDocumentLoaded(isLoaded: boolean) {
    this.state.firstDocumentLoaded = isLoaded;
  }

  setProcessing(isProcessing: boolean) {
    this.state.isProcessing = isProcessing;
  }

  setDocumentsCache(value: typeof this.state.documentsCache) {
    this.state.documentsCache = value;
  }

  initDocumentsCache() {
    const m = new Map();
    for (const d of this.state.documents) {
      m.set(d.id, d);
    }
    this.setDocumentsCache(m);
  }

  setDuplicateReviewDocuments(compareDocs: typeof this.state.duplicateReviewDocuments) {
    this.state.duplicateReviewDocuments = compareDocs;
  }

  filteredDocuments(ignoredFilters: DocumentFilterKey[] = [], includeDeleted = false) {
    let filteredDocuments = this.state.documents.filter(filterByBackendFilters);
    filteredDocuments = documentFilterService.getFilteredDocuments(filteredDocuments, ignoredFilters, includeDeleted);
    return filteredDocuments;
  }

  updateFilteredDocuments(sort = true) {
    let filteredDocuments = this.filteredDocuments();
    if (sort) {
      filteredDocuments = documentSortService.getSortedDocuments(filteredDocuments);
    }
    this.state.filteredDocuments = filteredDocuments;
    // NOTE(ndv): if sorting was applied we are in sync, otherwise not
    this.state.outOfSync = !sort;
  }

  setListView(listView: DocumentListView) {
    this.state.listView = listView;
  }

  updateDocument(document: Document) {
    const index = this.state.documents.findIndex((d) => d.id === document.id);
    if (index !== -1) {
      this.state.documents[index] = document;
    }
  }

  updateSplittedDocuments({ currentDocument, newDocument }: { currentDocument: Document; newDocument: Document }) {
    this.updateDocument(currentDocument);
    this.insertDocumentAfter({ afterDocument: currentDocument, newDocument });
    this.initDocumentsCache();
    this.updateFilteredDocuments(false);
  }

  insertDocumentAfter({ afterDocument, newDocument }: { afterDocument: Document; newDocument: Document }) {
    const index = this.state.documents.findIndex((d) => d.id === afterDocument.id);
    if (index !== -1) {
      this.state.documents.splice(index + 1, 0, newDocument);
    }
  }

  async updateDocumentDeleted({ documentId, deleted }: { documentId: UUID; deleted: boolean }) {
    const document = this.state.documentsCache.get(documentId);
    if (!document || document?.status === 'DELETED_BY_AGENT') return;

    // undo fn
    const undoFn = () => {
      this.updateDocumentDeleted({ documentId, deleted: !deleted });
    };

    // update state & persist change
    const status = deleted ? 'DELETED_BY_USER' : 'ACTIVE';
    document.status = status;

    if (this.state.selected?.id === documentId) {
      await this.nextDocument(true);
    }

    this.updateFilteredDocuments();

    documentApiService.updateStatus(document.caseId, documentId, status);

    const message = deleted ? $t('CaseDetail.Document.movedToTrash') : $t('CaseDetail.Document.restored');
    appService.info(message, undoFn);
  }

  async prevDocument(displayLastPage?: boolean) {
    if (!this.state.selected) return;

    const documents = this.state.filteredDocuments;

    const currentIndex = documents.findIndex((d) => d.id === this.state.selected!.id);
    let prev;
    if (currentIndex === 0) {
      prev = documents.length - 1;
      appService.info($t('CaseDetail.DocumentsList.navToLastDocSnackbar'));
    } else {
      prev = currentIndex - 1;
    }

    const prevDocument = documents[prev];

    // ndv: workaround for cursor up/down navigation.
    const el = document.getElementById(`tl_${prevDocument.id}_${prevDocument.sourceFilePage}`);
    el?.scrollIntoView();

    const page = displayLastPage ? prevDocument.pageCount : 1;
    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: prevDocument.id, page, scroll: false, forceShow: false, iterate: false });
    $a.l($a.e.DOCLIST_PREV);
  }

  async nextDocument(scroll = false) {
    if (!this.state.selected) return;

    const documents = this.state.filteredDocuments;

    const currentIndex = documents.findIndex((d) => d.id === this.state.selected!.id);

    let next;
    if (currentIndex < documents.length - 1) {
      next = currentIndex + 1;
    } else {
      next = 0;
      appService.info($t('CaseDetail.DocumentsList.navToFirstDocSnackbar'));
    }
    const nextDocument = documents[next];

    // ndv: workaround for cursor up/down navigation.
    const el = document.getElementById(`tl_${nextDocument.id}_${nextDocument.sourceFilePage}`);
    el?.scrollIntoView();

    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: nextDocument.id, page: 1, scroll, forceShow: false, iterate: false });
    $a.l($a.e.DOCLIST_NEXT);
  }

  clear() {
    mergeIntoReactive(this.state, cloneDeep(initialState));
  }

  // Selection

  setSelected(document: typeof this.state.selected) {
    this.state.selected = document;
  }

  setSelection(selection: typeof this.state.selection) {
    this.state.selection = selection;
  }

  setSelectionMode(selectionMode: typeof this.state.selectionMode) {
    this.state.selectionMode = selectionMode;
    $a.l($a.e.DOCLIST_SELECTION);
  }

  resetSelection() {
    this.state.selectionMode = false;
    this.state.selection = [];
    viewerService.resetWebViewer({ document: this.state.selected });
  }

  // Metadata

  async setDocumentMetadata(meta: {
    docId: UUID;
    update: Partial<Record<API.Document.MetadataKey, string>>;
    undo: Partial<Record<API.Document.MetadataKey, string>>;
    undoCallback?: () => void;
  }) {
    const { docId, update, undo } = meta;
    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    if (!this.state.documentsCache.has(docId)) return;
    const document = this.state.documentsCache.get(docId);
    if (document?.duplicateMatched) {
      appService.info($t('CaseDetail.Document.duplicatesChangeError'));
      return;
    }

    for (const key of Object.keys(update)) {
      $a.l(`DOC_META_EDIT_${key}`);
    }

    // removing html tags from TITLE
    if (update.TITLE) {
      update.TITLE = update.TITLE.replace(/<\/?[^>]+>/gi, '');
    }

    const result = await documentApiService.updateDocumentMetadata(legalCaseId, docId, update);
    if (!result) {
      // error handled in documentApiService
      return;
    }

    this.updateDocumentMeta({ documentId: docId, updatedMetadataValues: update });
    this.state.outOfSync = true;

    const undoFn = async () => {
      const undoResult = await documentApiService.updateDocumentMetadata(legalCaseId, docId, undo);
      if (!undoResult) {
        // error handled in documentApiService
        return;
      }
      this.updateDocumentMeta({ documentId: docId, updatedMetadataValues: undo });
      if (meta.undoCallback) {
        meta.undoCallback();
      }
    };

    appService.info($t('CaseDetail.Document.changeSaved'), undoFn);
  }

  updateDocumentMeta({
    documentId,
    updatedMetadataValues,
  }: {
    documentId: UUID;
    updatedMetadataValues: Partial<Record<API.Document.MetadataKey, string>>;
  }) {
    const doc = this.state.documentsCache.get(documentId)!;
    for (const key in doc.metadata) {
      if (Object.prototype.hasOwnProperty.call(updatedMetadataValues, key)) {
        doc.metadata[key as API.Document.MetadataKey].value = updatedMetadataValues[key as API.Document.MetadataKey] as string;
        doc.metadata[key as API.Document.MetadataKey].extractor = 'MANUAL';
      }
    }
  }

  // Annotations

  async addUserAnnotationToDocument({
    documentId,
    annotationKey,
    pageOneBased,
    quads,
    rect,
    color,
    opacity,
    contents,
  }: {
    documentId: UUID;
    annotationKey: string;
    pageOneBased: number;
    quads?: string;
    rect?: string;
    color: string;
    opacity: number;
    contents: string;
  }) {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) return;

    // create  annotation in pdfron and extract xfdf string
    const id = uuidv4();
    const options: CreateAnnotationOptions = {
      parentId: id,
      author: authService.state.userId!,
      page: pageOneBased,
      color,
      opacity,
      contents,
    };
    if (quads) {
      options.quads = quads;
    }
    if (rect) {
      options.rect = rect;
    }

    const wrappedXfdf = (await pdftronAnnotationService.create(annotationKey, options))!;

    // create annotation object for local state store: DocumentAnnotation (see API)
    const annotation: API.Document.Annotation = {
      id,
      annotationKey,
      author: authService.state.userId!,
      updated: dayjs().toISOString(),
      renderAnnotation: {
        // RenderAnnotation
        parentId: id,
        annotationKey,
        author: options.author ?? '',

        page: options.page - 1, // NOTE: pdftron's page numbers start at 1, XFDF ones at 0
        rect: pdftronAnnotationService.getAnnotationRect(wrappedXfdf),
        coords: pdftronAnnotationService.getAnnotationCoords(wrappedXfdf),

        color: options.color,
        opacity: options.opacity,
        contents: options.contents ?? '',
      },
    };

    document.userAnnotations = [...document.userAnnotations, annotation];
    document.userAnnotations.sort(userAnnotationService.sortByRect);
    pdftronAnnotationService.jumpToAnnotationById(id);

    // persist
    const createAnnotationRequest = {
      id: annotation.id,
      sourceFileId: document.sourceFileId,
      sourceFilePage: document.sourceFilePage + pageOneBased - 1,
      annotationKey: annotation.annotationKey,
      wrappedXfdf,
    };

    userAnnotationService.create(createAnnotationRequest);
  }

  async updateUserAnnotationKey({
    documentId,
    annotationId,
    annotationKey,
    color,
    xfdf,
  }: {
    documentId: UUID;
    annotationId: UUID;
    annotationKey: string;
    color: string;
    xfdf: string;
  }) {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) return;

    const annotation = document.userAnnotations.find((a) => a.id === annotationId);
    if (!annotation) return;

    // update state
    annotation.annotationKey = annotationKey;
    annotation.renderAnnotation.annotationKey = annotationKey;
    annotation.renderAnnotation.color = color;

    // re-render
    broadcastEventBus.emit('RENDER_ANNOTATION_EVENT', { annotationKeyPrefix: '', forceRender: true });

    // persist
    userAnnotationService.update(annotationId, annotationKey, xfdf);
  }

  updateUserAnnotation({ documentId, annotationId, xfdf }: { documentId: UUID; annotationId: UUID; xfdf: string }) {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) return;
    const annotation = document.userAnnotations.find((a) => a.id === annotationId);
    if (!annotation) return;

    // update state
    annotation.renderAnnotation.coords = pdftronAnnotationService.getAnnotationCoords(xfdf);
    annotation.renderAnnotation.rect = pdftronAnnotationService.getAnnotationRect(xfdf);
    annotation.renderAnnotation.contents = pdftronAnnotationService.getAnnotationContents(xfdf);

    // persist
    userAnnotationService.update(annotationId, annotation.annotationKey, xfdf);
  }

  deleteUserAnnotationFromDocument({ documentId, annotationId }: { documentId: UUID; annotationId: UUID }) {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) return;

    // update state
    document.userAnnotations = document.userAnnotations.filter((annotation) => annotation.id !== annotationId);

    // render annotations
    broadcastEventBus.emit('RENDER_ANNOTATION_EVENT', { annotationKeyPrefix: '', forceRender: true });

    // persist
    userAnnotationService.delete(annotationId);
  }

  // Duplicates

  async createDuplicatesFromSelection() {
    if (this.state.selection.length < 2) return;

    // store current state
    const oldDocuments = cloneDeep(this.state.documents);

    const selectedDocuments: Document[] = [];
    this.state.selection.forEach((documentId) => selectedDocuments.push(this.state.documentsCache.get(documentId)!));

    // choose new original document
    const [originalDocument] = selectedDocuments;

    // update state
    for (const document of selectedDocuments) {
      if (document.id === originalDocument.id) continue;
      originalDocument.duplicates = [...originalDocument.duplicates, ...document.duplicates, document];
      document.duplicates = [];
    }

    const duplicates = originalDocument.duplicates.map((d) => d.id);
    this.state.documents = this.state.documents.filter((d) => !duplicates.includes(d.id));
    this.initDocumentsCache();
    this.updateFilteredDocuments();

    // persist
    const duplicateDocumentIds: UUID[] = [];
    this.state.selection.forEach((id) => {
      if (id !== originalDocument.id) {
        duplicateDocumentIds.push(id);
      }
    });
    const caseId = detailViewService.getCurrentLegalCaseId();
    const fn = () => {
      documentApiService.updateBulkDocumentDuplicates(caseId, [
        {
          originalDocumentId: originalDocument.id,
          duplicateDocumentIds,
        },
      ]);
    };

    // undo fn
    const undoFn = () => {
      this.state.documents = oldDocuments;
      this.initDocumentsCache();
      this.updateFilteredDocuments();
      duplicatesReviewService.setOriginalDocument(this.state.documentsCache.get(originalDocument.id)!);
      // NOTE (ndv): no need to undo persistence as this is only persisted after the undo option goes away, i.e. it is delayed
    };

    // reset
    this.state.selectionMode = false;
    this.state.selection = [];

    // notify change with delayed persistence
    appService.infoWithDelayedAction($t('CaseDetail.Document.duplicatesChanged'), fn, undoFn);

    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: originalDocument.id, page: 0, scroll: false, forceShow: false, iterate: false });
    duplicatesReviewService.setOriginalDocument(originalDocument);
    detailViewService.openPanel('DuplicatesReview');
  }

  async updateDuplicatesOriginalDocument({
    formerOriginalDocumentId,
    newOriginalDocumentId,
  }: {
    formerOriginalDocumentId: UUID;
    newOriginalDocumentId: UUID;
  }) {
    // update local state
    const formerOriginalDocument = this.state.documents.find((document) => document.id === formerOriginalDocumentId)!;
    const newOriginalDocument = this.state.documents
      .flatMap((document) => document.duplicates)
      .find((document) => document.id === newOriginalDocumentId)!;

    // compute new duplicates for the new original and remove them from former original
    newOriginalDocument.duplicates = [
      ...formerOriginalDocument.duplicates.filter((duplicateDocument) => duplicateDocument.id !== newOriginalDocumentId),
      formerOriginalDocument,
    ];
    formerOriginalDocument.duplicates = [];
    // copy metadata & labels
    newOriginalDocument.metadata.TITLE.value = formerOriginalDocument.metadata.TITLE.value;
    newOriginalDocument.metadata.DOCTYPE.value = formerOriginalDocument.metadata.DOCTYPE.value;
    newOriginalDocument.metadata.ISSUE_DATE.value = formerOriginalDocument.metadata.ISSUE_DATE.value;
    newOriginalDocument.metadata.WORK_INABILITY.value = formerOriginalDocument.metadata.WORK_INABILITY.value;
    newOriginalDocument.metadata.IMPORTANT.value = formerOriginalDocument.metadata.IMPORTANT.value;
    newOriginalDocument.labels = [...formerOriginalDocument.labels];
    // remove former original from the document list and add new one
    const allDocumentsWithoutFormerOriginal = this.state.documents.filter((document) => document.id !== formerOriginalDocumentId);
    this.state.documents = [...allDocumentsWithoutFormerOriginal, this.mapToDocument(newOriginalDocument)];
    this.initDocumentsCache();
    this.updateFilteredDocuments();

    documentApiService.updateDuplicatesOriginalDocument(detailViewService.getCurrentLegalCaseId(), {
      formerOriginalDocumentId,
      newOriginalDocumentId,
    });
  }

  async removeDocumentDuplicate({ originalDocumentId, duplicateDocumentId }: { originalDocumentId: UUID; duplicateDocumentId: UUID }) {
    // update local state
    const originalDocument = this.state.documents.find((document) => document.id === originalDocumentId)!;
    const duplicateDocument = originalDocument.duplicates.find((document) => document.id === duplicateDocumentId)!;

    originalDocument.duplicates = originalDocument.duplicates.filter((documentDuplicate) => documentDuplicate.id !== duplicateDocumentId);
    this.state.documents = [...this.state.documents, this.mapToDocument(duplicateDocument)];
    this.initDocumentsCache();
    this.updateFilteredDocuments();
    // persist
    documentApiService.removeDocumentDuplicate(detailViewService.getCurrentLegalCaseId(), { originalDocumentId, duplicateDocumentId });
  }

  async addDuplicate({ originalDocumentId, duplicateDocument }: { originalDocumentId: UUID; duplicateDocument: Document }) {
    // update local state
    const originalDocument = this.state.documents.find((document) => document.id === originalDocumentId);
    if (!originalDocument) return;

    originalDocument.duplicates = [...originalDocument.duplicates, duplicateDocument];

    // persist
    documentApiService.addDocumentDuplicate(detailViewService.getCurrentLegalCaseId(), {
      originalDocumentId,
      duplicateDocumentId: duplicateDocument.id,
    });
  }

  // Diagnosis

  async addDiagnosisToDocument({ documentId, icd10Code, title, tags }: { documentId: UUID; icd10Code: string; title: string; tags: string[] }) {
    const document = this.state.documentsCache.get(documentId)!;
    const xfdf = (await pdftronAnnotationService.exportSelectionAsXfdf())!;
    const selection = pdftronAnnotationService.getSelection()!;

    const request = {
      sourceFileId: document.sourceFileId,
      sourceFilePage: document.sourceFilePage + selection.page - 1,
      icd10Code,
      title,
      coveredText: selection.contents,
      tags,
      issueDate: document.metadata.ISSUE_DATE.value,
      xfdf,
    };
    // perist diagnosis
    diagnosisService.createDiagnosisExtract(request).then((diagnosisExtract) => {
      if (!diagnosisExtract) return;

      // NOTE(ndv): we receive a DiagnosisExtract object from the backend and map it to a DocumentDiagnosis object
      const documentDiagnosis: DocumentDiagnosis = {
        id: diagnosisExtract.id,
        icd10Code: diagnosisExtract.code,
        title: diagnosisExtract.title,
        tags: diagnosisExtract.tags,
        debugInfo: JSON.stringify(diagnosisExtract.properties),
        renderAnnotation: {
          parentId: diagnosisExtract.id,
          annotationKey: `MEDICAL_ICD10_${diagnosisExtract.code.toUpperCase()}`,
          page: selection.page - 1,
          opacity: 0.5,
          coords: pdftronAnnotationService.getAnnotationCoords(xfdf),
          contents: '',
          color: '#72d2ff',
          rect: null,
          author: authService.state.userId!,
        },
      };
      // update state
      document.diagnoses.push(documentDiagnosis);

      broadcastEventBus.emit('RENDER_ANNOTATION_EVENT', { annotationKeyPrefix: 'MEDICAL_ICD10', forceRender: true });

      appService.info($t('CaseDetail.Document.diagnosesSaved'));
    });
  }

  updateDiagnosisFromDocument({
    documentId,
    diagnosisId,
    icd10Code,
    title,
    tags,
  }: {
    documentId: UUID;
    diagnosisId: UUID;
    icd10Code: string;
    title: string;
    tags: string[];
  }) {
    const document = this.state.documentsCache.get(documentId);
    if (!document) return;

    const diagnosis = document.diagnoses.find((d) => d.id === diagnosisId);
    if (!diagnosis) return;

    // update state
    diagnosis.icd10Code = icd10Code;
    diagnosis.title = title;
    diagnosis.tags = tags;

    // persist
    diagnosisService.updateDiagnosisExtract(diagnosisId, icd10Code, title, tags);

    broadcastEventBus.emit('RENDER_ANNOTATION_EVENT', { annotationKeyPrefix: 'MEDICAL_ICD10', forceRender: true });

    appService.info($t('CaseDetail.Document.diagnosisProcessed'));
  }

  deleteDiagnosisFromDocument({ diagnosisId }: { diagnosisId: UUID }) {
    if (!(this.state.selected as Document).diagnoses) return;
    const selected = this.state.selected as Document;

    selected.diagnoses = selected.diagnoses.filter((d) => d.id !== diagnosisId);

    broadcastEventBus.emit('RENDER_ANNOTATION_EVENT', { annotationKeyPrefix: 'MEDICAL_ICD10', forceRender: true });

    diagnosisService.deleteDiagnosisExtract(diagnosisId);
    appService.info($t('CaseDetail.Document.diagnosisDeleted'));
  }

  // Manual Segmentation

  async mergeWithPreviousDocument(document: Document) {
    if (this.state.working) {
      // prevent double click
      return;
    }
    this.state.working = true;

    try {
      const response = await axios.post(
        config.API.MANUAL_SEGMENTATION.MERGE.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()).replace('{documentId}', document.id),
        { documentId: document.id },
      );
      const mergedDocument = response.data;

      this.updateMergedDocument(mergedDocument);

      broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: mergedDocument.id, page: 1, scroll: true, forceShow: false, iterate: false });

      await ticketService.reloadSelectedTicket();
      exportService.refreshList();

      appService.info($t('CaseDetail.Document.documentLinkedToPrevious'), () => this.splitDocument(mergedDocument, document.sourceFilePage));
    } catch (e) {
      handleError($t('CaseDetail.Document.documentMergeError'), e);
    } finally {
      this.state.working = false;
    }
  }

  updateMergedDocument(document: Document) {
    this.removeDocumentWithSourceFileEndPage(document);
    this.updateDocument(document);
    this.initDocumentsCache();
    this.updateFilteredDocuments();
  }

  removeDocumentWithSourceFileEndPage(document: Document) {
    const index = this.state.documents.findIndex(
      (d) => d.sourceFileId === document.sourceFileId && d.sourceFileEndPage === document.sourceFileEndPage,
    );
    if (index !== -1) {
      const removedDocumentId = this.state.documents[index].id;
      this.state.documents.splice(index, 1);

      for (const doc of this.state.documents) {
        doc.duplicateCandidates = doc.duplicateCandidates.filter((d) => d.id !== removedDocumentId);
        doc.duplicates = doc.duplicates.filter((d) => d.id !== removedDocumentId);
      }
    }
  }

  async splitDocument(document: Document, sourceFileStartPageOfNewDocument: number) {
    if (this.state.working) {
      // prevent double click
      return;
    }
    this.state.working = true;

    try {
      const response = await axios.post(
        config.API.MANUAL_SEGMENTATION.SPLIT.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()).replace('{documentId}', document.id),
        {
          sourceFileStartPageOfNewDocument,
        },
      );
      // reponse contains an array with two documents, first is the current document, second is the new document

      this.updateSplittedDocuments({
        currentDocument: response.data[0],
        newDocument: response.data[1],
      });

      const newDocument = this.state.filteredDocuments.find(
        (doc) => doc.sourceFileId === document.sourceFileId && doc.sourceFilePage === sourceFileStartPageOfNewDocument,
      );

      if (newDocument) {
        broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: newDocument.id, page: 1, scroll: true, forceShow: false, iterate: false });
      }

      await ticketService.reloadSelectedTicket();
      // @ts-expect-error incompatibility with JS
      appService.info($t('CaseDetail.Document.documentSplitted'), () => this.mergeWithPreviousDocument(newDocument));
    } catch (e) {
      handleError($t('CaseDetail.Document.documentSplitError'), e);
    } finally {
      this.state.working = false;
    }
  }

  hasPaginationReference(documentId: string) {
    const document = this.state.documents.find((d) => d.id === documentId);
    if (!document) {
      return false;
    }
    const paginationId = document.metadata.EXPORT_PAGINATION_ID?.value || null;
    const paginationNo = document.metadata.EXPORT_PAGINATION_NO?.value || null;
    return paginationId !== null || paginationNo !== null;
  }

  async deleteDocumentMetadataKey(documentId: UUID, metadataKey: API.Document.MetadataKey) {
    await documentApiService.deleteDocumentMetadataByKeys(detailViewService.getCurrentLegalCaseId(), documentId, [metadataKey]);
  }
}

export default new DocumentService();
