<template>
  <l-menu v-if="isLoggedIn && tenant" v-model="menu" :close-on-content-click="false" close-delay="200">
    <template #activator="{ props }">
      <div class="ml-4" style="cursor: pointer" v-bind="props">
        <AppAvatar :override-tooltip="$t('App.Bar.AppMenu.tooltip')" />
      </div>
    </template>

    <v-list width="300" density="compact" data-testid="header_menu">
      <v-list-item
        v-if="$route.name === 'requests'"
        prepend-icon="mdi-format-list-text"
        :to="{ name: 'case-list', params: { tenant: tenant.canonicalName } }"
        @click="menu = false"
      >
        <v-list-item-title>{{ $t('CaseList.title') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-else-if="$hasFeature('ENABLE_COLLABORATION') && $hasPermission('ACCESS_COLLABORATION_OVERVIEW')"
        prepend-icon="mdi-message-reply-text"
        :to="{ name: 'requests', params: { tenant: tenant.canonicalName } }"
        @click="menu = false"
      >
        <v-list-item-title>{{ $t('Tickets.requestsOverview') }}</v-list-item-title>
      </v-list-item>

      <v-list-item prepend-icon="mdi-account-cog" data-testid="notification_settings" :to="{ name: 'user-settings' }" @click="menu = false">
        <v-list-item-title>{{ $t('App.Bar.AppMenu.userSettingsLabel') }}</v-list-item-title>
      </v-list-item>

      <template v-if="$isLegaliAdmin()">
        <v-list-group prepend-icon="mdi-tools">
          <template #activator="{ props }">
            <v-list-item v-bind="props">
              <v-list-item-title>Tools</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            data-testid="action_createWorkspace"
            append-icon="mdi-home-plus-outline"
            @click="
              $router.push({ name: 'create-workspace' });
              menu = false;
            "
          >
            <v-list-item-title> Add workspace</v-list-item-title>
          </v-list-item>
          <v-list-item
            data-testid="action_openWorkbench"
            append-icon="mdi-hammer-wrench"
            @click="
              $router.push({ name: 'workbench', params: { tenant: tenant.canonicalName } });
              menu = false;
            "
          >
            <v-list-item-title>Open workbench</v-list-item-title>
          </v-list-item>
          <v-list-item
            data-testid="action_openConsistency"
            append-icon="mdi-shield-check"
            @click="
              $router.push({ name: 'consistency', params: { tenant: tenant.canonicalName } });
              menu = false;
            "
          >
            <v-list-item-title>Open Consistency</v-list-item-title>
          </v-list-item>
          <v-list-item
            append-icon="mdi-database-remove-outline"
            @click="
              clearBackendCaches();
              menu = false;
            "
          >
            <v-list-item-title>Clear all caches</v-list-item-title>
          </v-list-item>
          <v-list-item
            append-icon="mdi-transfer"
            @click="
              showTransferDialog();
              menu = false;
            "
          >
            <v-list-item-title>Open transfer dialog</v-list-item-title>
          </v-list-item>
          <v-list-item append-icon="mdi-railroad-light" @click="setForceDisableSSO(!forceDisableSSO)">
            <v-list-item-title>
              {{ forceDisableSSO ? 'SSO disabled' : 'SSO enabled, if configured' }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-test-tube">
          <template #activator="{ props }">
            <v-list-item v-bind="props">
              <v-list-item-title>Tests</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            append-icon="mdi-email"
            @click="
              sendTestEmail();
              menu = false;
            "
          >
            <v-list-item-title>Send test email</v-list-item-title>
          </v-list-item>

          <v-list-item
            append-icon="mdi-theme-light-dark"
            @click="
              // @ts-expect-error 'global' marked as readonly but at the same time it's documented way to change theme (https://vuetifyjs.com/en/features/theme/#changing-theme)
              $vuetify.theme.global.name = $vuetify.theme.global.current.dark ? 'light' : 'dark'
            "
          >
            <v-list-item-title>
              {{ `Toggle ${$vuetify.theme.global.current.dark ? 'light mode' : 'dark mode'}` }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-pipe-valve">
          <template #activator="{ props }">
            <v-list-item v-bind="props">
              <v-list-item-title>Pipeline tasks</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item>
            <v-list-item-title>
              {{ statusToggle ? 'Process error only' : 'Process ready only' }}
            </v-list-item-title>
            <template #append>
              <v-list-item-action end>
                <v-btn-toggle v-model="statusToggle" variant="outlined">
                  <v-btn size="small" icon="mdi-check" />
                  <v-btn size="small" icon="mdi-alert-octagon" />
                </v-btn-toggle>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item
            append-icon="mdi-ocr"
            @click="
              requestTasksOnTenant('OCR');
              menu = false;
            "
          >
            <v-list-item-title>Run OCR</v-list-item-title>
          </v-list-item>
          <v-list-item
            append-icon="mdi-auto-fix"
            @click="
              requestTasksOnTenant('INDEXING');
              menu = false;
            "
          >
            <v-list-item-title>Run INDEXING</v-list-item-title>
          </v-list-item>

          <v-list-item
            append-icon="mdi-toy-brick-search"
            @click="
              requestTasksOnTenant('EXTRACTION');
              menu = false;
            "
          >
            <v-list-item-title>Run EXTRACTION</v-list-item-title>
          </v-list-item>

          <v-list-item
            append-icon="mdi-wheelchair-accessibility"
            @click="
              requestTasksOnTenant('ANALYZING');
              menu = false;
            "
          >
            <v-list-item-title>Run ANALYZING</v-list-item-title>
          </v-list-item>

          <v-list-item
            append-icon="mdi-restart"
            @click="
              requestTaskRestart();
              menu = false;
            "
          >
            <v-list-item-title>Run RESTART</v-list-item-title>
          </v-list-item>

          <v-list-item
            append-icon="mdi-hospital-box"
            @click="
              runSupervisor();
              menu = false;
            "
          >
            <v-list-item-title>Run Supervisor</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>

      <v-list-group prepend-icon="mdi-web" data-testid="role_locale">
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title>{{ selectedLocale.getNativeName() }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item
          v-for="locale of availableLocales"
          :key="locale.value.toString()"
          :disabled="localeChangeRequest === locale.value"
          :data-testid="`locale_${locale.value.toString()}`"
          :active="selectedLocale.toString() === locale.value.toString()"
          @click="selectedLocale = locale.value"
        >
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
          <template #append>
            <v-icon v-if="localeChangeRequest === locale.value">
              <v-progress-circular width="2" indeterminate />
            </v-icon>
          </template>
        </v-list-item>
      </v-list-group>

      <template v-if="$hasPermission('USER_SWITCH_ROLE')">
        <v-list-group prepend-icon="mdi-shield-crown" data-testid="role_settings">
          <template #activator="{ props }">
            <v-list-item v-bind="props" data-testid="role_settings_title">
              <v-list-item-title>{{ $t('App.Bar.AppMenu.switchRole') }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            v-for="currentRole of roles"
            v-show="authService.showSwitchToRole(currentRole.id)"
            :key="currentRole.id"
            :data-testid="'role_' + currentRole.id.replace(':', '_')"
            :append-icon="currentRole.icon"
            @click="
              switchRole(currentRole.id);
              menu = false;
            "
          >
            <v-list-item-title>{{ currentRole.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>

      <v-list-group prepend-icon="mdi-information-outline" data-testid="header_menu_about">
        <template #activator="{ props }">
          <v-list-item v-bind="props" data-testid="header_menu_about_title">
            <v-list-item-title>{{ $t('App.Bar.AppMenu.about') }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item style="margin-left: -0.5rem" class="text-body-2">
          <v-list-item-title><strong>Designed and engineered in Bern by</strong></v-list-item-title>
          <span
            >Patrick Barnert, Markus Baumgartner, Nicolas Di Vittorio, Aimé Ehi, Lena Fuhrimann, Dennis Gjokaj, Erik Graf, Achim Kohli, Joachim Masur,
            Dmitry Puzyrev, Ralph Schär, Nikolaos Theodorou, Kondrat Trunowicz, Jürgen Vogel, Michael Vogt, Matthias Winzeler, Lukas Zaugg<br />and
            many friends, partners and freelancers.</span
          >
        </v-list-item>
        <v-list-item style="margin-left: -0.5rem" class="text-body-2">
          <v-list-item-title><strong>Powered by Schadenanwälte</strong></v-list-item-title>
          <span
            >Die Partner:innen und Anwält:innen von Schadenanwälte haben sich bei der Entwicklung der Al-Software von legal-i als Entwicklungspartner
            in jedem Bereich ausgezeichnet: Mit ihrer hohen Fachkompetenz, ihrer pragmatischen und agilen Vorgehensweise, ihrem technischen
            Verständnis und ihrer Begeisterung für Innovation. Sie haben einen wesentlichen Beitrag zum Erfolg von legal-i geleistet.</span
          >
        </v-list-item>
        <v-list-item style="margin-left: -0.5rem; line-height: 1rem" class="text-body-2 text-grey">
          {{ `© ${currentYear} by legal-i AG, Sulgenbach- strasse 5, 3007 Bern, Switzerland` }}
        </v-list-item>
      </v-list-group>
      <v-list-item
        link
        data-testid="header_menu_clear_local_cache"
        prepend-icon="mdi-archive-sync-outline"
        @click="
          clearLocalCache();
          menu = false;
        "
      >
        <v-list-item-title>{{ $t('App.Bar.AppMenu.repair') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        data-testid="header_menu_logout"
        prepend-icon="mdi-logout"
        @click="
          logout();
          menu = false;
        "
      >
        <v-list-item-title>{{ $t('App.Bar.AppMenu.logout') }}</v-list-item-title>
      </v-list-item>
      <v-list-subheader data-testid="header_menu_version">
        Version: {{ version.substring(0, 7) }}, {{ $t('Common.role') }}: {{ role }}
      </v-list-subheader>
    </v-list>
  </l-menu>
</template>

<script lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import AppAvatar from '@/app/components/AppAvatar.vue';
import { config } from '@/app/config';
import { i18nService } from '@/app/i18n/i18n.service';
import { Locale } from '@/app/i18n/locale';
import appService from '@/app/services/app.service';
import { pipelineOrchestratorService } from '@/case-list/services/pipeline.orchestrator.service';
import { authService } from '@/common/services/auth/auth.service';
import entityService, { UserRoleId } from '@/common/services/entity.service';
import userService from '@/common/services/users/user.service';
import { API } from '@/common/types/api.types';

export default defineComponent({
  components: {
    AppAvatar,
  },

  data() {
    return {
      authService,
      statusToggle: 1,
      menu: false,
      version: config.VERSION,
      roles: entityService.USER_ROLES,
      forceDisableSSO: localStorage.getItem('forceDisableSSO') === 'true',
      availableLocales: i18nService.supportedLocales().map((l) => ({ title: l.getNativeName(), value: l })),
      localeChangeRequest: null as Locale | null,
    };
  },

  computed: {
    isLoggedIn() {
      return authService.state.isLoggedIn;
    },
    tenant() {
      return authService.state.data?.tenant;
    },
    role() {
      return authService.state.data?.role;
    },
    selectedLocale: {
      get() {
        return i18nService.state.locale;
      },
      set(value: Locale) {
        this.localeChangeRequest = value;
        // do not wait here, we'll wait 5s afterwards
        userService.updateUserLocale(authService.state.userId!, value);
        setTimeout(() => {
          window.location.reload();
          this.localeChangeRequest = null;
        }, 5000);
      },
    },
    currentYear() {
      return dayjs().year();
    },
  },

  methods: {
    runSupervisor() {
      pipelineOrchestratorService.runSupervisor();
      appService.info('Supervisor run started.');
    },
    requestTasksOnTenant(step: API.SourceFile.Status) {
      appService.confirm(
        'Run tasks?',
        `${step}<br>${config.ENVIRONMENT}<br>${this.tenant?.name}<br>${this.statusToggle ? 'Error Only ' : 'Ready Only'}`,
        'Run tasks',
        () => {
          pipelineOrchestratorService.requestTasksOnTenant(step, this.statusToggle === 1 ? 'ERROR' : 'READY').then((res) => {
            // @ts-expect-error incompatibility with JS
            appService.info(`Requested ${res.data} tasks to run.`);
          });
        },
        'primary',
        config.ENVIRONMENT,
      );
    },
    requestTaskRestart() {
      appService.confirm(
        'Restart sourcefiles in their failed state?',
        `Reset tasks for all sourcefiles<br>${config.ENVIRONMENT}<br>${this.tenant?.name}`,
        'Restart',
        () => {
          pipelineOrchestratorService
            .requestTaskRestart()
            .then((res) => {
              // @ts-expect-error incompatibility with JS
              appService.info(`Requested reset for ${res.data} tasks.`);
            })
            .catch((err) => {
              appService.error(`Error: ${err.message}`);
            });
        },
        'primary',
        config.ENVIRONMENT,
      );
    },
    clearLocalCache() {
      localStorage.clear();
      caches.keys().then((keyList) => {
        keyList.forEach((key) => caches.delete(key));
      });
      location.reload();
    },
    clearBackendCaches() {
      appService.confirm('Clear backend caches?', 'All backend caches will be cleared.', 'Clear caches', () => {
        axios.delete(`${config.API.INTERNAL.CLEAR_CACHE}`).then(() => {
          appService.info('All backend caches have been cleared');
        });
      });
    },
    async sendTestEmail() {
      appService.state.testEmailDialog = true;
    },
    logout() {
      authService.logout();
    },
    switchRole(to: UserRoleId) {
      appService.info(this.$t('App.Bar.AppMenu.roleChangedTo', [to]));
      authService.switchRole(to);
    },
    showTransferDialog() {
      this.$router
        .push({
          name: 'transfer',
        })
        .then(() => {});
    },
    setForceDisableSSO(value: boolean) {
      this.forceDisableSSO = value;
      localStorage.setItem('forceDisableSSO', value ? 'true' : 'false');
    },
  },
});
</script>

<style lang="scss" scoped></style>
