import search from 'approx-string-match';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import router from '@/app/router';
import icd10Service from '@/case-detail/subviews/diagnosis/services/icd10.service';
import { doctypeService } from '@/case-detail/subviews/labels/services/doctype.service';
import { authService } from '@/common/services/auth/auth.service';
import fileService from '@/common/services/file.service';

class ExplorerService {
  constructor() {
    this.webViewerInstancePromise = null;
    this.documentCache = new Map();

    // pdf annotation handling
    this.annotationKey = null;
    this.annotationIndex = 0;
    this.annotations = null;

    this.state = reactive({
      isLoading: false,
      selectedCase: null,
      cases: [],
      filteredCaseDocuments: [],
      loadingDocuments: false,
      selectedDocument: null,
      selectedDocumentPdf: null,
      selectedCaseSearchTerm: null,
      search: {
        searchTerms: [],
      },
    });
  }

  async init() {
    this.state.isLoading = true;
    await this.fetchCases();
    this.state.isLoading = false;
  }

  async loadResults() {
    this.state.isLoading = true;
    if (!this.state.selectedCase) {
      // use first case in the list
      const cases = this.getExploredCases();
      if (cases.length === 0) {
        this.state.isLoading = false;
        return;
      }
      this.setSelectedCase(cases[0]);
    }
    await this.loadCaseDocuments();
    this.state.isLoading = false;
  }

  destroy() {
    this.state.selectedCase = null;
    this.state.cases = [];
    this.state.search.searchTerms = [];
    this.state.filteredCaseDocuments = [];
    this.documentCache.clear();
    this.state.selectedCaseSearchTerm = null;
  }

  setSelectedCase(legalCase) {
    this.state.selectedCase = legalCase;
    if (legalCase) {
      router.push({
        name: 'explorer',
        params: { tenant: authService.state.data?.tenant.canonicalName, caseId: legalCase.id },
      });
    }
  }

  async fetchCases() {
    return axios
      .get(config.API.EXPLORER.replace('/{legalCaseId}', ''))
      .then((response) => {
        this.state.cases = response.data;
      })
      .catch((e) => {
        handleError($t('CaseExplorer.casesLoadError'), e);
        this.state.cases = [];
      });
  }

  async fetchCase(caseId) {
    return axios
      .get(`${config.API.EXPLORER.replace('{legalCaseId}', caseId)}`)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseExplorer.caseLoadError'), e);
        return null;
      });
  }

  async loadCaseDocuments() {
    this.state.loadingDocuments = true;
    const caseId = this.state.selectedCase?.id;
    if (!caseId) {
      this.state.loadingDocuments = false;
      return;
    }

    if (!this.documentCache.has(caseId)) {
      try {
        const documentResponse = await axios.get(`${config.API.EXPLORER.replace('{legalCaseId}', caseId)}/documents`, {
          headers: { 'Content-Type': 'text/plain' },
        });
        this.documentCache.set(caseId, documentResponse.data);
      } catch (e) {
        handleError($t('CaseDetail.Document.documentsLoadError'), e);
        return;
      }
    }
    this.filterDocuments(this.documentCache.get(caseId));

    // show first pdf
    if (this.state.filteredCaseDocuments.length > 0) {
      await this.showDocument(this.state.filteredCaseDocuments[0]);
    }

    this.state.loadingDocuments = false;
  }

  async filterDocumentsWithCaseSearchTerm(searchTerm) {
    this.state.selectedCaseSearchTerm = searchTerm;
    await this.loadCaseDocuments();
  }

  filterDocuments(caseDocuments) {
    if (this.state.search.searchTerms.length > 0) {
      const fcd = [];
      for (const caseDoc of caseDocuments) {
        const searchTerms = this.documentSearchTerms(caseDoc, this.state.selectedCaseSearchTerm);
        if (searchTerms.length === this.state.search.searchTerms.length) {
          fcd.push({
            ...caseDoc,
            searchTerms,
          });
        }
      }

      // sort
      this.state.filteredCaseDocuments = fcd.sort((a, b) => b.searchTerms.length - a.searchTerms.length);
    } else {
      this.state.filteredCaseDocuments = [...caseDocuments];
    }

    this.annotationKey = null;
    this.annotations = null;
    this.annotationIndex = 0;
  }

  async showDocument(document) {
    this.state.selectedDocument = document;
    const url = fileService.getUrlSync(document.fileUri);

    this.state.selectedDocumentPdf = {
      url,
      headers: {
        Authorization: `Bearer ${await authService.getToken()}`,
        'X-Tenant-ID': authService.state.data?.tenant.id,
      },
    };

    const { Core } = await this.webViewerInstancePromise;
    return new Promise((resolve) => {
      Core.documentViewer.addEventListener('documentLoaded', () => resolve(), {
        once: true,
      });
    });
  }

  async showDiagnosesAnnotations(key, xfdfs) {
    const { Core } = await this.webViewerInstancePromise;

    if (this.annotationKey !== key) {
      this.annotationKey = key;
      this.annotationIndex = 0;

      const importAnnotationsPromises = [];
      for (const xfdf of xfdfs) {
        const xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/"
              xml:space="preserve"><fields /><annots>${xfdf}</annots></xfdf>`;
        importAnnotationsPromises.push(Core.annotationManager.importAnnotations(xfdfString));
      }
      const importAnnotations = await Promise.all(importAnnotationsPromises);
      this.annotations = importAnnotations.flat();
    } else {
      this.annotationIndex = (this.annotationIndex + 1) % this.annotations.length;
    }
    await this.jumpToNextAnnotation();
  }

  async showFullTextAnnotations(document, searchTerm) {
    const { Core } = await this.webViewerInstancePromise;

    if (this.annotationKey !== document.id + searchTerm.id) {
      this.annotationKey = document.id + searchTerm.id;
      this.annotationIndex = 0;

      const fullTextAnnotationsPromises = [];
      for (const searchResult of searchTerm.searchResults) {
        const page = searchResult.sourceFilePage - document.sourceFileStartPage + 1;
        const pdfDocument = Core.documentViewer.getDocument();
        for (const keyword of searchResult.keywords) {
          fullTextAnnotationsPromises.push(
            pdfDocument.loadPageText(page).then((pageText) => this.fuzzySearchPage(pdfDocument, keyword, page, pageText)),
          );
        }
      }

      const fullTextAnnotations = await Promise.all(fullTextAnnotationsPromises);

      this.annotations = fullTextAnnotations.reduce((a, b) => a.concat(b), []);
      Core.annotationManager.addAnnotations(this.annotations);
    } else {
      this.annotationIndex = (this.annotationIndex + 1) % this.annotations.length;
    }

    await this.jumpToNextAnnotation();
  }

  async jumpToNextAnnotation() {
    const { Core } = await this.webViewerInstancePromise;

    Core.annotationManager.deselectAllAnnotations();
    Core.annotationManager.selectAnnotation(this.annotations[this.annotationIndex]);

    Core.annotationManager.jumpToAnnotation(this.annotations[this.annotationIndex]);
  }

  async fuzzySearchPage(pdfDocument, keyword, pageIndexOne, pageText) {
    const { Core } = await this.webViewerInstancePromise;
    const annotations = [];
    const noOfSpaces = keyword.split(' ').length - 1;
    const matches = search(pageText, keyword, 15 + noOfSpaces /* max errors */);

    if (matches.length > 0) {
      for (const match of matches) {
        if (match.start >= match.end) {
          continue;
        }

        const { Annotations } = Core;
        const annotation = new Annotations.TextHighlightAnnotation();
        annotation.PageNumber = pageIndexOne;
        annotation.Quads = await pdfDocument.getTextPosition(pageIndexOne, match.start, match.end);

        annotation.ReadOnly = true;
        annotation.Printable = false;

        annotations.push(annotation);
      }
    }
    return annotations;
  }

  casesSearched() {
    return this.state.search.searchTerms.length > 0;
  }

  getExploredCases() {
    let cases = this.state.cases.filter((c) => c.canBeOpened);

    // we only filter by the search terms, aka what the explorer uses
    if (this.state.search.searchTerms?.length > 0) {
      const nf = [];
      for (const lc of cases) {
        const cs = this.caseSearchTerms(lc.id);
        // Note (ndv): match all
        if (cs.length === this.state.search.searchTerms.length) {
          const totalSearchResults = cs.reduce((previousValue, currentValue) => previousValue + currentValue.count, 0);
          lc.score = cs.length * 10000 + totalSearchResults;
          lc.caseSearchTerms = cs;
          nf.push(lc);
        }
      }

      nf.sort((a, b) => b.score - a.score);
      cases = nf;
    }

    return cases;
  }

  async fullTextSearch(searchTerm) {
    this.state.isLoading = true;
    this.showExplorer();

    for (const st of this.state.search.searchTerms) {
      if (st.searchTerm === searchTerm && !st.isDiagnosis) {
        // ignore same query
        return;
      }
    }

    try {
      const response = await axios.post(config.API.SEARCH_ENDPOINT.SEARCH_CASES, { searchTerm });
      if (Object.keys(response.data).length >= 0) {
        const lcSearchTerms = new Map();
        for (const [key, value] of Object.entries(response.data)) {
          lcSearchTerms.set(key, value);
        }
        this.state.search.searchTerms.push({
          id: uuidv4(),
          searchTerm,
          lcSearchTerms,
        });
      }
    } catch (e) {
      handleError($t('CaseExplorer.fullTextSearchError'), e);
    }
    this.state.isLoadin = false;
  }

  diagnosisSearch(diagnosis) {
    this.state.isLoading = true;
    this.showExplorer();

    for (const st of this.state.search.searchTerms) {
      if (st.code === diagnosis.code && st.qualification === diagnosis.qualification && st.isDiagnosis) {
        // ignore same query
        return;
      }
    }
    const lcSearchTerms = new Map();
    for (const lc of this.state.cases) {
      for (const lcDiagnosis of lc.diagnoses) {
        // sanity check
        if (!lcDiagnosis?.qualifications) continue;
        // filter by code and qualification
        if (lcDiagnosis.code === diagnosis.code && Object.keys(lcDiagnosis.qualifications).includes(diagnosis.qualification)) {
          lcSearchTerms.set(lc.id, { hits: lcDiagnosis.count });
          break;
        }
      }
    }

    this.state.search.searchTerms.push({
      id: uuidv4(),
      isDiagnosis: true,
      searchTerm: `${diagnosis.code} ${diagnosis.title}`,
      code: diagnosis.code,
      qualification: diagnosis.qualification,
      lcSearchTerms,
    });
    this.state.isLoading = false;
  }

  labelsSearch(doctype) {
    this.state.isLoading = true;
    this.showExplorer();

    for (const st of this.state.search.searchTerms) {
      if (st.key === doctype.key && st.isLabel) {
        // ignore same query
        return;
      }
    }
    const lcSearchTerms = new Map();
    for (const lc of this.state.cases) {
      if (lc.docTypes) {
        for (const lcDoctype of lc.docTypes) {
          if (lcDoctype.key === doctype.key) {
            lcSearchTerms.set(lc.id, { hits: lcDoctype.count });
            break;
          }
        }
      }
    }
    const label = this.getLabelByDoctype(doctype.key);

    const rgb = label.color
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    const textColor = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? 'black' : 'white';
    this.state.search.searchTerms.push({
      id: uuidv4(),
      isLabel: true,
      searchTerm: label.text,
      icon: label.icon,
      color: label.color,
      code: doctype.key,
      textColor,
      lcSearchTerms,
    });
    this.state.isLoading = false;
  }

  authorsSearch(author) {
    this.state.isLoading = true;
    this.showExplorer();

    for (const st of this.state.search.searchTerms) {
      if (st.code === author.key) {
        // ignore same query
        return;
      }
    }
    const lcSearchTerms = new Map();
    for (const lc of this.state.cases) {
      for (const lcAuthor of lc.authors) {
        // sanity check
        if (!lcAuthor) continue;
        // filter
        if (lcAuthor.key === author.key) {
          lcSearchTerms.set(lc.id, { hits: lcAuthor.count });
          break;
        }
      }
    }

    this.state.search.searchTerms.push({
      id: uuidv4(),
      isAuthor: true,
      searchTerm: author.key,
      icon: 'mdi-doctor',
      color: 'medical',
      textColor: 'white',
      code: author.key,
      lcSearchTerms,
    });
    this.state.isLoading = false;
  }

  showExplorer() {
    if (router.currentRoute.value.name !== 'explorer') {
      router.push({
        name: 'explorer',
        params: { tenant: authService.state.data?.tenant.canonicalName },
      });
    }
  }

  removeSearchTerm(id) {
    if (this.state.search.searchTerms.length === 1) {
      this.clearSearchTerms();
      return;
    }

    this.state.search.searchTerms = this.state.search.searchTerms.filter((st) => st.id !== id);
    if (this.state.search.searchTerms.length === 0) {
      this.state.selectedCase = null;
    }
  }

  clearSearchTerms() {
    this.state.search.searchTerms = [];
    this.state.selectedCase = null;
  }

  caseSearchTerms(legalCaseId) {
    const result = [];
    for (const st of this.state.search.searchTerms) {
      if (st.lcSearchTerms.has(legalCaseId)) {
        const lcs = st.lcSearchTerms.get(legalCaseId);
        result.push({
          id: st.id,
          isDiagnosis: st.isDiagnosis,
          isLabel: st.isLabel,
          searchTerm: st.searchTerm,
          code: st.code,
          qualification: st.qualification,
          icon: st.icon,
          color: st.color,
          textColor: st.textColor,
          count: lcs.hits,
        });
      }
    }
    return result;
  }

  documentSearchTerms(document, caseSearchTerm = null) {
    const legalCaseId = this.state.selectedCase.id;
    const result = [];
    for (const st of this.state.search.searchTerms) {
      if (caseSearchTerm && caseSearchTerm.id !== st.id) {
        continue;
      }

      if (st.lcSearchTerms.has(legalCaseId)) {
        if (st.isDiagnosis) {
          const diagnosesSearchResults = [];
          for (const docDiagnosis of document.diagnoses) {
            if (docDiagnosis.code === st.code) {
              diagnosesSearchResults.push(docDiagnosis.xfdf);
            }
          }
          if (diagnosesSearchResults.length > 0) {
            result.push({
              id: st.id,
              isDiagnosis: st.isDiagnosis,
              searchTerm: st.searchTerm,
              code: st.code,
              count: diagnosesSearchResults.length,
              searchResults: diagnosesSearchResults,
            });
          }
        } else if (st.isLabel) {
          if (document.docType === st.code) {
            result.push({
              id: st.id,
            });
          }
        } else if (st.isAuthor) {
          if (document.author === st.code) {
            result.push({
              id: st.id,
            });
          }
        } else {
          const lcs = st.lcSearchTerms.get(legalCaseId);
          const docSearchResults = [];
          for (const lcsDoc of lcs.documents) {
            if (
              lcsDoc.sourceFileId === document.sourceFileId &&
              document.sourceFileStartPage <= lcsDoc.sourceFilePage &&
              lcsDoc.sourceFilePage <= document.sourceFileEndPage
            ) {
              lcsDoc.documentId = document.id;
              docSearchResults.push(lcsDoc);
            }
          }
          if (docSearchResults.length > 0) {
            result.push({
              id: st.id,
              searchTerm: st.searchTerm,
              count: docSearchResults.reduce((prevValue, currValue) => prevValue + currValue.count, 0),
              searchResults: docSearchResults,
            });
          }
        }
      }
    }
    return result;
  }

  getLabelByDoctype(doctype) {
    return doctypeService.getDoctypeLabelByType(doctype);
  }

  /**
   *
   * @param {Array} query
   * @returns Promise
   */
  async getFullTextSuggestions(query) {
    if (!query || query.trim() === '') {
      return Promise.resolve({ data: [] });
    }

    if (this.previousSuggestionRequestCancelToken) {
      this.previousSuggestionRequestCancelToken.cancel();
    }
    this.previousSuggestionRequestCancelToken = axios.CancelToken.source();
    return axios
      .post(config.API.SEARCH_ENDPOINT.SEARCH_SUGGESTIONS, { searchTerm: query }, { cancelToken: this.previousSuggestionRequestCancelToken.token })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          handleError($t('CaseExplorer.suggestionsLoadError'), e);
        }
        return { data: [] };
      });
  }

  /**
   *
   * @param {*} query
   * @param {int} limit, limit amount of suggestions, default 0
   * @returns Array
   */
  getDoctypesSuggestions(query, limit = 0) {
    const suggestions = new Map();
    for (const lc of this.state.cases) {
      if (lc.docTypes) {
        for (const dt of lc.docTypes) {
          const text = this.getLabelByDoctype(dt.key)?.title;
          if (text && !suggestions.has(dt.key) && text.toLowerCase().includes(query.toLowerCase())) {
            suggestions.set(dt.key, {
              id: dt.key,
              field: 'labels',
              suggestion: text,
              value: {
                ...dt,
              },
            });
            if (limit > 0 && suggestions.size === limit) {
              return [...suggestions.values()];
            }
          }
        }
      }
    }
    return [...suggestions.values()];
  }

  /**
   *
   * @param {*} query
   * @param {int} limit, limit amount of suggestions, default 0
   * @returns Array
   */
  getAuthorsSuggestions(query, limit = 0) {
    const suggestions = new Map();
    for (const lc of this.state.cases) {
      if (lc.authors) {
        for (const lcAuthor of lc.authors) {
          if (lcAuthor && !suggestions.has(lcAuthor.key) && lcAuthor.key.toLowerCase().includes(query.toLowerCase())) {
            suggestions.set(lcAuthor.key, {
              id: lcAuthor.key,
              field: 'authors',
              suggestion: lcAuthor.key,
              value: {
                ...lcAuthor,
              },
            });
            if (limit > 0 && suggestions.size === limit) {
              return [...suggestions.values()];
            }
          }
        }
      }
    }

    return [...suggestions.values()];
  }

  /**
   * return suggestions
   * @param {Array} diagnoses
   * @param {Array} query
   * @param {int} limit amount of suggestions, default 0
   * @returns array with suggestions, contains json object with id, field, suggestion, suggestionSubtitle.
   */
  getDiagnosisSuggestions(diagnoses, query, limit = 0) {
    // ignore short queries
    if (!query || query.length < 3) return [];
    // compute suggestions
    const suggestions = [];
    for (const diagnosis of diagnoses) {
      const queryLowerCase = query.toLowerCase();
      const diagnosisTitleMatch = diagnosis.title.toLowerCase().indexOf(queryLowerCase) !== -1;
      const diagnosisCodeMatch = diagnosis.code.toLowerCase() === queryLowerCase;
      if (diagnosisTitleMatch || diagnosisCodeMatch) {
        // add a suggestion for each qualification
        for (const q of diagnosis.qualifications) {
          const suggestionSubtitle = icd10Service.tags[q] != null ? icd10Service.tags[q].titleShort : '';
          suggestions.push({
            id: `${diagnosis.code}_${q}`,
            field: 'diagnosis',
            suggestion: `${diagnosis.code}: ${diagnosis.title}`,
            suggestionSubtitle,
            value: {
              ...diagnosis,
              qualification: q,
            },
          });
        }
        if (limit > 0 && suggestions.length === limit) {
          return suggestions;
        }
      }
    }
    return suggestions;
  }
}

export default new ExplorerService();
export const ExplorerServiceClass = ExplorerService;
