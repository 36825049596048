import { reactive } from 'vue';

import router from '@/app/router';
import { PanelId, PanelMetadata, panels, SlotId } from '@/case-detail/services/detail.view.panels.meta';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import { pdftronHelper } from '@/case-detail/subviews/document/services/pdftron.helper';
import { legalCaseAPIClient } from '@/common/clients/legalcase.api.client';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import detachedWindowService from '@/common/services/detached-window.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export type ViewId = 'CASE' | 'DOCS' | 'SHARING' | 'TOOLS';

export const viewPanelsMap: Record<ViewId, PanelId[]> = {
  CASE: ['CaseOverview', 'Copilot', 'WebViewer', 'DocumentList', 'Notebook', 'LegalCaseAccess'],
  DOCS: [
    'WebViewer',
    'DuplicatesReview',
    'DocumentList',
    'Labels',
    'Authors',
    'Diagnosis',
    'WorkInabilities',
    'Copilot',
    'LegalCaseAccess',
    'Notebook',
  ],
  SHARING: [
    'WebViewer',
    'DuplicatesReview',
    'DocumentList',
    'Labels',
    'Authors',
    'Diagnosis',
    'WorkInabilities',
    'Copilot',
    'LegalCaseAccess',
    'Notebook',
    'Export',
    'Collaboration',
  ],

  // legali admin-only
  TOOLS: ['Timelines', 'FormData', 'PII', 'Metadata'],
};

export const requiredPanelPerView: Record<ViewId, PanelId[]> = {
  CASE: ['CaseOverview'],
  DOCS: ['WebViewer'],
  SHARING: ['DocumentList'],
  TOOLS: [],
};

export type MedInsightsMode = 'diagnoses' | 'workInabilities';

class DetailViewService {
  state: {
    view: ViewId;
    panels: Record<ViewId, PanelId[]>;
    currentLegalCase: API.LegalCase.Response | null;
    currentCopilotScope: Record<ViewId, API.Copilot.Scope>;
    currentMedInisightsMode: MedInsightsMode | null;
  };

  panels: Record<PanelId, PanelMetadata>;

  constructor() {
    this.state = reactive({
      view: 'DOCS',
      panels: { CASE: [], DOCS: [], SHARING: [], TOOLS: [] },
      currentLegalCase: null,
      currentCopilotScope: {
        CASE: 'LEGALCASE',
        DOCS: 'DOCUMENT',
        SHARING: 'LEGALCASE', // just a placeholder
        TOOLS: 'DOCUMENT', // just a placeholder
      },
      currentMedInisightsMode: null,
    });

    this.panels = panels;
  }

  // CURRENT LEGAL CASE

  getCurrentLegalCaseId() {
    // NOTE(dp): `undefined` could be returned as well
    // one example of this is going to big case and quickly navigating back to the list - init load functions will still run and call this method
    // for simplicity `string` type is left to not add stupid checks everywhere
    // BUT (!) if you use this method in init-load-type methods — adding manual check for undefined is good idea
    return this.state.currentLegalCase?.id ?? (router.currentRoute.value.params.caseId as string);
  }

  getCurrentLegalCase() {
    return this.state.currentLegalCase;
  }

  async setCurrentLegalCase(caseId: UUID) {
    if (this.state.currentLegalCase && caseId === this.state.currentLegalCase.id) return;
    this.state.currentLegalCase = await legalCaseAPIClient.fetch(caseId);
  }

  async refreshCurrentLegalCase() {
    if (!this.state.currentLegalCase) return;
    this.state.currentLegalCase = await legalCaseAPIClient.fetch(this.state.currentLegalCase.id);
  }

  async updateCurrentLegalCase(newData: API.LegalCase.CreateUpdateRequest) {
    if (!this.state.currentLegalCase) return;
    await legalCaseAPIClient.update(this.state.currentLegalCase.id, newData);
    this.refreshCurrentLegalCase();
  }

  async toggleCurrentLegalCaseArchive() {
    if (!this.state.currentLegalCase) return;
    const legalCase = this.state.currentLegalCase;
    const newStatus = legalCase.legalCaseStatus === 'OPEN' ? 'ARCHIVED' : 'OPEN';
    await legalCaseAPIClient.updateStatus(legalCase.id, newStatus);
    this.refreshCurrentLegalCase();
  }

  async updateCurrentLegalCaseMetadata(key: API.LegalCase.PiiKey, value: string) {
    if (!this.state.currentLegalCase) return;
    await legalCaseAPIClient.updateMetadata(this.state.currentLegalCase.id, key, value);
    this.refreshCurrentLegalCase();
  }

  // COPILOT SCOPE

  getCurrentCopilotScope(view?: ViewId): API.Copilot.Scope {
    const scope = this.state.currentCopilotScope[view ?? this.state.view];
    if (scope === 'LEGALCASE' && !authService.hasFeature('ENABLE_CASEPILOT')) {
      return 'DOCUMENT';
    }
    if (scope === 'DOCUMENT' && !authService.hasFeature('ENABLE_DOCPILOT')) {
      return 'LEGALCASE';
    }
    return scope;
  }

  setCopilotScope(scope: API.Copilot.Scope) {
    this.state.currentCopilotScope[this.state.view] = scope;
  }

  // PANELS

  isViewOpen(viewId: ViewId) {
    return this.state.view === viewId;
  }

  getOpenView() {
    return this.state.view;
  }

  getOpenPanels(viewId?: ViewId): PanelId[] {
    return [...this.state.panels[viewId ?? this.state.view], ...this.state.panels.TOOLS];
  }

  openView(viewId: ViewId) {
    if (this.state.view === viewId) {
      return;
    }

    if (
      (viewId === 'CASE' &&
        !authService.hasFeature('ENABLE_CASEHISTORY') &&
        !authService.hasFeature('ENABLE_DASHBOARDS') &&
        !authService.hasFeature('ENABLE_ANALYTICS')) ||
      (viewId === 'SHARING' && !authService.hasFeature('ENABLE_EXPORT') && !authService.hasFeature('ENABLE_COLLABORATION'))
    ) {
      return;
    }

    if (viewId === 'CASE') {
      pdftronHelper.switchMode('case');
    } else {
      pdftronHelper.switchMode('normal');
    }

    this.state.view = viewId;
    detailViewRouteService.persistInQuery({ openView: viewId, openPanels: this.getOpenPanels(viewId) });
  }

  isPanelClosable(panelId: PanelId) {
    // Labels panel depends on DocList
    return !requiredPanelPerView[this.getOpenView()].includes(panelId) && panelId !== 'Labels';
  }

  togglePanel(panelId: PanelId) {
    if (this.isPanelOpened(panelId)) {
      this.closePanel(panelId);
    } else {
      this.openPanel(panelId);
    }
  }

  isPanelEnabled(panelId: PanelId) {
    return this.panels[panelId].enabled(authService);
  }

  isPanelOpened(panelId: PanelId, viewId?: ViewId) {
    if (panelId === 'Labels') {
      panelId = 'DocumentList';
    }
    return this.getOpenPanels(viewId).includes(panelId);
  }

  openPanel(panelId: PanelId) {
    if (panelId === 'Labels') {
      panelId = 'DocumentList';
    }

    if (this.isPanelOpened(panelId)) {
      return;
    }

    const panel = panels[panelId];

    if (panel?.detachable) {
      const windowName = detachedWindowService.getPanelWindowName(panelId);
      const window = detachedWindowService.getWindowReference(windowName);
      if (window) {
        window.focus();
        return;
      }
    }

    this.closeSlot(panel.slot);

    if (viewPanelsMap.TOOLS.includes(panelId)) {
      this.state.panels.TOOLS.push(panelId);
    } else if (viewPanelsMap[this.state.view].includes(panelId)) {
      this.state.panels[this.state.view].push(panelId);
    } else {
      // invalid panels for the view
      return;
    }

    detailViewRouteService.persistInQuery({ openPanels: this.getOpenPanels() });
  }

  closePanel(panelId: PanelId) {
    if (panelId === 'Labels') {
      panelId = 'DocumentList';
    }

    if (!this.isPanelOpened(panelId)) {
      return;
    }

    if (viewPanelsMap.TOOLS.includes(panelId)) {
      this.state.panels.TOOLS = this.state.panels.TOOLS.filter((p) => p !== panelId);
    } else {
      this.state.panels[this.state.view] = this.state.panels[this.state.view].filter((p) => p !== panelId);
    }

    if (panelId === 'DuplicatesReview') {
      this.openPanel('WebViewer');
    } else if (panelId === 'DocumentList') {
      // close doc filters as well
      this.closeSlot('DocumentListExtension');
    } else if (panelId === 'Copilot') {
      // on Copilot close reset to default scope for current view
      if (this.isViewOpen('CASE') && this.state.currentCopilotScope.CASE !== 'LEGALCASE' && authService.hasFeature('ENABLE_CASEPILOT')) {
        this.state.currentCopilotScope.CASE = 'LEGALCASE';
      } else if (this.isViewOpen('DOCS') && this.state.currentCopilotScope.DOCS !== 'DOCUMENT' && authService.hasFeature('ENABLE_DOCPILOT')) {
        this.state.currentCopilotScope.CASE = 'DOCUMENT';
      }
    }

    detailViewRouteService.persistInQuery({ openPanels: this.getOpenPanels() });
  }

  closeSlot(slotId: SlotId) {
    const items = Object.entries(this.panels).filter(
      ([, panel]) => panel.slot === slotId || (Array.isArray(panel.slot) && panel.slot.includes(slotId)),
    ) as [PanelId, PanelMetadata][];

    const panelIdsToClose = items.map(([panelId]) => panelId);

    this.state.panels.TOOLS = this.state.panels.TOOLS.filter((p) => !panelIdsToClose.includes(p));
    this.state.panels[this.state.view] = this.state.panels[this.state.view].filter((p) => !panelIdsToClose.includes(p));
  }

  initArrangement() {
    const initViewParam = detailViewRouteService.getFromQuery('openView');

    let initPanelsParam = detailViewRouteService.getFromQuery('openPanels') ?? [];
    // handle legacy link when we used to call case overview "CasePilot"
    initPanelsParam = initPanelsParam.map((p) => ((p as string) === 'CasePilot' ? 'CaseOverview' : p));
    // don't allow Duplicates because it won't have originalDocument in duplicates service
    initPanelsParam = initPanelsParam.filter((p) => p !== 'DuplicatesReview');
    // leave enabled panels only
    initPanelsParam = initPanelsParam.filter((p) => panels[p].enabled(authService));

    const initTicketIdParam = detailViewRouteService.getFromQuery('ticketId');

    this.initDefaultArrangement();

    // special case - link to specific ticket
    if (initTicketIdParam) {
      this.openView('SHARING');
      this.openPanel('Collaboration');
      return;
    }

    // catch invalid state
    if (!initViewParam || !initPanelsParam?.length) {
      return;
    }
    if (!initPanelsParam.every((p) => viewPanelsMap[initViewParam].includes(p) || viewPanelsMap.TOOLS.includes(p))) {
      return;
    }

    // make sure all required are present
    initPanelsParam.push(...requiredPanelPerView[initViewParam].filter((p) => !initPanelsParam.includes(p)));

    // don't preserve opened duplicates panel
    initPanelsParam = initPanelsParam.filter((p) => p !== 'DuplicatesReview');

    this.openView(initViewParam);
    for (const panel of initPanelsParam) {
      this.openPanel(panel);
    }

    broadcastEventBus.once('DOCUMENT_LOADED_EVENT', () => pdftronHelper.switchMode(initViewParam === 'CASE' ? 'case' : 'normal'));
  }

  initDefaultArrangement() {
    this.state.panels = {
      CASE: this.defaultPanelState('CASE'),
      DOCS: this.defaultPanelState('DOCS'),
      SHARING: this.defaultPanelState('SHARING'),
      TOOLS: [],
    };

    const defaultView = authService.state.data?.tenant.tenantConfig.legalCaseConfig.defaultView;
    if (!defaultView || (defaultView === 'CASE' && !authService.hasFeature('ENABLE_CASEHISTORY') && !authService.hasDashboards())) {
      this.state.view = 'DOCS';
    } else {
      this.state.view = defaultView;
    }
  }

  defaultPanelState(viewId: ViewId): PanelId[] {
    switch (viewId) {
      case 'DOCS':
        return ['DocumentList', 'WebViewer'];
      case 'CASE':
        return ['CaseOverview'];
      case 'SHARING':
        if (panels.Export.enabled(authService)) {
          return ['Export', 'DocumentList', 'WebViewer'];
        }
        return ['Collaboration', 'DocumentList', 'WebViewer'];
      default:
        return [];
    }
  }

  clear() {
    this.state.currentLegalCase = null;
    this.state.currentMedInisightsMode = null;
    this.initArrangement();
  }
}

export default new DetailViewService();
