import COPILOT_FORMAT_TWEAKS from '@/common/entities/COPILOT_FORMAT_TWEAKS.json';
import { AnalyticsGeneratorConfig, CopilotDashboardHeading, CopilotDashboardItem } from '@/common/generated-types/openapi';
import { CopilotCatalogQuestionLocalized } from '@/common/services/entity.service';
import { ObjectValues } from '@/common/types/common.types';

// Project-specific scope that isn't in the API
export type Scope = 'LEGALCASE' | 'DOCUMENT' | 'DASHBOARD';

export type AnswerTweak = keyof typeof COPILOT_FORMAT_TWEAKS;

export type ContextStrategy =
  | 'NONE'
  | 'DOCUMENT'
  | 'DOCUMENT_PAGE'
  | 'FULL_LEGALCASE'
  | 'PLAN'
  | 'TOC'
  | 'DOCS_BY_DOCTYPES'
  | 'MEDICAL_HISTORY'
  | 'DOCTYPE_LOOKUP'
  | 'TOC_LOOKUP'
  | 'JUSTEMENT';

export const agents = {
  PLAN: 'PLAN',
  GENERAL: 'GENERAL',
  MEDICAL: 'MEDICAL',
  EXPLAINING: 'EXPLAINING',
} as const;
export type Agent = ObjectValues<typeof agents>;

export const multipleAnswers = {
  SINGLE: 'SINGLE',
  BIASED: 'BIASED',
  PLAN: 'PLAN',
} as const;
export type MultipleAnswers = ObjectValues<typeof multipleAnswers>;
export type UserLanguage = {
  value: string;
  title: string;
};

// dashboards

export type CopilotDashboardItemLocalized = CopilotDashboardHeading | CopilotCatalogQuestionLocalized | AnalyticsGeneratorConfig;

export type Automation = 'DISABLED' | 'INITIAL' | 'EVERY';

export interface CopilotDashboard {
  key: string;
  order: number;
  enabled: boolean;
  icon: string;
  title: {
    [key: string]: string;
  };
  automation: Automation;
  items: CopilotDashboardItem[];
}

export interface CopilotDashboardLocalized {
  key: string;
  order: number;
  enabled: boolean;
  icon: string;
  title: string;
  automation: Automation;
  items: CopilotDashboardItemLocalized[];
}
