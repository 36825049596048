import { cloneDeep } from 'lodash';
import { reactive } from 'vue';

import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import { LabelResponse } from '@/common/generated-types/openapi';
import { mergeIntoReactive } from '@/common/services/common.utils';
import { openApiClientService } from '@/common/services/openapi.client.service';
import { UUID } from '@/common/types/common.types';

const initialState = {
  labels: [] as LabelResponse[],
  isLoaded: false,
  legalCaseId: '',
};

export class LabelService {
  state: typeof initialState;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
  }

  async load(legalCaseId: UUID) {
    const client = await openApiClientService.getClient();
    const response = await client.listLabels();
    this.state.labels = response.data;
    this.state.isLoaded = true;
    this.state.legalCaseId = legalCaseId;
  }

  getLabelById(id: UUID) {
    return this.state.labels.find((l) => l.id === id);
  }

  async create(title: string, color: string, icon: LabelResponse['icon']) {
    const client = await openApiClientService.getClient();

    try {
      const label = (await client.createLabel({ legalCaseId: this.state.legalCaseId }, { title, color, icon })).data;
      this.state.labels.push(label);
      appService.info($t('CaseDetail.Labels.labelAdded'));
    } catch (e) {
      appService.error($t('CaseDetail.Labels.failedToAdd'));
    }
  }

  getCaseLabels() {
    return this.state.labels.filter((l) => l.legalcaseId === this.state.legalCaseId);
  }

  getLabelsFromOtherCases() {
    return this.state.labels.filter((l) => l.legalcaseId !== this.state.legalCaseId);
  }

  getMdiIconName(iconId: LabelResponse['icon']) {
    return LABEL_ICONS[iconId];
  }

  async update(labelId: UUID, title: string, color: string, icon: LabelResponse['icon']) {
    const client = await openApiClientService.getClient();
    const response = await client.updateLabel({ labelId, legalCaseId: this.state.legalCaseId }, { title, color, icon });
    const data = response.data as LabelResponse;

    const label = this.state.labels.find((l) => l.id === labelId)!;
    Object.assign(label, data);
  }

  async deleteLabel(labelId: string) {
    const client = await openApiClientService.getClient();

    try {
      // FE-side update
      this.state.labels = this.state.labels.filter((l) => l.id !== labelId);
      let anyRemoved = false;
      for (const document of documentService.state.documents) {
        if (document.labels.includes(labelId)) {
          anyRemoved = true;
          document.labels = document.labels.filter((l) => l !== labelId);
        }
      }
      if (anyRemoved) {
        documentService.setOutOfSync(true);
      }

      // persist
      await client.deleteLabel({ legalCaseId: this.state.legalCaseId, labelId });
      appService.info($t('CaseDetail.Labels.labelDeleted'));
    } catch (e) {
      appService.error($t('CaseDetail.Labels.failedToDelete'));
    }
  }

  async addLabelToDocument({ documentId, labelId }: { documentId: string; labelId: string }) {
    const document = documentService.getDocumentsCache().get(documentId)!;
    if (document.labels.includes(labelId)) {
      return;
    }

    const client = await openApiClientService.getClient();

    const currentLabels = [...document.labels];
    const courrentOutOfSync = documentService.state.outOfSync;
    const undoFn = async () => {
      // FE-side update
      document.labels = currentLabels;
      documentService.setOutOfSync(courrentOutOfSync);
      // persist
      await client.deleteDocumentLabel({ documentId, labelId, legalCaseId: document.caseId });
    };

    // FE update
    document.labels.push(labelId);
    documentService.setOutOfSync(true);
    // persist
    await client.addLabelToDocument({ documentId, legalCaseId: document.caseId }, { labelId });

    appService.info($t('CaseDetail.Labels.labelAdded'), undoFn);
  }

  async deleteLabelFromDocument({ documentId, labelId }: { documentId: string; labelId: string }) {
    const document = documentService.getDocumentsCache().get(documentId)!;
    if (!document.labels.includes(labelId)) {
      return;
    }

    const client = await openApiClientService.getClient();

    const currentLabels = [...document.labels];
    const courrentOutOfSync = documentService.state.outOfSync;
    const undoFn = async () => {
      // FE-side update
      document.labels = currentLabels;
      documentService.setOutOfSync(courrentOutOfSync);
      // persist
      await client.addLabelToDocument({ documentId, legalCaseId: document.caseId }, { labelId });
    };

    // FE update
    document.labels = document.labels.filter((id) => id !== labelId);
    documentService.setOutOfSync(true);
    // persist
    await client.deleteDocumentLabel({ documentId, labelId, legalCaseId: document.caseId });

    appService.info($t('CaseDetail.Labels.labelRemoved'), undoFn);
  }

  clear() {
    mergeIntoReactive(this.state, cloneDeep(initialState));
  }
}

export const labelService = new LabelService();

export const LABEL_ICONS: Record<LabelResponse['icon'], string> = {
  DEFAULT: 'mdi-label',
  STAR: 'mdi-star',
  HEART: 'mdi-heart-circle',
  ALERT: 'mdi-alert-circle',
  EYE: 'mdi-eye-circle',
  QUESTION: 'mdi-help-circle',
  PLUS: 'mdi-plus-circle',
  MINUS: 'mdi-minus-circle',
  CHECK: 'mdi-check-circle',
  CLOSE: 'mdi-close-circle',
};
