import dayjs from 'dayjs';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import documentService, { Document } from '@/case-detail/subviews/document/services/document.service';
import documentFilterService from '@/case-detail/subviews/documents-list/services/filter/document.filter.service';
import { doctypeService } from '@/case-detail/subviews/labels/services/doctype.service';
import { labelService } from '@/case-detail/subviews/labels/services/label.service';
import { ExportConfiguration, ExportDocumentEntry, ExportResponse } from '@/common/generated-types/openapi';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { formatToLocale } from '@/common/services/date.utils';
import fileService from '@/common/services/file.service';
import { openApiClientService } from '@/common/services/openapi.client.service';
import { API } from '@/common/types/api.types';
import { MetadataKey } from '@/common/types/api-types/document.api.types';
import { UUID } from '@/common/types/common.types';

export enum EntryType {
  DOCUMENT = 'DOCUMENT',
  HEADING = 'HEADING',
}

export enum ExportView {
  ExportList = 'ExportList',
  TemplateSelection = 'TemplateSelection',
  DocumentSelection = 'DocumentSelection',
  Share = 'Share',
}

interface ExportDocumentEntryDOC extends Omit<ExportDocumentEntry, 'documentId'> {
  documentId: UUID;
  sourceFileId: string;
  fileReference: string;
  paginationNo: string;
  paginationId: string;
  labelId: string;
}
export type ExportSortingKey = ExportConfiguration['sorting'];

interface ExportSortingMeta {
  titleKey: string;
  icon?: string;
  iconSvg?: string;
}

export const SortingModes: Record<ExportSortingKey, ExportSortingMeta> = {
  MANUAL: {
    titleKey: 'CaseDetail.Export.SortModes.MANUAL',
    icon: 'mdi-hand-back-right-outline',
  },
  ISSUE_DATE_ASC: {
    titleKey: 'CaseDetail.Export.SortModes.ISSUE_DATE_ASC',
    icon: 'mdi-sort-calendar-ascending',
  },
  ISSUE_DATE_DESC: {
    titleKey: 'CaseDetail.Export.SortModes.ISSUE_DATE_DESC',
    icon: 'mdi-sort-calendar-descending',
  },
  RECEIPT_DATE_ASC: {
    titleKey: 'CaseDetail.Export.SortModes.RECEIPT_DATE_ASC',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 17h3V3h2v14h3l-4 4-4-4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
  },
  RECEIPT_DATE_DESC: {
    titleKey: 'CaseDetail.Export.SortModes.RECEIPT_DATE_DESC',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 7h3v14h2V7h3l-4-4-4 4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
  },
  PAGINATION_NO_DESC: {
    titleKey: 'CaseDetail.Export.SortModes.PAGINATION_NO_DESC',
    iconSvg:
      '<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.66397 18L3.23887 14H0L0.283401 12H3.52227L4.38057 6H1.1417L1.4251 4H4.66397L5.23887 0H6.8583L6.2834 4H11.1417L11.7166 0H13.336L12.7611 4H16L15.7166 6H12.4777L11.6194 12H14.8583L14.5749 14H11.336L10.7611 18H9.1417L9.7166 14H4.8583L4.2834 18H2.66397ZM6 6L5.1417 12H10L10.8583 6H6Z" fill="black"/> <path d="M22 14H25L21 18L17 14H20V0H22" fill="black"/> </svg>',
  },
  PAGINATION_NO_ASC: {
    titleKey: 'CaseDetail.Export.SortModes.PAGINATION_NO_ASC',
    iconSvg:
      '<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.0002 4H20.0002V18H22.0002V4H25.0002L21.0002 0L17.0002 4Z" fill="black"/> <path d="M2.66397 18L3.23887 14H0L0.283401 12H3.52227L4.38057 6H1.1417L1.4251 4H4.66397L5.23887 0H6.8583L6.2834 4H11.1417L11.7166 0H13.336L12.7611 4H16L15.7166 6H12.4777L11.6194 12H14.8583L14.5749 14H11.336L10.7611 18H9.1417L9.7166 14H4.8583L4.2834 18H2.66397ZM6 6L5.1417 12H10L10.8583 6H6Z" fill="black"/> </svg>',
  },
};

export const TocTitles = () => [
  $t('CaseDetail.Export.TocTitles.index'),
  $t('CaseDetail.Export.TocTitles.edition'),
  $t('CaseDetail.Export.TocTitles.toc'),
  $t('CaseDetail.Export.TocTitles.appendices'),
];

export type SelectedDocumentExport = ExportResponse & { prevDocumentIds: string[] };

interface LabelFilterOption {
  id: UUID | 'other';
  labelText: string;
  labelColor: string;
  labelIcon: string;
  count: number;
}

interface ServiceState {
  view: ExportView;
  error: boolean;
  isLoading: boolean;
  isFirstPreview: boolean;
  allowSave: boolean;
  showDropOverlay: boolean;

  // docs
  documentExportsList: SelectedDocumentExport[];
  documentExportHierarchyMap: Map<string, SelectedDocumentExport[]>;
  selectedDocumentExport: SelectedDocumentExport | null;
  selectedDocumentId: UUID | null;

  // filter
  doctypeFilterOptions: LabelFilterOption[];
  doctypeFilter: LabelFilterOption | null;
}

class ExportService {
  state: ServiceState;
  documentExportsMap: Map<UUID, SelectedDocumentExport> = new Map();

  // @ts-expect-error we make sure service is indeed initialized with valid currentCase, here don't include `null` type for easier typing
  currentCase: API.LegalCase.Response = {};

  constructor() {
    this.state = reactive({
      view: ExportView.ExportList,
      error: false,
      isLoading: false,
      isFirstPreview: true,
      allowSave: false,
      showDropOverlay: false,

      // docs
      documentExportsList: [],
      documentExportHierarchyMap: new Map(),
      selectedDocumentExport: null,
      selectedDocumentId: null,

      // filter
      doctypeFilterOptions: [],
      doctypeFilter: null,
    });
  }

  async init(curCase: API.LegalCase.Response) {
    this.currentCase = curCase;
    await this.fetchAll();
  }

  /** GETTERS / SETTERS */

  isLoading() {
    return this.state.isLoading;
  }

  setLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  getCurrentlySelectedDocumentIds() {
    const entries = this.state.selectedDocumentExport?.documentEntries ?? [];
    return this.filterDocEntries(entries).map((e) => e.documentId);
  }

  getPreviouslyExportedDocumentIds() {
    return this.state.selectedDocumentExport?.prevDocumentIds ?? [];
  }

  /** CRUD */

  async create(recipient: string) {
    const client = await openApiClientService.getClient();
    const response = await client.createExport(
      {
        legalCaseId: this.currentCase.id,
      },
      { recipient },
    );
    const data = response.data as ExportResponse;

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...data,
      prevDocumentIds: this.state.selectedDocumentExport?.prevDocumentIds ?? [],
    };

    this.updateLabelFilterOptions();

    this.overrideDefaultConfig();

    this.state.documentExportsList.push(this.state.selectedDocumentExport);
    this.updateMaps();

    // NOTE(ndv): exports are initialized on create using the first template found from the list, therefore template selection makes sense only when there's more than one template in the list
    if (authService.state.data && authService.state.data.tenant.tenantConfig.exportConfig.templates.length > 1) {
      this.state.view = ExportView.TemplateSelection;
    } else {
      this.state.view = ExportView.DocumentSelection;
    }
    $a.l($a.e.EXPORT_ADD);
  }

  async createUpdate(previousDocumentExport: SelectedDocumentExport) {
    const client = await openApiClientService.getClient();
    const response = await client.createExportUpdate(
      {
        legalCaseId: this.currentCase.id,
        previousExportId: previousDocumentExport.id,
      },
      {
        recipient: previousDocumentExport.recipient,
      },
    );
    const data = response.data as ExportResponse;

    const prevDocIds = this.filterDocEntries(previousDocumentExport.documentEntries).map((d) => d.documentId);
    prevDocIds.push(...previousDocumentExport.prevDocumentIds);

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      prevDocumentIds: prevDocIds,
      ...data,
    };
    this.updateLabelFilterOptions();
    // NOTE: sets filename
    this.overrideDefaultConfig();

    this.state.documentExportsList.push(this.state.selectedDocumentExport!);
    this.updateMaps();

    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_ADD_UPDATE);
  }

  async createCopy(originalExportId: UUID, recipient: string) {
    this.state.isLoading = true;

    const client = await openApiClientService.getClient();
    const response = await client.createExportCopy(
      {
        exportId: originalExportId,
        legalCaseId: this.currentCase.id,
      },
      { recipient },
    );
    const data = response.data as ExportResponse;

    this.state.documentExportsList.push({ ...data, prevDocumentIds: [] });
    this.updateMaps();
    this.state.isLoading = false;
    $a.l($a.e.EXPORT_ADD_COPY);
  }

  async saveDocuments() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    this.sort();

    const client = await openApiClientService.getClient();
    const response = await client.updateExportDocuments(
      {
        exportId: this.state.selectedDocumentExport.id,
        legalCaseId: this.currentCase.id,
      },
      {
        documentEntries: this.state.selectedDocumentExport.documentEntries,
        sorting: this.state.selectedDocumentExport.configuration.sorting,
        groupByLabels: this.state.selectedDocumentExport.configuration.groupByLabels,
      },
    );
    const data = response.data as ExportResponse;

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...data,
    };

    this.updateLabelFilterOptions();
  }

  async saveConfig() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    const client = await openApiClientService.getClient();
    const requestData = {
      ...this.state.selectedDocumentExport.configuration,
    };

    let data: ExportResponse | null = null;
    try {
      const response = await client.updateExportConfiguration(
        {
          exportId: this.state.selectedDocumentExport.id,
          legalCaseId: this.currentCase.id,
        },
        requestData,
      );
      data = response.data;
    } catch (e) {
      handleError($t('CaseDetail.Export.exportCannotBeSavedErrorMessage'), e);
    }

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...data,
    };
    this.updateLabelFilterOptions();
  }

  async update(exportId: UUID, payload: { recipient: string }) {
    const client = await openApiClientService.getClient();
    await client.updateExport({ exportId, legalCaseId: this.currentCase.id }, payload);

    let documentExport = this.state.documentExportsList.find((e) => e.id === exportId);
    if (!documentExport) return;

    documentExport = {
      ...documentExport,
      ...payload,
    };
    this.updateLocalExport(documentExport);
  }

  async delete(exportId: UUID) {
    const client = await openApiClientService.getClient();
    await client.deleteExport({ exportId, legalCaseId: this.currentCase.id });

    this.state.selectedDocumentExport = null;
    this.state.documentExportsList = this.state.documentExportsList.filter((de) => de.id !== exportId);
    this.updateMaps();

    $a.l($a.e.EXPORT_DELETE);
  }

  async publish() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    this.state.isLoading = true;

    // verify documents
    const validDocumentEntries = this.state.selectedDocumentExport.documentEntries.filter((entry) => {
      if (entry.type !== EntryType.DOCUMENT) {
        return true;
      }
      const document = documentService.getDocumentsCache().get(entry.documentId!);
      return !!document && document.status === 'ACTIVE';
    });

    if (validDocumentEntries.length !== this.state.selectedDocumentExport.documentEntries.length) {
      appService.info($t('CaseDetail.Export.someDocumentsInvalidInfoMessage'));
      this.state.selectedDocumentExport.documentEntries = [...validDocumentEntries];
      await this.saveDocuments();
      this.state.view = ExportView.DocumentSelection;
      return;
    }

    await this.saveConfig();

    let data: ExportResponse | null = null;
    try {
      const client = await openApiClientService.getClient();
      const response = await client.publishExport({ exportId: this.state.selectedDocumentExport.id, legalCaseId: this.currentCase.id });
      data = response.data;
    } catch (e) {
      handleError($t('CaseDetail.Export.exportPublishErrorMessage'), e);
      return;
    }
    documentService.load(this.currentCase.id);

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...data,
    };
    this.updateLocalExport(this.state.selectedDocumentExport);

    this.state.isLoading = false;
    this.state.view = ExportView.Share;

    $a.l($a.e.EXPORT_PUBLISH);
  }

  async fetchHistory(exportId: UUID) {
    const client = await openApiClientService.getClient();
    return await client
      .getExportHistory({ legalCaseId: this.currentCase.id, exportId })
      .then((response) => response.data as API.Auth.AuditEvent[])
      .catch((e) => {
        handleError($t('CaseDetail.Export.historyLoadErrorMessage'), e);
      });
  }

  async fetchAll() {
    try {
      const client = await openApiClientService.getClient();
      const documentExportResponse = await client.listExports({ legalCaseId: this.currentCase.id });
      const data = documentExportResponse.data as ExportResponse[];
      this.state.documentExportsList = data.map((d) => ({ ...d, prevDocumentIds: [] }));
      this.updateMaps();

      for (const documentExport of this.state.documentExportsList) {
        const prevDocumentIds = [];
        let { previousExportId } = documentExport;
        while (previousExportId) {
          const previousDocumentExport = this.documentExportsMap.get(previousExportId)!;
          const previousDocumentExportDocumentIds = this.filterDocEntries(previousDocumentExport.documentEntries).map((d) => d.documentId);
          prevDocumentIds.unshift(...previousDocumentExportDocumentIds);
          previousExportId = previousDocumentExport.previousExportId;
        }
        documentExport.prevDocumentIds = prevDocumentIds;
      }
    } catch (e) {
      handleError($t('CaseDetail.Export.exportsLoadErrorMessage'), e);
    }
  }

  async overrideDefaultConfig() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    if (!this.state.selectedDocumentExport.configuration.filename) {
      const filenames = this.getFilenamesForSelect();
      if (filenames?.length) {
        this.state.selectedDocumentExport.configuration.filename = filenames[0].value;
      }
    }
    await this.saveConfig();
  }

  async applyConfigTemplate(templateObject: ExportConfiguration) {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    // apply template
    this.state.selectedDocumentExport.configuration = {
      ...this.state.selectedDocumentExport.configuration,
      ...templateObject,
    };

    await this.overrideDefaultConfig();

    // pre-fill export
    if (this.state.selectedDocumentExport.configuration.predefinedLabelSelection.length === 0) return;

    this.addDocumentsByPredefinedLabelSelection();
  }

  /** PDF */

  async downloadPdf(documentExport: SelectedDocumentExport, downloadOriginal = false) {
    appService.info($t('Common.File.preparingDownload'));
    const filename = this.parseFilenameTemplate(documentExport.configuration.filename, documentExport);
    const fileUri = downloadOriginal ? documentExport.originalFileUri : documentExport.fileUri;
    fileService.download(fileUri, filename);
  }

  /** DOCUMENTS */

  async addDocuments(documentIds: UUID[]): Promise<number> {
    if (!this.state.selectedDocumentExport) {
      return 0;
    }

    // NOTE(ndv): the idea is to not impact the loading when it's already managed outside this method, e.g. from methods that call this method inside them
    let resetLoading = false;
    if (!this.isLoading()) {
      this.setLoading(true);
      resetLoading = true;
    }

    const documents = documentIds.map((documentId) => documentService.getDocumentsCache().get(documentId)!);
    documents.sort((a, b) => this.sortByLabel(a.id, b.id));

    let added = 0;
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      if (
        this.state.selectedDocumentExport.documentEntries.find((e) => e.documentId === document.id) ||
        this.state.selectedDocumentExport.prevDocumentIds?.includes(document.id) ||
        document.status !== 'ACTIVE'
      ) {
        continue;
      }

      // auto-add heading (if grouping is active)
      if (this.state.selectedDocumentExport.configuration.groupByLabels) {
        if (i === 0 || added === 0 || documents[i - 1].metadata.DOCTYPE.value !== document.metadata.DOCTYPE.value) {
          const newHeader = this.getDocumentLabelHeading(document);
          // only add the heading if it doesn't exist yet
          if (
            newHeader &&
            !this.state.selectedDocumentExport.documentEntries.find((e) => e.type === EntryType.HEADING && e?.labelId === newHeader.labelId)
          ) {
            this.state.selectedDocumentExport.documentEntries.push(newHeader);
          }
        }
      }

      // add entry
      this.state.selectedDocumentExport.documentEntries.push({
        type: EntryType.DOCUMENT,
        documentId: document.id,
        sourceFileId: document.sourceFileId,
        fileReference: document.sourceFileUploadFilename,
      });

      added++;
    }

    if (added > 0) {
      await this.saveDocuments();
    }

    // see above
    if (resetLoading) {
      this.setLoading(false);
    }

    return added;
  }

  async addDocumentsByPredefinedLabelSelection() {
    const selectedExport = this.state.selectedDocumentExport;
    if (!selectedExport) {
      return;
    }

    const autoIncludeLabels = Array.from(doctypeService.getAllDoctypes()).filter(
      (l) =>
        (selectedExport.configuration.predefinedLabelSelection.includes(l.id) ||
          selectedExport.configuration.predefinedLabelSelection.includes('*')) &&
        !selectedExport.configuration.blacklistedLabels.includes(l.id),
    );

    const labelIds = autoIncludeLabels.map((l) => l.id);
    const allLabelIds: UUID[] = [];
    for (const labelId of labelIds) {
      const sublabels = doctypeService.getDirectChildrenIds(labelId);
      const filteredSublabels = sublabels.filter((l) => !selectedExport.configuration.blacklistedLabels.includes(l));
      allLabelIds.push(labelId, ...filteredSublabels.map((l) => l));
    }

    const matchingDocuments = documentService.getDocuments().filter((d) => d.labels && d.labels.some((id) => allLabelIds.includes(id)));

    const documentIds = matchingDocuments.map((d) => d.id);
    const oldDocumentEntries = [...selectedExport.documentEntries];

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        selectedExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addFilteredDocuments() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    this.setLoading(true);

    const filteredDocuments = documentService.getFilteredDocuments();

    // validate against label balacklist
    const { blacklistedLabels } = this.state.selectedDocumentExport.configuration;
    const filteredByBlacklistedDoctypes = filteredDocuments.filter((d) => !this.areDocLabelsInList(d.labels, blacklistedLabels));

    // get timeline documents, except deleted ones
    const timelineDocumentIds = filteredByBlacklistedDoctypes.filter((d) => d.status === 'ACTIVE').map((d) => d.id);

    // store current documentIds before change to allow undo action
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    const added = await this.addDocuments(timelineDocumentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport!.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === timelineDocumentIds.length) {
        appService.info($t('CaseDetail.Export.addedAllVisibleDocumentsInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.addedDocumentsWithLimitationsInfoMessage'), undoFn);
      }
    } else if (added === 0 && timelineDocumentIds.length > 0) {
      appService.info($t('CaseDetail.Export.documentsWasNotAddedInfoMessage'));
    } else {
      appService.info($t('CaseDetail.Export.noVisibleDocumentsInfoMessage'));
    }

    this.setLoading(false);

    $a.l($a.e.EXPORT_DOCS_ADD_ALL);
  }

  async addAllDocumentsWithDoctype(doctypeId: string) {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    const allDoctypeIds = [doctypeService.normalizedDoctypeId(doctypeId), ...doctypeService.getDeepChildrenIds(doctypeId)].filter(
      (id) => !this.areDocLabelsInList([id], this.state.selectedDocumentExport!.configuration.blacklistedLabels),
    );
    const allDoctypeTypes = allDoctypeIds.map((id) => doctypeService.getDoctypeLabelById(id).doctype);

    // sanity check
    if (!allDoctypeIds.length) return;

    this.setLoading(true);

    let filteredDocuments = documentService.getDocuments().filter((d) => allDoctypeTypes.includes(d.metadata.DOCTYPE.value));

    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }

    const documentIds = filteredDocuments.map((d) => d.id);
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport!.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addAllDocumentsWithLabel(labelId: UUID) {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    // sanity check
    if (!labelId) return;

    this.setLoading(true);

    const label = labelService.getLabelById(labelId);
    if (!label) {
      return;
    }

    let filteredDocuments = documentService.getDocuments().filter((d) => d.labels && d.labels.includes(label.id));

    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }

    const documentIds = filteredDocuments.map((d) => d.id);
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport!.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addAllImportantDocuments() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    this.setLoading(true);

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    let filteredDocuments = documentService.getDocuments().filter((d) => d.metadata.IMPORTANT.value === 'true');
    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }

    // validate against doctype balacklist
    const { blacklistedLabels } = this.state.selectedDocumentExport.configuration;
    const filteredByBlacklistedDoctypes = filteredDocuments.filter((d) => !this.areDocLabelsInList(d.labels, blacklistedLabels));

    const documentIds = filteredByBlacklistedDoctypes.map((d) => d.id);

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport!.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  getDocumentLabelHeading(document: Document): ExportDocumentEntry {
    const doctypeLabel = doctypeService.getDoctypeLabelByType(
      document.metadata.DOCTYPE.value,
      this.state.selectedDocumentExport?.configuration.locale,
    );

    return {
      type: EntryType.HEADING,
      text: doctypeLabel.title,
      labelId: doctypeLabel.id,
    };
  }

  async insertLabelsHeadings() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    this.setLoading(true);

    const documentEntries = this.filterDocEntries(this.state.selectedDocumentExport.documentEntries);

    const resultEntries: ExportDocumentEntry[] = documentEntries.sort((a, b) => this.sortByLabel(a.documentId, b.documentId));

    // NOTE(ndv): we use a map to avoid changing the array in place which causes issues with the loop
    let added = 0;
    for (let i = 0; i < resultEntries.length; i++) {
      const entry = resultEntries[i];
      if (entry.type === EntryType.HEADING) {
        continue;
      }

      const document = documentService.getDocumentsCache().get(entry.documentId!)!;

      if (added === 0) {
        const newHeader = this.getDocumentLabelHeading(document);
        resultEntries.splice(i, 0, newHeader);
        added++;
        continue;
      }

      const prevEntry = resultEntries[i - 1];
      if (prevEntry.type === EntryType.HEADING) {
        continue;
      }
      const prevDocument = documentService.getDocumentsCache().get(prevEntry.documentId!)!;
      if (prevDocument.metadata.DOCTYPE.value === document.metadata.DOCTYPE.value) {
        continue;
      }

      const newHeader = this.getDocumentLabelHeading(document);
      resultEntries.splice(i, 0, newHeader);
      added++;
    }

    this.state.selectedDocumentExport.documentEntries = resultEntries;
    this.setLoading(false);
  }

  convertLabelHeadingsToManual() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    this.state.selectedDocumentExport.documentEntries = this.state.selectedDocumentExport.documentEntries.map((entry) => {
      const result: ExportDocumentEntry = entry.type === 'DOCUMENT' ? entry : { ...entry, labelId: undefined };
      return result;
    });
  }

  async removeLabelsHeadings() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    this.setLoading(true);

    this.state.selectedDocumentExport.documentEntries = this.state.selectedDocumentExport.documentEntries.filter(
      (e) => e.type === EntryType.DOCUMENT || (e.type === EntryType.HEADING && !e?.labelId),
    );

    this.setLoading(false);
  }

  async addHeading(index: number) {
    const documentExport = this.state.selectedDocumentExport;
    if (!documentExport) {
      return;
    }

    if (documentExport.configuration.groupByLabels) {
      appService.confirm(
        $t('CaseDetail.Export.switchToManualHeadingsConfirmTitle'),
        $t('CaseDetail.Export.switchToManualHeadingsConfirmText'),
        $t('Common.continue'),
        async () => {
          this.convertLabelHeadingsToManual();
          documentExport.configuration.groupByLabels = false;
          await this.insertHeading(index);
        },
      );
    } else {
      await this.insertHeading(index);
    }
  }

  async insertHeading(index: number) {
    const documentExport = this.state.selectedDocumentExport;
    if (!documentExport) {
      return;
    }

    const newDocumentEntries = [...documentExport.documentEntries];
    newDocumentEntries.splice(index, 0, {
      type: EntryType.HEADING,
      text: '',
    });
    documentExport.documentEntries = newDocumentEntries;
    await this.saveDocuments();
    $a.l($a.e.EXPORT_DOCS_HEADING);
  }

  async updateDocumentEntry(index: number, newData: Partial<ExportDocumentEntry>) {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    this.state.selectedDocumentExport.documentEntries[index] = {
      ...this.state.selectedDocumentExport.documentEntries[index],
      ...newData,
    };
  }

  async deleteAllVisibleDocuments() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    this.setLoading(true);

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    this.state.selectedDocumentExport.documentEntries = [...this.state.selectedDocumentExport.documentEntries].filter(
      (entry) => entry.documentId && !this.isVisible(entry.documentId),
    );
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.allVisibleDocumentsRemovedInfoMessage'), async () => {
      this.state.selectedDocumentExport!.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    this.setLoading(false);

    $a.l($a.e.EXPORT_DOCS_CLEAR);
  }

  async deleteDocument(documentId: UUID) {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    if (!this.state.selectedDocumentExport.documentEntries.find((e) => e.documentId === documentId)) return;

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    this.state.selectedDocumentExport.documentEntries = [...this.state.selectedDocumentExport.documentEntries].filter(
      (e) => e.documentId !== documentId,
    );
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.documentRemovedInfoMessage'), async () => {
      this.state.selectedDocumentExport!.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    $a.l($a.e.EXPORT_DOCS_DELETE);
  }

  async removeDocumentEntry(index: number) {
    const documentExport = this.state.selectedDocumentExport;
    if (!documentExport || index > documentExport.documentEntries.length || index < 0) {
      return;
    }

    const remove = async () => {
      const oldDocumentEntries = [...documentExport.documentEntries];
      const newDocumentEntries = [...documentExport.documentEntries];

      newDocumentEntries.splice(index, 1);

      documentExport.documentEntries = newDocumentEntries;
      await this.saveDocuments();

      appService.info($t('CaseDetail.Export.headlineRemovedInfoMesssage'), async () => {
        documentExport!.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      });
    };

    const entry = documentExport.documentEntries[index];
    if (entry.type === 'HEADING' && documentExport.configuration.groupByLabels) {
      appService.confirm(
        $t('CaseDetail.Export.switchToManualHeadingsConfirmTitle'),
        $t('CaseDetail.Export.switchToManualHeadingsConfirmTextDeletion'),
        $t('Common.continue'),
        async () => {
          this.convertLabelHeadingsToManual();
          documentExport.configuration.groupByLabels = false;
          await remove();
        },
      );
      return;
    } else {
      await remove();
    }

    $a.l($a.e.EXPORT_DOCS_HEADING);
  }

  refreshList() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    const oldDocumentLength = this.state.selectedDocumentExport.documentEntries.length;
    this.state.selectedDocumentExport.documentEntries = this.state.selectedDocumentExport?.documentEntries?.filter((e) => {
      if (e.type === EntryType.DOCUMENT) {
        return documentService.getDocumentsCache().has(e.documentId!);
      }
      return true;
    });
    if (oldDocumentLength !== this.state.selectedDocumentExport.documentEntries.length) {
      this.saveDocuments();
    }
  }

  isVisible(documentId: UUID) {
    if (!this.state.doctypeFilter) {
      return true;
    }
    const labelId = this.state.doctypeFilter.id;
    const doc = documentService.getDocumentsCache().get(documentId)!;
    const doctypeLabel = labelId ? doctypeService.getDoctypeLabelById(labelId) : null;
    return doctypeLabel === null || doc.metadata.DOCTYPE.value === doctypeLabel?.doctype;
  }

  hasVisibileDocuments() {
    return this.filterDocEntries(this.state.selectedDocumentExport?.documentEntries ?? []).some((e) => this.isVisible(e.documentId));
  }

  hasHeadings() {
    const headingsCount = this.state.selectedDocumentExport?.documentEntries?.filter((e) => e.type === EntryType.HEADING)?.length ?? 0;
    return headingsCount > 0;
  }

  async setSorting(sortingModeKey: ExportSortingKey) {
    if (!this.state.selectedDocumentExport || !SortingModes[sortingModeKey]) {
      return;
    }

    this.state.selectedDocumentExport.configuration.sorting = sortingModeKey;

    $a.l($a.e.EXPORT_DOCS_SORT_MODE);
    await this.saveDocuments();
  }

  async setGroupByLabels(groupByLabels: boolean) {
    const documentExport = this.state.selectedDocumentExport;
    if (!documentExport) {
      return;
    }

    const enable = async () => {
      documentExport.configuration.groupByLabels = true;
      this.insertLabelsHeadings();
      await this.saveDocuments();
      this.sort();
    };
    const disable = async () => {
      documentExport.configuration.groupByLabels = false;
      this.removeLabelsHeadings();
      await this.saveDocuments();
      this.sort();
    };

    if (groupByLabels) {
      const customHeadings = documentExport.documentEntries.filter((entry) => entry.type === 'HEADING');
      if (customHeadings.length) {
        appService.confirm(
          $t('CaseDetail.Export.removeCustomHeadingsConfirmTitle'),
          $t('CaseDetail.Export.removeCustomHeadingsConfirmText'),
          $t('Common.continue'),
          async () => {
            documentExport.documentEntries = documentExport.documentEntries.filter((entry) => entry.type !== 'HEADING');
            await enable();
          },
        );
      } else {
        await enable();
      }
    } else {
      await disable();
    }

    $a.l($a.e.EXPORT_DOCS_GROUP_BY_LABELS);
  }

  sort() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    const { sorting } = this.state.selectedDocumentExport.configuration;
    if (sorting === 'MANUAL') {
      return;
    }

    const documentEntriesToSort = this.editDocEntriesPerGroup(this.state.selectedDocumentExport.documentEntries, (docEntries) => {
      if (sorting.startsWith('ISSUE_DATE') || sorting.startsWith('RECEIPT_DATE')) {
        return this.sortByDate(docEntries, sorting);
      } else if (sorting.startsWith('PAGINATION_NO')) {
        return this.sortByPaginationNo(docEntries, sorting);
      }
      return docEntries;
    });

    this.state.selectedDocumentExport.documentEntries = documentEntriesToSort;
  }

  sortByDate(list: ExportDocumentEntryDOC[], sorting: ExportSortingKey) {
    return list.sort((a, b) => {
      let aDocument = documentService.getDocumentsCache().get(a.documentId)!;
      let bDocument = documentService.getDocumentsCache().get(b.documentId)!;
      let key: MetadataKey;
      if (sorting.endsWith('_DESC')) {
        [aDocument, bDocument] = [bDocument, aDocument];
        key = sorting.replace('_DESC', '') as MetadataKey;
      } else {
        key = sorting.replace('_ASC', '') as MetadataKey;
      }
      return aDocument.metadata[key].value.localeCompare(bDocument.metadata[key].value);
    });
  }

  sortByPaginationNo(list: ExportDocumentEntryDOC[], sorting: ExportSortingKey) {
    return list.sort((a, b) => {
      const aPaginationNo = a.paginationNo;
      const bPaginationNo = b.paginationNo;

      if (sorting.endsWith('_DESC')) {
        return aPaginationNo.localeCompare(bPaginationNo);
      }
      return bPaginationNo.localeCompare(aPaginationNo);
    });
  }

  sortByLabel(aId?: UUID, bId?: UUID) {
    const aDocument = documentService.getDocumentsCache().get(aId!)!;
    const bDocument = documentService.getDocumentsCache().get(bId!)!;
    const aLabel = doctypeService.getDoctypeLabelByType(aDocument.metadata.DOCTYPE.value);
    const bLabel = doctypeService.getDoctypeLabelByType(bDocument.metadata.DOCTYPE.value);

    if (aLabel === bLabel) return 0;
    if (!aLabel && bLabel) return -1;
    if (aLabel && !bLabel) return 1;

    // NOTE(ndv): give priority to sorting defined by predefined labels
    const indexOfLabelA = this.state.selectedDocumentExport?.configuration.predefinedLabelSelection.indexOf(aLabel.id) ?? -1;
    const indexOfLabelB = this.state.selectedDocumentExport?.configuration.predefinedLabelSelection.indexOf(bLabel.id) ?? -1;
    if (indexOfLabelA !== -1 && indexOfLabelB !== -1) return indexOfLabelA - indexOfLabelB;
    if (indexOfLabelA !== -1 && indexOfLabelB === -1) return -1;
    if (indexOfLabelA === -1 && indexOfLabelB !== -1) return 1;

    let aSorting = aLabel.sorting;
    let bSorting = bLabel.sorting;
    // NOTE(mba): the main label, e.g. "Medizinisch allgemein", should always be after the sub-labels
    if (aSorting && !aSorting.includes('.')) {
      aSorting += '.99';
    }
    if (bSorting && !bSorting.includes('.')) {
      bSorting += '.99';
    }

    if (aSorting && bSorting) return aSorting.localeCompare(bSorting);
    if (aSorting && !bSorting) return -1;
    if (!aSorting && bSorting) return 1;

    return aLabel.title.localeCompare(bLabel.title);
  }

  changeDoctypeFilter(value: LabelFilterOption | null) {
    this.state.doctypeFilter = value;
    if (value) {
      documentFilterService.setFilter('doctypes', value.id ? [value.id] : []);
    }
    $a.l($a.e.EXPORT_DOCS_FILTER);
  }

  toggleDoctypeFilter(value: LabelFilterOption) {
    if (this.state.doctypeFilter?.id === value.id) {
      this.changeDoctypeFilter(null);
    } else {
      this.changeDoctypeFilter(value);
    }
  }

  updateLabelFilterOptions() {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    const labelMap = new Map();
    let otherCount = 0;

    for (const entry of this.state.selectedDocumentExport.documentEntries) {
      if (entry.type !== EntryType.DOCUMENT) {
        continue;
      }

      const doc = documentService.getDocumentsCache().get(entry.documentId!);

      if (!doc?.labels || doc?.labels?.length === 0) {
        otherCount++;
      }
      for (const label of doc?.labels ?? []) {
        if (labelMap.has(label)) {
          labelMap.set(label, labelMap.get(label) + 1);
        } else {
          labelMap.set(label, 1);
        }
      }
    }

    const options: LabelFilterOption[] = [];
    for (const [labelId, count] of labelMap.entries()) {
      const label = doctypeService.getDoctypeLabelById(labelId);
      options.push({
        id: labelId,
        labelText: label.title,
        labelColor: label.color,
        labelIcon: label.icon,
        count,
      });
    }

    options.sort((a, b) => a.labelText.toLowerCase().localeCompare(b.labelText.toLowerCase()));

    if (otherCount > 0) {
      options.push({
        id: 'others',
        labelText: $t('CaseDetail.Export.others'),
        labelColor: 'primary',
        labelIcon: 'mdi-label-off',
        count: otherCount,
      });
    }
    this.state.doctypeFilterOptions = options;
  }

  selectDocument(documentId: UUID) {
    this.state.selectedDocumentId = documentId;
    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: documentId, page: 0, scroll: false, forceShow: false, iterate: false });
  }

  async selectDocumentAtIndex(index: number) {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    const entry = this.state.selectedDocumentExport.documentEntries[index];
    if (!entry) {
      return;
    }

    if (entry.type === EntryType.DOCUMENT) {
      this.selectDocument(entry.documentId!);
    }
  }

  updateMaps() {
    // fill reference map
    this.documentExportsMap.clear();

    for (const documentExport of this.state.documentExportsList) {
      this.documentExportsMap.set(documentExport.id, documentExport);
    }

    // build recipient map
    this.state.documentExportHierarchyMap.clear();
    for (const documentExport of this.state.documentExportsList) {
      // parent export
      if (!documentExport.previousExportId) {
        if (!this.state.documentExportHierarchyMap.has(documentExport.id)) {
          this.state.documentExportHierarchyMap.set(documentExport.id, [documentExport]);
          continue;
        }

        this.state.documentExportHierarchyMap.get(documentExport.id)?.push(documentExport);
        continue;
      }

      // export update
      if (this.state.documentExportHierarchyMap.has(documentExport.previousExportId)) {
        this.state.documentExportHierarchyMap.get(documentExport.previousExportId)?.push(documentExport);
      } else {
        this.state.documentExportHierarchyMap.set(documentExport.previousExportId, [documentExport]);
      }
    }
  }

  updateLocalExport(documentExport: SelectedDocumentExport) {
    const index = this.state.documentExportsList.findIndex((de) => de.id === documentExport.id);
    if (index === -1) return;

    this.state.documentExportsList[index] = documentExport;
    this.updateMaps();
  }

  /* Config Panel */
  getFilenamesForSelect(): { value: string; text: string }[] | undefined {
    if (!this.state.selectedDocumentExport) {
      return;
    }

    const filenames = [];
    const templates = this.state.selectedDocumentExport.configuration.filenameTemplates.split('\n');

    for (const template of templates) {
      const parsed = this.parseFilenameTemplate(template, this.state.selectedDocumentExport);
      if (parsed.trim() !== '') {
        filenames.push({ value: template, text: parsed });
      }
    }

    return filenames;
  }

  parseFilenameTemplate(template: string, documentExport: SelectedDocumentExport) {
    const actsStart = documentExport?.previousExportId ? documentExport.prevDocumentIds.length + 1 : 1;
    const actsEnd = documentExport?.previousExportId
      ? documentExport.prevDocumentIds.length + documentExport.documentEntries.length
      : documentExport.documentEntries.length;

    const patterns = {
      '{actsRange}': `${actsStart}-${actsEnd}`,
      '{initials}': `${this.currentCase.displayLabel
        .split(' ')
        .map((s) => s.charAt(0))
        .join('')}`,
      '{date}': dayjs().format('YYYY-MM-DD'),
      '{reference}': this.currentCase.reference,
    };

    for (const [search, replace] of Object.entries(patterns)) {
      template = template.replace(search, replace);
    }
    return template;
  }

  getConfigurationTitleSuffixes() {
    return {
      title: `${this.currentCase.displayLabel}`,
      subtitle: `${formatToLocale(dayjs())}`,
    };
  }

  areDocLabelsInList(labels: UUID[], list: UUID[]) {
    for (const labelId of labels) {
      if (list.includes(labelId)) {
        return true;
      }
    }
    return false;
  }

  getProcessedEmailSubject(exportConfig: ExportConfiguration) {
    const prefix = $t('CaseDetail.Export.Settings.subjectPrefix', {
      user: authService.state.user!.name,
      tenant: authService.state.data!.tenant.tenantConfig.brandingConfig.companyName,
    });

    // NOTE(ndv): we need defaults for the use in the settings where no case is set
    const processedSubject = exportConfig.emailSubject
      .replace('{caseReference}', this.currentCase?.reference || 'A123-B456-C789')
      .replace('{reference}', this.currentCase?.reference || 'A123-B456-C789')
      .replace('{name}', this.currentCase?.displayLabel || 'Marco Bernasconi');

    return `${prefix} ${processedSubject}`;
  }

  /* Navigation */
  async back() {
    this.state.view = ExportView.ExportList;
    this.state.isLoading = false;
    this.state.isFirstPreview = true;
    this.state.allowSave = false;
    this.state.doctypeFilter = null;
    this.state.doctypeFilterOptions = [];
    $a.l($a.e.EXPORT_BACK);
  }

  editDraft(documentExport: SelectedDocumentExport) {
    this.state.selectedDocumentExport = documentExport;
    this.overrideDefaultConfig();
    this.refreshList();
    this.updateLabelFilterOptions();
    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_EDIT);
  }

  dragStart() {
    if (this.state.view === ExportView.DocumentSelection) {
      this.state.showDropOverlay = true;
    }
  }

  dragEnd() {
    this.state.showDropOverlay = false;
  }

  filterDocEntries(entries: ExportDocumentEntry[]): ExportDocumentEntryDOC[] {
    return entries.filter((entry) => entry.type === EntryType.DOCUMENT) as ExportDocumentEntryDOC[];
  }

  editDocEntriesPerGroup(entries: ExportDocumentEntry[], fn: (docEntries: ExportDocumentEntryDOC[]) => ExportDocumentEntry[]): ExportDocumentEntry[] {
    const groups: { heading?: ExportDocumentEntry; docs: ExportDocumentEntry[] }[] = [];
    for (const entry of entries) {
      if (entry.type === 'HEADING') {
        groups.push({ heading: entry, docs: [] });
      } else {
        if (!groups.length) {
          groups.push({ docs: [] });
        }
        groups[groups.length - 1].docs.push(entry);
      }
    }

    for (const group of groups) {
      group.docs = fn(group.docs as ExportDocumentEntryDOC[]);
    }

    return groups.flatMap((group) => [group.heading, ...group.docs].filter(Boolean) as ExportDocumentEntry[]);
  }

  destroy() {
    this.state.documentExportsList = [];
    this.state.documentExportHierarchyMap.clear();
    this.documentExportsMap.clear();
    // @ts-expect-error outside we ensure it's not used after destroy
    this.currentCase = null;

    // view
    this.state.isLoading = false;
    this.state.isFirstPreview = true;
    this.state.error = false;
    this.state.view = ExportView.ExportList;

    this.state.selectedDocumentExport = null;

    // filter
    this.state.doctypeFilter = null;
    this.state.doctypeFilterOptions = [];
  }
}

export default new ExportService();
export const ExportServiceClass = ExportService;
