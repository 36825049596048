<template>
  <v-dialog v-model="dialog" heigth="90%" scrollable width="90%">
    <v-card>
      <v-card-item class="pl-5 py-2 pr-2">
        <v-card-title class="d-flex align-center">
          <span>Audit History</span>
          <v-spacer />
          <v-btn v-blur icon="mdi-close" density="comfortable" variant="text" @click="dialog = false" />
        </v-card-title>
      </v-card-item>
      <v-divider />
      <v-card-text class="py-3">
        <v-data-table
          :custom-filter="filterTable"
          :headers="headers"
          :items="auditLog"
          :loading="loading"
          :search="search"
          density="compact"
          disable-sort
          items-per-page="10"
          :loading-text="$t('CaseList.PipelineHistory.loadingMessage')"
        >
          <template #item.error="{ item }">
            <div v-dompurify-html="item.error" />
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-text-field v-model="search" class="mx-4" :label="$t('Common.search')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';

import { pipelineOrchestratorService } from '@/case-list/services/pipeline.orchestrator.service';
import { formatToLocale } from '@/common/services/date.utils';
import userService from '@/common/services/users/user.service';

export default {
  props: {
    sourceFileId: {
      type: [String, null],
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          title: 'Action',
          value: 'entityTypeAction',
          width: '10%',
        },
        { title: 'Status Start', value: 'localTs', width: '25%' },
        {
          title: 'Duration (Hours:Minutes:Seconds)',
          value: 'diff',
          align: 'end',
          width: '5%',
        },
        {
          title: 'User',
          value: 'user',
          width: '35%',
        },
        {
          title: 'Error',
          value: 'error',
          width: '25%',
        },
      ],
      auditLog: [],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.sourceFileId !== null;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        const response = await pipelineOrchestratorService.getAuditLog(this.sourceFileId);
        const len = response.length;
        for (const [i, row] of response.entries()) {
          row.user = row.user.userName ? row.user.userName : userService.getFullName(row.user.userId);
          const zuluTime = dayjs(row.ts);
          if (row.action !== 'READY' && row.action !== 'ERROR' && i + 1 < len) {
            const nextRow = response[i + 1];
            const nextZuluTime = dayjs(`${nextRow.ts}`);
            const totalDiffSeconds = nextZuluTime.diff(zuluTime, 'second', false);
            if (totalDiffSeconds > 0) {
              const diffHours = nextZuluTime.diff(zuluTime, 'hour');
              const diffMinutes = nextZuluTime.diff(zuluTime, 'minute') - diffHours * 60;
              const diffSeconds = totalDiffSeconds - diffHours * 60 * 60 - diffMinutes * 60;
              row.diff = `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}:${diffSeconds < 10 ? '0' : ''}${diffSeconds}`;
            } else {
              row.diff = '0:00:00';
            }
          }
          row.localTs = formatToLocale(zuluTime, 'L LTS');

          row.error = [row.payload?.error, row.payload?.batchJobUrl, row.payload?.auditLogUrl, row.payload?.awsLogUrl]
            .filter((str) => !!str)
            .map((str) => str.replace(/^"+|"+$/g, ''))
            .join('<br />');
        }
        this.auditLog = response.reverse();
      }
    },
  },
  methods: {
    filterTable(value, search) {
      return value != null && search != null && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
