<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <v-card>
      <v-card-item class="pl-5 py-2 pr-2">
        <v-card-title class="d-flex align-center">
          <span>{{ $t('CaseList.manageLabeledData') }}</span>
          <v-spacer />
          <v-btn v-blur icon="mdi-close" density="comfortable" variant="text" @click="close" />
        </v-card-title>
      </v-card-item>
      <v-divider />
      <div class="my-3 px-5">
        <v-text-field
          v-model="query"
          prepend-inner-icon="mdi-magnify"
          :label="$t('CaseList.LabelData.searchFields')"
          hide-details
          density="compact"
          variant="outlined"
          clearable
        />
      </div>
      <v-divider />
      <v-card-text>
        <div v-for="metaObj in filteredDocumentMetadata" :key="metaObj.key" class="py-2 d-flex align-center">
          <div>
            <v-switch
              v-model="labeledSet"
              v-tippy="{ content: $t('CaseList.LabelData.caseInTestSet', [metaObj.key]), placement: 'top' }"
              hide-details
              :value="metaObj.key"
              color="primary"
              inset
              density="compact"
              class="switch-md mr-3"
            />
          </div>
          <div>
            <div class="text-body-1">
              {{ metaObj.title }}
            </div>
            <div class="text-body-2 text-grey">
              {{ metaObj.key }}
            </div>
          </div>
          <v-spacer />
          <v-btn
            v-tippy="$t('CaseList.LabelData.markAllAsLabeled', [metaObj.key])"
            size="small"
            color="primary"
            variant="outlined"
            :disabled="isLoading"
            class="mr-2"
            @click="confirmAddToTestset(metaObj.type, metaObj.key)"
          >
            {{ $t('CaseList.LabelData.markAsLabeled') }}
          </v-btn>
        </div>
        <div v-if="filteredDocumentMetadata.length === 0" class="text-center text-body-2 pb-1">
          {{ $t('CaseList.LabelData.noCorrespondingFields') }}
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn color="primary" variant="flat" @click="close">
          {{ $t('Common.done') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import appService from '@/app/services/app.service';
import { legalCaseService } from '@/case-list/services/legalcase.service';
import entityService, { CaseMetadataKey, LocalDocMetadataKey } from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';
import dataLabelingService from '@/workspace-admin/settings/services/data.labeling.service';

const otherDataKeys = ['DIAGNOSES', 'DUPLICATES', 'SEGMENTATION', 'WORK_INABILITY'] as const;
type MetadataKey = LocalDocMetadataKey | CaseMetadataKey | (typeof otherDataKeys)[number];
type MetadataType = 'DOCUMENT' | 'LEGALCASE' | 'OTHER';

interface MetadataObject {
  key: MetadataKey;
  sorted: number;
  title: string;
  type: MetadataType;
}

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    inputCase: {
      type: Object as PropType<API.LegalCase.ListResponse>,
      required: true,
    },
  },

  emits: ['close'],

  data() {
    return {
      dataLabelingService,
      isLoading: false,
      query: '',
      caseMetadata: entityService.LEGALCASE_METADATA,
      docMetadata: entityService.DOC_METADATA,
    };
  },

  computed: {
    labeledSet: {
      get() {
        return this.inputCase.labeled;
      },
      set(val: string[]) {
        this.inputCase.labeled = val;
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    filteredDocumentMetadata(): MetadataObject[] {
      const docMeta = Object.values(this.docMetadata)
        .filter(
          (metaObj) => metaObj.key.toLowerCase().includes(this.query.toLowerCase()) || metaObj.title.toLowerCase().includes(this.query.toLowerCase()),
        )
        .map((d) => ({ ...d, type: 'DOCUMENT', sorted: 1 }));

      const caseMeta = Object.values(this.caseMetadata)
        .filter(
          (metaObj) => metaObj.key.toLowerCase().includes(this.query.toLowerCase()) || metaObj.title.toLowerCase().includes(this.query.toLowerCase()),
        )
        .map((d) => ({ ...d, type: 'LEGALCASE', sorted: 2 }));

      const otherData = otherDataKeys
        .filter((key) => key.toLowerCase().includes(this.query.toLowerCase()))
        .map((key) => ({
          key,
          title: key,
          type: 'OTHER',
          sorted: 0,
        }));

      return [...otherData, ...docMeta, ...caseMeta]
        .map((metaObj) => ({
          ...metaObj,
          type: metaObj.type as MetadataType,
        }))
        .sort((a, b) => a.sorted - b.sorted || a.key.localeCompare(b.key));
    },
  },

  watch: {
    async labeledSet(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return;
      }
      const legalCaseRequest = {
        reference: this.inputCase.reference,
        owner: this.inputCase.owner,
        accessGroup: this.inputCase.accessGroup ?? '',
        labeled: this.inputCase.labeled,
      };
      await legalCaseService.editCase(this.inputCase.id, legalCaseRequest);
    },
  },

  methods: {
    close() {
      this.query = '';
      this.dialog = false;
    },
    async addToTestset(entity: MetadataType, key: MetadataKey) {
      this.isLoading = true;
      try {
        await this.dataLabelingService.freezeMetadata(entity, this.inputCase.id, key);
        const legalCaseRequest = {
          reference: this.inputCase.reference,
          owner: this.inputCase.owner,
          accessGroup: this.inputCase.accessGroup ?? '',
          labeled: [...this.inputCase.labeled, key],
        };
        await legalCaseService.editCase(this.inputCase.id, legalCaseRequest);
      } finally {
        this.isLoading = false;
      }
    },
    confirmAddToTestset(entity: MetadataType, key: MetadataKey) {
      const title = `${this.$t('CaseList.LabelData.markAsLabeled')}?`;
      const message = this.$t('CaseList.LabelData.fieldsAreSetToManual', [key, key]);

      appService.confirm(title, message, this.$t('CaseList.LabelData.markAsLabeled'), () => this.addToTestset(entity, key), 'primary');
    },
  },
});
</script>

<style lang="scss" scoped></style>
