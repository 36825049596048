import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import { RouteLocationRaw } from 'vue-router';

import { config } from '@/app/config';
import router from '@/app/router';
import { PanelId } from '@/case-detail/services/detail.view.panels.meta';
import { detailViewPersistenceService } from '@/case-detail/services/detail.view.persistence.service';
import { ViewId } from '@/case-detail/services/detail.view.service';
import { authService } from '@/common/services/auth/auth.service';
import { UUID } from '@/common/types/common.types';

type QueryParamKey =
  | 'openView'
  | 'openPanels'
  // doc
  | 'initialDocId'
  | 'initialPage'
  | 'highlight'
  // case pilot
  | 'casePilotView'
  | 'casePilotItemId'
  // collab
  | 'ticketId'
  | 'messageId'
  // notebook
  | 'notebookId';

type QueryParamKeyValueMap = {
  openView: ViewId;
  openPanels: PanelId[];
  initialDocId: string;
  initialPage: number;
  highlight: string;
  casePilotView: string;
  casePilotItemId: string;
  ticketId: string;
  messageId: string;
  notebookId: string;
};

// for our usage
type QueryParamValue<T extends QueryParamKey> = QueryParamKeyValueMap[T];
export type QueryParamsObject = Partial<Record<QueryParamKey, QueryParamValue<QueryParamKey>>>;

// for vue-router
type SerializedQueryParamValue = string;
type SerializedQueryParamsObject = Partial<Record<QueryParamKey, SerializedQueryParamValue>>;

class DetailViewRouteService {
  pendingQueryUpdate: SerializedQueryParamsObject | null = null;

  debUpdateRoute: DebouncedFunc<(saveHistory: boolean) => void>;

  constructor() {
    this.debUpdateRoute = debounce(this.updateRoute, 50);
  }

  async updateRoute(saveHistory = false) {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.name !== 'case' || !this.pendingQueryUpdate) {
      return;
    }

    const newRoute = { name: currentRoute.name, params: currentRoute.params, query: cloneDeep(this.pendingQueryUpdate) };
    if (saveHistory) {
      await router.push(newRoute);
    } else {
      await router.replace(newRoute);
    }
    detailViewPersistenceService.update(this.parseQueryObject(this.pendingQueryUpdate), currentRoute.params.caseId as string);
    this.pendingQueryUpdate = null;
  }

  pushRouteUpdates(query: SerializedQueryParamsObject, saveHistory = false) {
    this.pendingQueryUpdate = query;
    this.debUpdateRoute(saveHistory);
  }

  getParsedCurrentQueryObject(): QueryParamsObject {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.name !== 'case') {
      return {};
    }
    return this.parseQueryObject(cloneDeep(currentRoute.query));
  }

  parseQueryObject(query: SerializedQueryParamsObject) {
    return Object.fromEntries(
      Object.entries(query).map(([k, v]) => {
        let value: any = v;
        if (k === 'openPanels' && v) {
          value = (v as string).split(',') as PanelId[];
        } else if (k === 'initialPage' && v) {
          value = parseInt(v, 10);
        }
        return [k, value];
      }),
    ) as QueryParamsObject;
  }

  serializeQueryObject(query: QueryParamsObject): SerializedQueryParamsObject {
    return Object.fromEntries(Object.entries(query).map(([k, v]) => [k, Array.isArray(v) ? v.join(',') : v]));
  }

  // interface

  getFromQuery<K extends QueryParamKey>(key: K): QueryParamKeyValueMap[K] | null {
    const currentQuery = this.getParsedCurrentQueryObject();
    if (key in currentQuery && !!currentQuery[key]) {
      // @ts-expect-error weird error
      return currentQuery[key];
    }
    return null;
  }

  persistInQuery(queryObject: QueryParamsObject) {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.name !== 'case') {
      return;
    }

    const oldQuery = cloneDeep(this.pendingQueryUpdate ?? currentRoute.query);

    // for document change we save history to allow user navigate via Back/Next browser buttons
    const saveHistory = !!oldQuery.initialDocId && !!queryObject.initialDocId && oldQuery.initialDocId !== queryObject.initialDocId;

    this.pushRouteUpdates(
      {
        ...oldQuery,
        ...this.serializeQueryObject(queryObject),
      },
      saveHistory,
    );
  }

  cleanFromQuery(...params: QueryParamKey[]) {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.name !== 'case') {
      return;
    }
    const oldQuery = cloneDeep(this.pendingQueryUpdate ?? currentRoute.query);
    for (const param of params) {
      delete oldQuery[param];
    }

    this.pushRouteUpdates(oldQuery);
  }

  persistOpenedPanel(panel: PanelId) {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.name !== 'case') {
      return;
    }
    const openedPanels = (this.getParsedCurrentQueryObject().openPanels ?? []) as PanelId[];
    if (!openedPanels.includes(panel)) {
      openedPanels.push(panel);
    }
    this.persistInQuery({ openPanels: openedPanels });
  }

  persistClosedPanel(panel: PanelId) {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.name !== 'case') {
      return;
    }
    const openedPanels = (this.getParsedCurrentQueryObject().openPanels ?? []) as PanelId[];
    this.persistInQuery({ openPanels: openedPanels.filter((p) => p !== panel) });
  }

  buildCaseRoute(caseId: UUID, query?: QueryParamsObject): RouteLocationRaw | null {
    const tenant = authService.state.data?.tenant;
    if (!tenant) {
      return null;
    }

    return {
      name: 'case',
      params: { tenant: tenant.canonicalName, caseId },
      query: query ? this.serializeQueryObject(query) : undefined,
    };
  }

  buildDocumentLink(docId: UUID, page: number, highlight?: string | null) {
    const currentRoute = router.currentRoute.value;

    const queryParams: Partial<QueryParamKeyValueMap> = {
      initialDocId: docId,
      initialPage: page,
      openView: 'DOCS',
      openPanels: ['DocumentList', 'WebViewer'],
    };
    if (highlight) {
      queryParams.highlight = highlight;
    }

    const route = this.buildCaseRoute(currentRoute.params.caseId as string, queryParams);
    if (!route) {
      return '';
    }

    return config.APP_URL + router.resolve(route).href;
  }
}

export const detailViewRouteService = new DetailViewRouteService();
