import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { reactive } from 'vue';

import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import { websocketService } from '@/app/services/websocket.service';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { casePilotService } from '@/case-detail/subviews/copilot/services/copilot.service';
import {
  AnalyticsGeneratorConfig,
  CopilotCatalogQuestionHistoryResponse,
  CopilotDashboardHeading,
  CopilotDashboardItem,
  CopilotDashboardTrafficLight,
  CopilotQuestion,
  CopilotRequest,
  CopilotResponse,
  CopyDashboardRequest,
  TrafficLightResponse,
} from '@/common/generated-types/openapi';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import entityService, {
  COPILOT_LOCALIZED_PROMPTS,
  COPILOT_PROMPTS,
  CopilotCatalogQuestionLocalized,
  CopilotCatalogQuestionRaw,
} from '@/common/services/entity.service';
import { highlightMatchedText } from '@/common/services/text.utils';
import { API } from '@/common/types/api.types';
import { Automation, CopilotDashboard, CopilotDashboardItemLocalized, CopilotDashboardLocalized } from '@/common/types/api-types/copilot.api.types';
import { EmptyObject, MultilingualText, UUID } from '@/common/types/common.types';

interface DashboardData {
  key: string;
  order: number;
  enabled: boolean;
  automation: Automation;
  icon: string;
  title: {
    de: string;
    en: string;
    it: string;
    fr: string;
  };
}

interface DialogContent {
  question: {
    title: string;
  };
  message: any;
  language: string;
}

export interface ViewData {
  key: string;
  order: number;
  enabled: boolean;
  automation: Automation;
  icon: string;
  title: string;
  items: CopilotDashboardItemLocalized[];
}

interface DashboardsServiceState {
  query: string;
  dialog: boolean;
  dialogContent: DialogContent | null;
  showToc: boolean;

  highlightedItem: string | null;
  highlightedItemTimeout: ReturnType<typeof setTimeout> | undefined;

  editDashboard: boolean;
  questionEditingDialog: boolean;
  questionToEditKey: string | null;

  dashboardEditingElementIndex: number;

  addSubheaderDialog: boolean;
  addTrafficLightDialog: boolean;

  // Add dashboard dialog
  addDashboardDialog: boolean;
  dashboardCreationTab: string;
  selectedTemplateKey: string;
  dashboardCopyId: string;
  availableTemplates: string[];
  automationOptions: { title: string; value: string }[];
  newDashboardData: DashboardData;
  showNewDashboardDetailsDialog: boolean;

  isInitialized: boolean;
  dashboards: CopilotDashboard[];
  loadingDashboards: Record<string, boolean>;
  isLoading: boolean;
  questionLoadingMap: Map<string, boolean>;
  questionHistories: Record<string, CopilotCatalogQuestionHistoryResponse>;
}

const MIN_QUERY_LENGTH = 3;

class DashboardsService {
  DOCUMENT_CATALOG_QUESTIONS: COPILOT_PROMPTS | EmptyObject = {};
  LEGALCASE_CATALOG_QUESTIONS: COPILOT_PROMPTS | EmptyObject = {};

  state: DashboardsServiceState;
  subscribedTopics: string[] = [];
  trafficLightResponses: Map<string, TrafficLightResponse> = new Map();
  analyticsMode = false;

  constructor() {
    this.state = reactive({
      query: '',
      dialog: false,
      dialogContent: null,
      showToc: true,

      highlightedItem: null,
      highlightedItemTimeout: undefined,

      editDashboard: false,
      questionEditingDialog: false,
      questionToEditKey: null,

      dashboardEditingElementIndex: -1,

      addSubheaderDialog: false,
      addTrafficLightDialog: false,

      // Add dashboard dialog
      addDashboardDialog: false,
      dashboardCreationTab: 'template',
      selectedTemplateKey: '',
      dashboardCopyId: '',
      availableTemplates: [],
      automationOptions: [
        { title: 'Disabled', value: 'DISABLED' },
        { title: 'Initial', value: 'INITIAL' },
        { title: 'Every New Source File', value: 'EVERY' },
      ],
      newDashboardData: {
        key: '',
        order: 0,
        enabled: true,
        automation: 'DISABLED' as Automation,
        icon: '',
        title: {
          de: '',
          en: '',
          it: '',
          fr: '',
        },
      },
      showNewDashboardDetailsDialog: false,

      isInitialized: false,
      dashboards: [] as CopilotDashboard[],
      loadingDashboards: {},
      isLoading: false,
      questionLoadingMap: new Map(),
      questionHistories: {},
    });
  }

  async init(analyticsMode: boolean) {
    this.analyticsMode = analyticsMode;

    const catalogPromise =
      authService.hasFeature('ENABLE_CASEPILOT') || authService.hasFeature('ENABLE_DOCPILOT')
        ? axios.get(config.API.COPILOT.CATALOG.LIST).then((response) => {
            this.DOCUMENT_CATALOG_QUESTIONS = reactive(response.data.DOCUMENT) as COPILOT_PROMPTS;
            this.LEGALCASE_CATALOG_QUESTIONS = reactive(response.data.LEGALCASE) as COPILOT_PROMPTS;
          })
        : Promise.resolve();
    const dashboardsPromise = authService.hasFeature('ENABLE_DASHBOARDS') ? this.fetchDashboards() : Promise.resolve();

    await Promise.all([catalogPromise, dashboardsPromise]).then(() => {
      this.state.isInitialized = true;
    });
  }

  async fetchDashboards() {
    let response;
    if (this.analyticsMode) {
      response = await axios.get<CopilotDashboard[]>(config.API.COPILOT.ANALYTICS.LIST);
    } else {
      response = await axios.get<CopilotDashboard[]>(config.API.COPILOT.DASHBOARDS.LIST);
    }
    this.state.dashboards = response.data;
  }

  isInitialized(): boolean {
    return this.state.isInitialized;
  }

  isDashboardLoading(dashboardKey: string): boolean {
    return this.state.loadingDashboards[dashboardKey];
  }

  setQuestionLoading(questionKey: string, loading: boolean): void {
    this.state.questionLoadingMap.set(questionKey, loading);
  }

  isQuestionLoading(questionKey: string): boolean {
    return this.state.questionLoadingMap.get(questionKey) || false;
  }

  // CATALOGS
  getCatalog(scope: API.Copilot.Scope): COPILOT_PROMPTS | EmptyObject {
    return (scope === 'LEGALCASE' ? this.LEGALCASE_CATALOG_QUESTIONS : this.DOCUMENT_CATALOG_QUESTIONS) as COPILOT_PROMPTS;
  }

  getCatalogLocalized(scope: API.Copilot.Scope): COPILOT_LOCALIZED_PROMPTS | EmptyObject {
    return entityService.localizeAndAddKey(this.getCatalog(scope), ['title', 'description']) as COPILOT_LOCALIZED_PROMPTS;
  }

  getCatalogQuestion(scope: API.Copilot.Scope, questionKey: string): CopilotCatalogQuestionRaw | null {
    return this.getCatalog(scope)[questionKey] || null;
  }

  getCatalogQuestionLocalized(scope: API.Copilot.Scope, questionKey: string): CopilotCatalogQuestionLocalized | null {
    const rawCatalogQuestion = this.getCatalogQuestion(scope, questionKey) as CopilotCatalogQuestionRaw;
    if (!rawCatalogQuestion) return null;

    const localized = entityService.localizeAndAddKey({ [questionKey]: rawCatalogQuestion }, ['title', 'description']);
    return localized[questionKey] as CopilotCatalogQuestionLocalized;
  }

  // DASHBOARD
  getDashboards(): CopilotDashboard[] {
    return this.state.dashboards;
  }

  getDashboard(dashboardKey: string): CopilotDashboard | null {
    return this.state.dashboards.find((d) => d.key === dashboardKey) || null;
  }

  getDashboardsLocalized(): CopilotDashboardLocalized[] {
    return this.getDashboards().map((dashboard) => ({
      ...dashboard,
      title: $t(dashboard.title),
      items: dashboard.items.map((item) => {
        if (Object.hasOwn(item, 'key')) {
          return {
            ...item,
            title: $t((item as CopilotCatalogQuestionRaw).title),
            description: $t((item as CopilotCatalogQuestionRaw).description),
          } as CopilotCatalogQuestionLocalized;
        } else if (Object.hasOwn(item, 'extractSchema')) {
          return item as AnalyticsGeneratorConfig;
        } else if (Object.hasOwn(item, 'header')) {
          return item as CopilotDashboardTrafficLight;
        }

        return item as CopilotDashboardHeading;
      }),
    }));
  }

  getDashboardQuestion(dashboardKey: string, questionKey: string): CopilotCatalogQuestionRaw | null {
    const dashboardQuestions = this.getDashboardQuestions(dashboardKey);
    return dashboardQuestions.find((question) => question.key === questionKey) || null;
  }

  getDashboardQuestions(dashboardKey: string): CopilotCatalogQuestionRaw[] {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) {
      return [];
    }

    return dashboard.items.filter((item) => Object.hasOwn(item, 'key')) as CopilotCatalogQuestionRaw[];
  }

  getDashboardElement(dashboardKey: string, index: number): CopilotDashboardItem | null {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return null;

    return dashboard.items[index] || null;
  }

  async createDashboardQuestion(dashboardKey: string, question: CopilotCatalogQuestionRaw, index: number = -1) {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();

    if (index === -1) {
      dashboard.items.push(question);
    } else {
      const insertIndex = this.#getInsertIndex(dashboard.items, index);
      dashboard.items.splice(insertIndex, 0, question);
    }

    return this.updateDashboard(dashboard);
  }

  async updateDashboardQuestion(dashboardKey: string, updatedQuestion: CopilotCatalogQuestionRaw, formerKey: string | null = null) {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();

    const key = formerKey || updatedQuestion.key;
    const question = dashboard.items.find((item) => (item as CopilotCatalogQuestionRaw).key === key);
    if (!question) return Promise.resolve();

    Object.assign(question, updatedQuestion);

    return this.updateDashboard(dashboard);
  }

  async updateDashboardPartialQuestion(dashboardKey: string, questionKey: string, updatedFields: Partial<CopilotCatalogQuestionRaw>) {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();

    const question = dashboard.items.find((item) => (item as CopilotCatalogQuestionRaw).key === questionKey) as CopilotCatalogQuestionRaw;
    if (!question) return Promise.resolve();

    Object.assign(question, updatedFields);

    return this.updateDashboard(dashboard);
  }

  async toggleQuestionEnabled(dashboardKey: string, questionKey: string) {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();

    const question = dashboard.items.find((item) => (item as CopilotCatalogQuestionRaw).key === questionKey) as CopilotCatalogQuestionRaw;
    if (!question) return Promise.resolve();

    question.enabled = !question.enabled;

    return this.updateDashboard(dashboard);
  }

  async deleteDashboardQuestion(dashboardKey: string, questionKey: string) {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) {
      return Promise.resolve();
    }

    // Remove the question itself
    dashboard.items = dashboard.items.filter((item) => !Object.hasOwn(item, 'key') || (item as CopilotCatalogQuestionRaw).key !== questionKey);

    // Check for and remove references to the deleted question in dependencyQuestionKeys of other items
    dashboard.items.forEach((item) => {
      if (Object.hasOwn(item, 'dependencyQuestionKeys')) {
        const itemWithKeys = item as { dependencyQuestionKeys: string[] };
        if (Array.isArray(itemWithKeys.dependencyQuestionKeys)) {
          itemWithKeys.dependencyQuestionKeys = itemWithKeys.dependencyQuestionKeys.filter((key) => key !== questionKey);
        }
      }
    });

    return this.updateDashboard(dashboard);
  }

  async moveDashboardItem(dashboardKey: string, fromIndex: number, direction: number) {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    const item = dashboard.items[fromIndex];
    if (!item) return Promise.resolve();

    const toIndex = fromIndex + direction;
    if (toIndex < 0 || toIndex >= dashboard.items.length) return Promise.resolve();

    [dashboard.items[fromIndex], dashboard.items[toIndex]] = [dashboard.items[toIndex], dashboard.items[fromIndex]];

    return this.updateDashboard(dashboard);
  }

  async createDashboardHeading(dashboardKey: string, subheaderTitle: MultilingualText, index: number = -1) {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    const newSubheader = { headingText: subheaderTitle } as CopilotDashboardHeading;
    if (index === -1) {
      dashboard.items.push(newSubheader);
    } else {
      dashboard.items.splice(index, 0, newSubheader);
    }

    return this.updateDashboard(dashboard);
  }

  async updateDashboardHeading(dashboardKey: string, index: number, updatedHeadingText: MultilingualText) {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    const heading = dashboard.items[index] as CopilotDashboardHeading;
    if (!heading || !Object.hasOwn(heading, 'headingText')) {
      return Promise.resolve();
    }
    heading.headingText = updatedHeadingText;

    return this.updateDashboard(dashboard);
  }

  async createDashboardTrafficLight(dashboardKey: string, trafficLightHeading: CopilotDashboardTrafficLight, index: number = -1) {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    if (index === -1) {
      dashboard.items.push(trafficLightHeading);
    } else {
      dashboard.items.splice(index, 0, trafficLightHeading);
    }

    return this.updateDashboard(dashboard);
  }

  async updateDashboardTrafficLight(dashboardKey: string, index: number, updatedTrafficLight: CopilotDashboardTrafficLight) {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) {
      return Promise.resolve();
    }

    const trafficLight = dashboard.items[index] as CopilotDashboardTrafficLight;
    if (!trafficLight || !Object.hasOwn(trafficLight, 'header')) {
      return Promise.resolve();
    }
    Object.assign(trafficLight, updatedTrafficLight);

    return this.updateDashboard(dashboard);
  }

  async deleteDashboardHeading(dashboardKey: string, index: number) {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) {
      return Promise.resolve();
    }
    dashboard.items = dashboard.items.filter((item, i) => Object.hasOwn(item, 'key') || i !== index);

    return this.updateDashboard(dashboard);
  }

  async createDashboard(
    templateKey: string,
    data: {
      key: string;
      order: number;
      icon: string;
      enabled: boolean;
      title: {
        de: string;
        en: string;
        fr: string;
        it: string;
      };
    },
  ) {
    return axios.post(config.API.COPILOT.DASHBOARDS.CREATE, {
      templateKey,
      data,
    });
  }

  // API
  async fetchDashboardTemplateKeys(): Promise<string[]> {
    return axios.get<string[]>(config.API.COPILOT.DASHBOARDS.LIST_TEMPLATE_KEYS).then((response) => response.data);
  }

  async updateDashboard(dashboard: CopilotDashboard) {
    if (this.analyticsMode) {
      return axios.patch(config.API.COPILOT.ANALYTICS.UPDATE.replace('{dashboardKey}', dashboard.key), dashboard);
    }
    return axios.patch(config.API.COPILOT.DASHBOARDS.UPDATE.replace('{dashboardKey}', dashboard.key), dashboard);
  }

  async answerAllDashboardQuestions(dashboardKey: string, callback: (questionKey: string) => void) {
    this.state.loadingDashboards[dashboardKey] = true;
    await axios.post(config.API.COPILOT.DASHBOARDS.ANSWERALL.replace('{dashboardKey}', dashboardKey).replace('{legalCaseId}', this.currentCaseId()));

    const topic = `/topic/${this.currentTenantId()}/${this.currentCaseId()}/dashboard/${dashboardKey}`;
    await websocketService.subscribeToTopic(topic, (message) => {
      const parsedMessage = JSON.parse(message.body);
      const questionKey = parsedMessage.question;
      if (questionKey === 'DONE') {
        this.state.loadingDashboards[dashboardKey] = false;
        websocketService.unsubscribeFromTopic(topic);
        this.subscribedTopics = this.subscribedTopics.filter((t) => t !== topic);
      } else {
        callback(questionKey);
      }
    });
    this.subscribedTopics.push(topic);
  }

  async destroy() {
    this.subscribedTopics.forEach((topic) => {
      websocketService.unsubscribeFromTopic(topic);
    });
    this.subscribedTopics = [];
  }

  currentTenantId() {
    return authService.state.data?.tenant.id as UUID;
  }

  currentCaseId() {
    return detailViewService.getCurrentLegalCaseId() as UUID;
  }

  async deleteDashboard(dashboardKey: string) {
    return axios.delete(config.API.COPILOT.DASHBOARDS.DELETE.replace('{dashboardKey}', dashboardKey));
  }

  async pasteDashboard(dashboardId: string) {
    const request: CopyDashboardRequest = {
      dashboardId,
    };
    return axios.post(config.API.COPILOT.DASHBOARDS.COPY, request);
  }

  async translateText(text: string): Promise<Record<string, string>> {
    return axios.post<Record<string, string>>(config.API.COPILOT.DASHBOARDS.TRANSLATE, { text }).then((response) => {
      const data = response.data as Record<string, any>;
      return data.text as Record<string, string>;
    });
  }

  async generatePrompt(title: string, description: string, prompt: string): Promise<string> {
    return axios
      .post(config.API.COPILOT.DASHBOARDS.GENERATE_PROMPT, {
        title,
        description,
        prompt,
      })
      .then((response) => {
        const data = response.data as Record<string, any>;
        return data.prompt as string;
      });
  }

  getDashboardKey(dashboards: CopilotDashboardLocalized[], isLegaliAdmin: boolean): string {
    // Show enabled and disabled dashboards for legali admin, otherwise only show enabled ones
    const views = Object.values(this.getViews(dashboards, isLegaliAdmin)).filter((v) => isLegaliAdmin || v.enabled);

    if (views.length > 0) {
      const viewFromQuery = detailViewRouteService.getFromQuery('casePilotView');
      if (viewFromQuery && views.map((v) => v.key).includes(viewFromQuery)) {
        return viewFromQuery;
      }
    }
    return views.length > 0 ? views[0].key : '';
  }

  setDashboardKey(value: string) {
    detailViewRouteService.persistInQuery({ casePilotView: value });
    detailViewRouteService.cleanFromQuery('casePilotItemId');
  }

  #getInsertIndex(items: CopilotDashboardItem[], startIndex: number): number {
    for (let i = startIndex + 1; i < items.length; i++) {
      if (!Object.hasOwn(items[i], 'key')) {
        return i;
      }
    }

    return items.length - 1;
  }

  getViews(allDashboards: CopilotDashboardLocalized[], isLegaliAdmin: boolean): Record<string, ViewData> {
    if (isLegaliAdmin) {
      const acc = {} as Record<string, ViewData>;
      for (const dashboard of allDashboards) {
        acc[dashboard.key] = {
          key: dashboard.key,
          order: dashboard.order,
          enabled: dashboard.enabled,
          icon: dashboard.icon,
          items: dashboard.items,
          automation: dashboard.automation,
          title: dashboard.title,
        };
      }
      return acc;
    }

    return allDashboards.reduce(
      (acc, dashboard) => {
        let items = [...dashboard.items];
        items = items.filter((item) => {
          if (!Object.hasOwn(item, 'key')) {
            return true;
          }
          return (item as CopilotCatalogQuestionLocalized).enabled;
        });

        acc[dashboard.key] = {
          key: dashboard.key,
          order: dashboard.order,
          enabled: dashboard.enabled,
          icon: dashboard.icon,
          automation: dashboard.automation,
          items,
          title: dashboard.title,
        };
        return acc;
      },
      {} as Record<string, ViewData>,
    );
  }

  getViewsArray(views: Record<string, ViewData>, allDashboards: CopilotDashboardLocalized[]): ViewData[] {
    return Object.values(views).sort((a, b) => {
      const dashboardA = allDashboards.find((d) => d.key === a.key);
      const dashboardB = allDashboards.find((d) => d.key === b.key);
      if (dashboardA && dashboardB) {
        if (dashboardA.order !== dashboardB.order) {
          return dashboardA.order - dashboardB.order;
        }
      }
      return a.title.localeCompare(b.title);
    });
  }

  getDashboardItems(views: Record<string, ViewData>, view: string): CopilotDashboardItemLocalized[] {
    return views[view]?.items || [];
  }

  questionKeys(views: Record<string, ViewData>, view: string): { key: string; translation: string }[] {
    const questionKeys: { key: string; translation: string }[] = [];
    for (const item of this.getDashboardItems(views, view)) {
      if (Object.hasOwn(item, 'key')) {
        if (Object.hasOwn(item, 'title') && (item as CopilotCatalogQuestionLocalized).title) {
          questionKeys.push({
            key: (item as CopilotCatalogQuestionLocalized).key,
            translation: (item as CopilotCatalogQuestionLocalized).title,
          });
        }
      }
    }
    return questionKeys;
  }

  getSearchHits(
    views: Record<string, ViewData>,
    query: string,
  ): Partial<Record<string, Record<string, { titleHit: boolean; descriptionHit: boolean }>>> {
    if (!query || query.length < MIN_QUERY_LENGTH) return {};

    const searchHits: Partial<
      Record<
        string,
        Record<
          string,
          {
            titleHit: boolean;
            descriptionHit: boolean;
          }
        >
      >
    > = {};
    Object.keys(views).forEach((key) => {
      const itemsWithMatches: Record<string, { titleHit: boolean; descriptionHit: boolean }> = {};
      views[key].items.forEach((item) => {
        // NOTE(ndv): only handle questions
        if (!Object.hasOwn(item, 'key')) return;

        const catalogItem = item as CopilotCatalogQuestionLocalized;
        const titleHit = catalogItem.title.toLowerCase().includes(query.toLowerCase());
        const descriptionHit = catalogItem.description.toLowerCase().includes(query.toLowerCase());

        itemsWithMatches[catalogItem.key] = {
          titleHit,
          descriptionHit,
        };
      });

      searchHits[key] = itemsWithMatches;
    });

    return searchHits;
  }

  getHits(
    searchHitsEntry:
      | Record<
          string,
          {
            titleHit: boolean;
            descriptionHit: boolean;
          }
        >
      | undefined
      | null,
  ): string[] {
    if (!searchHitsEntry) {
      return [];
    }
    return Object.keys(searchHitsEntry).filter((key) => searchHitsEntry[key].titleHit || searchHitsEntry[key].descriptionHit);
  }

  showDialog(event: DialogContent) {
    this.state.dialog = true;
    this.state.dialogContent = event;
  }

  closeDialog() {
    this.state.dialog = false;
    this.state.dialogContent = null;
  }

  onDashboardBtnClick(dashboardId: string, allDashboards: CopilotDashboardLocalized[]) {
    const dashboard = allDashboards.find((d) => d.key === dashboardId);
    if (!dashboard || (!dashboard.enabled && !dashboard.enabled)) {
      return;
    }
    this.setDashboardKey(dashboardId);
  }

  highlightMatchText(text: string, query: string): string {
    if (!query || query.length < MIN_QUERY_LENGTH) {
      return text;
    }
    return highlightMatchedText(text, query);
  }

  getItemId(item: CopilotDashboardItemLocalized, index: number): string {
    let type = '';
    if (Object.hasOwn(item, 'key')) {
      type = 'QUESTION';
    }
    if (Object.hasOwn(item, 'headingText')) {
      type = 'SUBHEADER';
    }
    if (Object.hasOwn(item, 'header')) {
      type = 'TRAFFICLIGHT';
    }
    return `${type}-${index}`;
  }

  async scrollToItem(
    id?: string,
    params?: {
      smooth?: boolean;
      highlight?: boolean;
      persistInQuery?: boolean;
      delay?: number;
    },
  ) {
    const defaultParams = { smooth: true, highlight: true, persistInQuery: true, delay: 0 };
    const { smooth, highlight, persistInQuery, delay } = Object.assign(defaultParams, params);

    const catalogWrapper = document.querySelector('.catalog-wrapper') as HTMLElement;
    const element = id ? document.getElementById(id) : null;
    if (!id || !element || !catalogWrapper) {
      return;
    }

    if (delay > 0) {
      await this.sleep(delay);
    }

    element.scrollIntoView({
      behavior: smooth ? 'smooth' : 'instant',
      block: 'start',
    });

    if (!smooth) {
      catalogWrapper.scrollTo({ top: catalogWrapper.scrollTop - 20 });
    }

    clearTimeout(this.state.highlightedItemTimeout);
    if (highlight) {
      this.state.highlightedItem = id;
      this.state.highlightedItemTimeout = setTimeout(() => {
        this.state.highlightedItem = null;
      }, 2000);
    }

    if (persistInQuery) {
      detailViewRouteService.persistInQuery({ casePilotItemId: id });
    }
  }

  scrollCatalogToTop() {
    const el = document.querySelector('.catalog-wrapper') as HTMLElement | undefined;
    if (el) {
      el.scrollTop = 0;
    }
  }

  chat(copilotResponse: any, itemId: string) {
    casePilotService.loadConversation(copilotResponse);
    broadcastEventBus.emit('COPILOT_LOAD_CONVERSATION_EVENT', { copilotResponse });
    const copilotPanelWasClosed = !detailViewService.isPanelOpened('Copilot');
    detailViewService.openPanel('Copilot');
    detailViewService.setCopilotScope('LEGALCASE');
    setTimeout(() => {
      const chatInput = document.getElementById('chatInput');
      if (chatInput) {
        chatInput.focus();
      }
    }, 500);

    setTimeout(() => {
      if (copilotPanelWasClosed && detailViewService.isPanelOpened('Copilot')) {
        this.scrollToItem(itemId, {
          delay: 150,
          highlight: false,
          smooth: false,
          persistInQuery: false,
        });
      }
    });
  }

  async openDashboardQuestionEditor(questionKey: string | null = null) {
    this.state.questionToEditKey = questionKey;
    this.state.questionEditingDialog = true;
  }

  openDashboardHeadingEditor(index: number) {
    this.state.addSubheaderDialog = true;
    this.state.dashboardEditingElementIndex = index;
  }

  openDashboardTrafficLightEditor(index: number) {
    this.state.addTrafficLightDialog = true;
    this.state.dashboardEditingElementIndex = index;
  }

  async refreshTrafficLight(dashboardKey: string, trafficLight: CopilotDashboardTrafficLight) {
    await axios.post(config.API.COPILOT.DASHBOARDS.TRAFFICLIGHTS_REFRESH.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()), {
      dashboardKey,
      trafficLightId: trafficLight.id!,
    });
    appService.info('Traffic Light refreshed');
  }

  async fetchTrafficLightResponse(
    trafficLight: CopilotDashboardTrafficLight,
    ignoreCache: boolean = false,
  ): Promise<TrafficLightResponse | undefined> {
    if (!ignoreCache) {
      const trafficLightResponse = this.trafficLightResponses.get(trafficLight.id!);
      if (trafficLightResponse) {
        return trafficLightResponse;
      }
    }

    const trafficLightsResponses = await axios.get<TrafficLightResponse[]>(
      config.API.COPILOT.DASHBOARDS.TRAFFICLIGHTS.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()),
    );
    if (trafficLightsResponses.data) {
      trafficLightsResponses.data.forEach((response) => {
        this.trafficLightResponses.set(response.trafficLightId, response);
      });
    }
    return this.trafficLightResponses.get(trafficLight.id!);
  }

  async deleteQuestion(dashboardKey: string, questionKey: string) {
    appService.confirm(
      'Delete question?',
      'The question will be permanently deleted and removed from the dashboard.',
      'Delete question',
      async () => {
        await this.deleteDashboardQuestion(dashboardKey, questionKey);
        appService.info('Question deleted.');
      },
      'primary',
    );
  }

  async moveItem(dashboardKey: string, index: number, direction: number) {
    await this.moveDashboardItem(dashboardKey, index, direction);
    appService.info('Dashboard updated');
  }

  async removeSubheader(dashboardKey: string, index: number) {
    appService.confirm(
      'Remove subheader from dashboard?',
      'The subheader and all questions below it will be removed from the dashboard.',
      'Remove subheader',
      async () => {
        await this.deleteDashboardHeading(dashboardKey, index);
        appService.info('Subheader removed from dashboard.');
      },
      'primary',
    );
  }

  async removeTrafficLight(dashboardKey: string, index: number) {
    appService.confirm(
      'Remove traffic light from dashboard?',
      'The traffic light and all questions below it will be removed from the dashboard.',
      'Remove traffic light',
      async () => {
        await this.deleteDashboardHeading(dashboardKey, index);
        appService.info('Traffic light removed from dashboard.');
      },
      'primary',
    );
  }

  async openAddDashboardDialog() {
    this.resetNewDashboardData();
    this.state.dashboardCreationTab = 'template';
    this.state.dashboardCopyId = '';
    this.state.availableTemplates = await this.fetchDashboardTemplateKeys();
    this.state.addDashboardDialog = true;
  }

  copyDashboard(dashboardKey: string) {
    navigator.clipboard.writeText(`${this.currentTenantId()}_${dashboardKey}`);
    appService.info($t('Copilot.dashboard.dashboardCopied'));
  }

  async pasteDashboardFromId() {
    if (!this.state.dashboardCopyId) {
      return;
    }
    try {
      await this.pasteDashboard(this.state.dashboardCopyId);
      await this.fetchDashboards();
      this.state.addDashboardDialog = false;
      this.state.dashboardCopyId = '';
      appService.info($t('Copilot.dashboard.dashboardCopied'));
    } catch (error) {
      appService.error($t('Copilot.dashboard.errorCopyingDashboard'));
    }
  }

  openNewDashboardDetailsDialog() {
    this.state.addDashboardDialog = false;
    this.state.showNewDashboardDetailsDialog = true;
  }

  cancelAddDashboard() {
    this.state.addDashboardDialog = false;
    this.state.dashboardCreationTab = 'template';
    this.state.dashboardCopyId = '';
    this.resetNewDashboardData();
  }

  cancelNewDashboardDetails() {
    this.state.showNewDashboardDetailsDialog = false;
    this.resetNewDashboardData();
  }

  resetNewDashboardData() {
    this.state.selectedTemplateKey = '';
    this.state.newDashboardData = {
      key: '',
      order: 0,
      enabled: true,
      automation: 'DISABLED' as Automation,
      icon: '',
      title: {
        de: '',
        en: '',
        it: '',
        fr: '',
      },
    };
  }

  isNewDashboardDataValid(): boolean {
    return !!(
      (this.state.newDashboardData.order !== undefined || this.state.newDashboardData.order === 0) &&
      this.state.newDashboardData.title.de &&
      this.state.newDashboardData.title.en &&
      this.state.newDashboardData.title.it &&
      this.state.newDashboardData.title.fr &&
      this.state.newDashboardData.icon
    );
  }

  editDashboardDetails(dashboard: ViewData) {
    const dashboards = this.getDashboards();
    const selectedDashboard = dashboards.find((d: CopilotDashboard) => d.key === dashboard.key);
    if (!selectedDashboard) {
      return;
    }
    this.state.newDashboardData = {
      key: dashboard.key,
      order: dashboard.order,
      enabled: dashboard.enabled,
      automation: dashboard.automation as Automation,
      icon: dashboard.icon,
      title: {
        de: selectedDashboard.title.de,
        en: selectedDashboard.title.en,
        it: selectedDashboard.title.it,
        fr: selectedDashboard.title.fr,
      },
    };
    this.state.showNewDashboardDetailsDialog = true;
  }

  async deleteDashboardWithConfirm(dashboardKey: string) {
    appService.confirm(
      'Delete dashboard?',
      'The dashboard and all its questions will be permanently deleted.',
      'Delete dashboard',
      async () => {
        await this.deleteDashboard(dashboardKey);
        await this.fetchDashboards();
        appService.info('Dashboard deleted');
      },
      'primary',
    );
  }

  async translateLocalizedProperty() {
    if (!this.state.newDashboardData.title.de) {
      return;
    }

    this.state.isLoading = true;
    try {
      if (!this.state.newDashboardData.title.en && !this.state.newDashboardData.title.it && !this.state.newDashboardData.title.fr) {
        const translations = await this.translateText(this.state.newDashboardData.title.de);
        if (translations) {
          this.state.newDashboardData.title.en = translations.en;
          this.state.newDashboardData.title.it = translations.it;
          this.state.newDashboardData.title.fr = translations.fr;
        }
      }
    } finally {
      this.state.isLoading = false;
    }
  }

  async insertOrUpdateDashboard() {
    if (!this.isNewDashboardDataValid()) return;

    if (this.state.newDashboardData.key) {
      // Update existing dashboard
      const dashboards = this.getDashboards();
      const existingDashboard = dashboards.find((d: CopilotDashboard) => d.key === this.state.newDashboardData.key);
      if (existingDashboard) {
        const updatedDashboard = {
          ...existingDashboard,
          order: this.state.newDashboardData.order,
          enabled: this.state.newDashboardData.enabled,
          automation: this.state.newDashboardData.automation,
          icon: this.state.newDashboardData.icon,
          title: this.state.newDashboardData.title,
        };
        await this.updateDashboard(updatedDashboard);
        await this.fetchDashboards();
        this.state.showNewDashboardDetailsDialog = false;
        this.resetNewDashboardData();
        appService.info($t('Copilot.dashboard.dashboardUpdated'));
      }
    } else {
      // Create new dashboard
      this.state.newDashboardData.key = uuidv4();
      await this.createDashboard(this.state.selectedTemplateKey, this.state.newDashboardData);
      await this.fetchDashboards();
      this.state.showNewDashboardDetailsDialog = false;
      this.resetNewDashboardData();
      appService.info($t('Copilot.dashboard.dashboardCreated'));
    }
  }

  async fetchQuestionHistoriesForDashboard(dashboardKey: string | null = null) {
    if (!dashboardKey) {
      return;
    }
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) {
      return;
    }
    const questionItems = dashboard.items.filter((item) => Object.hasOwn(item, 'key'));
    const questionKeys = questionItems.map((item) => (item as CopilotCatalogQuestionLocalized).key);

    if (questionKeys.length === 0) {
      return;
    }

    try {
      this.state.questionHistories = await dashboardsService.fetchQuestionHistories(questionKeys);
    } catch (error) {
      appService.error('Error fetching question histories: ' + error);
    }
  }

  async fetchQuestionHistories(questionKeys: string[]): Promise<Record<string, CopilotCatalogQuestionHistoryResponse>> {
    if (!questionKeys.length) {
      return {};
    }

    try {
      const response = await axios.post<Record<string, CopilotCatalogQuestionHistoryResponse>>(
        config.API.COPILOT.DASHBOARDS.HISTORIES.replace('{legalCaseId}', this.currentCaseId()),
        { questionKeys },
      );
      return response.data;
    } catch (error) {
      appService.error('Error fetching question histories: ' + error);
      return {};
    }
  }

  /**
   * Answer a dashboard question by sending a request to the backend
   * @param dashboardKey The dashboard key
   * @param question The question to answer
   * @returns Promise with the response
   */
  async answerDashboardQuestion(dashboardKey: string, question: Partial<CopilotQuestion>): Promise<CopilotCatalogQuestionHistoryResponse> {
    const requestParams: CopilotRequest = {
      requestId: uuidv4(),
      conversationItems: [],
      question: question as CopilotQuestion,
      analytics: this.analyticsMode,
    };

    $a.l($a.e.COPILOT_QUESTION, {
      key: question.catalogKey,
      prompt: question.prompt,
      scope: 'DASHBOARD',
    });

    const endpointUrl = config.API.COPILOT.DASHBOARDS.ANSWER.replace('{legalCaseId}', this.currentCaseId()).replace('{dashboardKey}', dashboardKey);
    let response = await axios.post<CopilotResponse>(endpointUrl, requestParams, { timeout: 180_000 });
    if (!response.data) {
      response = await axios.post<CopilotResponse>(endpointUrl, requestParams, { timeout: 180_000 });
    }
    const responseData = response.data;

    let success = true;
    const errorMessage: Set<string> = new Set();

    for (const qa of responseData.conversationItems[responseData.conversationItems.length - 1].qa) {
      if ('success' in qa && !qa.success) {
        success = false;
        if (qa.errorMessage) {
          errorMessage.add(qa.errorMessage);
        }
      }
    }

    // refresh all dependent questions
    const dependencies = this.getAllQuestionDependencies(dashboardKey, question.catalogKey!);
    if (dependencies.size > 0) {
      const dependentResponse = await this.fetchQuestionHistories(Array.from(dependencies));
      Object.keys(dependentResponse).forEach((key) => {
        dashboardsService.state.questionHistories[key] = dependentResponse[key];
      });
    }

    return {
      copilotResponse: responseData,
      userId: '',
      timestamp: '',
      outdated: false,
      success,
      errorMessage: Array.from(errorMessage).join(', '),
    } as CopilotCatalogQuestionHistoryResponse;
  }

  // Get all dependencies for a question in a dashboard
  getAllQuestionDependencies(dashboardKey: string, questionKey: string): Set<string> {
    const dependencies = new Set<string>();
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) {
      return dependencies;
    }

    const collectDependencies = (qKey: string, visited = new Set<string>()) => {
      if (visited.has(qKey)) {
        return;
      }

      visited.add(qKey);

      const questionItems = dashboard.items.filter((item) => Object.hasOwn(item, 'key'));
      const question = questionItems.find((item) => (item as CopilotCatalogQuestionLocalized).key === qKey) as CopilotCatalogQuestionLocalized;

      if (question && question.dependencyQuestionKeys && question.dependencyQuestionKeys.length > 0) {
        for (const dependencyKey of question.dependencyQuestionKeys) {
          dependencies.add(dependencyKey);
          collectDependencies(dependencyKey, visited);
        }
      }
    };

    collectDependencies(questionKey);
    return dependencies;
  }

  // Get all questions that depend on the given question
  getAllDependentQuestions(dashboardKey: string, questionKey: string): Set<string> {
    const dependents = new Set<string>();
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) {
      return dependents;
    }

    const questionItems = dashboard.items.filter((item) => Object.hasOwn(item, 'key'));

    const dependencyMap = new Map<string, string[]>();

    questionItems.forEach((item) => {
      const question = item as CopilotCatalogQuestionLocalized;
      if (question.dependencyQuestionKeys && question.dependencyQuestionKeys.length > 0) {
        question.dependencyQuestionKeys.forEach((depKey) => {
          if (!dependencyMap.has(depKey)) {
            dependencyMap.set(depKey, []);
          }
          dependencyMap.get(depKey)!.push(question.key);
        });
      }
    });

    const collectDependents = (qKey: string, visited = new Set<string>()) => {
      if (visited.has(qKey)) {
        return;
      }

      visited.add(qKey);

      const directDependents = dependencyMap.get(qKey) || [];
      for (const dependentKey of directDependents) {
        dependents.add(dependentKey);
        collectDependents(dependentKey, visited);
      }
    };

    collectDependents(questionKey);
    return dependents;
  }

  /**
   * Update an answer for a question
   * @param historyId The history ID of the answer to update
   * @param newText The new text for the answer
   */
  async updateAnswer(historyId: string, newText: string) {
    const endpointUrl = `${config.API.COPILOT.LEGALCASE.BASE.replace('{legalCaseId}', this.currentCaseId())}/history/${historyId}/answer`;
    await axios.patch(endpointUrl, { text: newText });
  }

  async clearQuestionAnswer(dashboardKey: string, questionKey: string) {
    try {
      let url = config.API.INTERNAL.CLEAR_COPILOT_ANSWER.replace('{questionKey}', questionKey).replace('{legalCaseId}', this.currentCaseId());
      await axios.delete(url);
      delete this.state.questionHistories[questionKey];
      const dependentQuestions = this.getAllDependentQuestions(dashboardKey, questionKey);
      if (dependentQuestions.size > 0) {
        for (const dependentQuestionKey of dependentQuestions.values()) {
          url = config.API.INTERNAL.CLEAR_COPILOT_ANSWER.replace('{questionKey}', dependentQuestionKey).replace(
            '{legalCaseId}',
            this.currentCaseId(),
          );
          await axios.delete(url);
          delete this.state.questionHistories[dependentQuestionKey];
        }
      }
    } catch (error) {
      appService.error('Error clearing question answer: ' + error);
      throw error;
    }
  }

  async sleep(ms: number) {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}

export const dashboardsService = new DashboardsService();
export default dashboardsService;
