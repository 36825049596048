import { AuthService } from '@/common/services/auth/auth.service';
import { ObjectValues } from '@/common/types/common.types';

export const SLOTS = {
  Left: 'Left',
  LeftExtension: 'LeftExtension',

  // main, most of the space
  DocumentListExtension: 'DocumentListExtension',
  DocumentList: 'DocumentList',
  MainContent: 'MainContent',
  CaseOverview: 'CaseOverview',

  RightExtension: 'RightExtension',
  Right: 'Right',
} as const;
export type SlotId = ObjectValues<typeof SLOTS>;

export interface PanelMetadata {
  slot: SlotId;
  initialState: boolean;
  detachable: boolean;
  enabled: (authService: AuthService) => boolean;
  legaliAdminOnly: boolean;
  button: {
    divider: (authService: AuthService) => boolean;
    icon: string;
    closeTooltipMessageId: string;
    openTooltipMessageId: string;
  };
}

export const panels = {
  // not visible in navigation
  DuplicatesReview: {
    slot: SLOTS.MainContent,
    initialState: false,
    detachable: false,
    enabled: () => true,
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: '',
      closeTooltipMessageId: '',
      openTooltipMessageId: '',
    },
  },
  WebViewer: {
    slot: SLOTS.MainContent,
    initialState: true,
    detachable: false,
    enabled: () => true,
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: '',
      closeTooltipMessageId: '',
      openTooltipMessageId: '',
    },
  },

  CaseOverview: {
    slot: SLOTS.CaseOverview,
    initialState: false,
    detachable: true,
    enabled: (authService) =>
      authService.hasFeature('ENABLE_CASEHISTORY') || authService.hasFeature('ENABLE_DASHBOARDS') || authService.hasFeature('ENABLE_ANALYTICS'),
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: '',
      closeTooltipMessageId: '',
      openTooltipMessageId: '',
    },
  },

  // CASE
  LegalCaseAccess: {
    slot: SLOTS.RightExtension,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_LEGALCASE_ACCESS') && authService.hasFeature('ENABLE_COLLABORATION'),
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-key',
      closeTooltipMessageId: 'CaseDetail.Access.Dialog.title',
      openTooltipMessageId: 'CaseDetail.Access.Dialog.title',
    },
  },
  PII: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: () => true,
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-face-man-shimmer',
      closeTooltipMessageId: 'CaseDetail.Pii.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Pii.openTooltip',
    },
  },

  // DOCS
  Labels: {
    slot: SLOTS.DocumentListExtension,
    initialState: false,
    detachable: false,
    enabled: () => true,
    legaliAdminOnly: false,
    button: {
      divider: () => true,
      icon: 'mdi-label',
      closeTooltipMessageId: 'CaseDetail.Labels.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Labels.openTooltip',
    },
  },
  DocumentList: {
    slot: SLOTS.DocumentList,
    initialState: true,
    detachable: false,
    enabled: () => true,
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-timeline-outline',
      closeTooltipMessageId: 'CaseDetail.DocumentsList.closeTooltip',
      openTooltipMessageId: 'CaseDetail.DocumentsList.openTooltip',
    },
  },
  // NOTE(dp): if rename this panel, remove legacy link handling in detailViewService.initArrangement() ("p === 'CasePilot' ? 'CaseOverview' : p")
  Copilot: {
    slot: SLOTS.RightExtension,
    initialState: false,
    detachable: true,
    enabled: (authService) => authService.hasFeature('ENABLE_DOCPILOT') || authService.hasFeature('ENABLE_CASEPILOT'),
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-creation',
      closeTooltipMessageId: 'CaseDetail.CopilotPanel.closeTooltip',
      openTooltipMessageId: 'CaseDetail.CopilotPanel.openTooltip',
    },
  },
  Authors: {
    slot: SLOTS.LeftExtension,
    initialState: false,
    detachable: false,
    enabled: () => true,
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-account-outline',
      closeTooltipMessageId: 'CaseDetail.InvolvedParties.closeTooltip',
      openTooltipMessageId: 'CaseDetail.InvolvedParties.openTooltip',
    },
  },
  Metadata: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_METADATA'),
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-pipe',
      closeTooltipMessageId: 'CaseDetail.Metadata.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Metadata.openTooltip',
    },
  },
  FormData: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('LEGALI_ADMIN'),
    legaliAdminOnly: true,
    button: {
      divider: () => false,
      icon: 'mdi-form-textbox',
      closeTooltipMessageId: 'CaseDetail.FormData.closeTooltip',
      openTooltipMessageId: 'CaseDetail.FormData.openTooltip',
    },
  },

  // EXTRACTION
  Diagnosis: {
    slot: SLOTS.LeftExtension,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasFeature('ENABLE_MEDINSIGHTS_DOCLIST_FILTER'),
    legaliAdminOnly: false,
    button: {
      divider: () => true,
      icon: 'mdi-stethoscope',
      closeTooltipMessageId: 'CaseDetail.Diagnoses.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Diagnoses.openTooltip',
    },
  },
  WorkInabilities: {
    slot: SLOTS.LeftExtension,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasFeature('ENABLE_MEDINSIGHTS_DOCLIST_FILTER'),
    legaliAdminOnly: false,
    button: {
      divider: () => false,
      icon: 'mdi-wheelchair',
      closeTooltipMessageId: 'CaseDetail.WorkInabilities.closeTooltip',
      openTooltipMessageId: 'CaseDetail.WorkInabilities.openTooltip',
    },
  },
  Timelines: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('LEGALI_ADMIN'),
    legaliAdminOnly: true,
    button: {
      divider: () => false,
      icon: 'mdi-clock-outline',
      closeTooltipMessageId: 'CaseDetail.Timelines.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Timelines.openTooltip',
    },
  },

  // TOOLS
  Notebook: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_NOTEBOOK'),
    legaliAdminOnly: false,
    button: {
      divider: () => true,
      icon: 'mdi-notebook-outline',
      closeTooltipMessageId: 'CaseDetail.Notebook.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Notebook.openTooltip',
    },
  },
  Collaboration: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_COLLABORATION') && authService.hasFeature('ENABLE_COLLABORATION'),
    legaliAdminOnly: false,
    button: {
      divider: (authService: AuthService) => !authService.hasPermission('ACCESS_CASE_DETAIL_NOTEBOOK'),
      icon: 'mdi-account-group-outline',
      closeTooltipMessageId: 'Tickets.closeTooltip',
      openTooltipMessageId: 'Tickets.openTooltip',
    },
  },
  Export: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_EXPORT') && authService.hasFeature('ENABLE_EXPORT'),
    legaliAdminOnly: false,
    button: {
      divider: (authService: AuthService) =>
        !authService.hasPermission('ACCESS_CASE_DETAIL_NOTEBOOK') &&
        !authService.hasPermission('ACCESS_CASE_DETAIL_COLLABORATION') &&
        !authService.hasFeature('ENABLE_COLLABORATION'),
      icon: 'mdi-file-export-outline',
      closeTooltipMessageId: 'CaseDetail.Export.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Export.openTooltip',
    },
  },
} satisfies Record<string, PanelMetadata>;

export type PanelId = keyof typeof panels;
