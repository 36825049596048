import { $t } from '@/app/i18n/i18n.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import DOC_DOCTYPE_LABELS_RAW from '@/common/entities/DOC_DOCTYPE_LABELS.json5';
import { MultilingualText, UUID } from '@/common/types/common.types';

export type LabelStructureNode = {
  labelKey: string;
  children?: LabelStructureNode[];
};

export interface LabelMetadataRaw {
  id: string;
  doctype: string;
  title: MultilingualText;
  color: string;
  icon: string;
}

export interface LabelMetadataExtended extends LabelMetadataRaw {
  key: string;
  sorting: string;
  parentId: string;
}

export interface LabelMetadataLocalized extends Omit<LabelMetadataExtended, 'title'> {
  title: string;
}

export class DoctypeService {
  data: { structure: LabelStructureNode[]; metadata: Record<string, LabelMetadataExtended> };

  constructor() {
    this.data = DOC_DOCTYPE_LABELS_RAW;

    // populate keys
    for (const [key, doctypeLabel] of Object.entries(this.data.metadata)) {
      doctypeLabel.key = key;
    }

    // travel through tree and populate 'sorting' and 'parentId'
    for (let i = 0; i < this.data.structure.length; i++) {
      const node = this.data.structure[i];
      this.data.metadata[node.labelKey].sorting = `${Number(i) + 1}`;

      if (node.children) {
        for (let j = 0; j < node.children.length; j++) {
          const firstLevelChildNode = node.children[j];
          this.data.metadata[firstLevelChildNode.labelKey].sorting = `${Number(i) + 1}.${Number(j) + 1}`;
          this.data.metadata[firstLevelChildNode.labelKey].parentId = this.getDoctypeLabelByKey(node.labelKey).id;

          if (firstLevelChildNode.children) {
            for (let k = 0; k < firstLevelChildNode.children.length; k++) {
              const secondLevelChildNode = firstLevelChildNode.children[k];
              this.data.metadata[secondLevelChildNode.labelKey].sorting = `${Number(i) + 1}.${Number(j) + 1}.${Number(k) + 1}`;
              this.data.metadata[secondLevelChildNode.labelKey].parentId = this.getDoctypeLabelByKey(firstLevelChildNode.labelKey).id;
            }
          }
        }
      }
    }
  }

  normalizedDoctypeId(id: string) {
    return id.endsWith('_other') ? id.replace('_other', '') : id;
  }

  isTypeOther(id: string) {
    return id.endsWith('_other');
  }

  getAllDoctypes(locale?: string) {
    return Object.values(this.data.metadata).map((metadata) => this.localizeMetadata(metadata, locale));
  }

  getDoctypeLabelById(id: string, locale?: string) {
    const normalizedId = this.normalizedDoctypeId(id);
    return this.localizeMetadata(Object.values(this.data.metadata).find((l) => l.id === normalizedId)!, locale);
  }

  getDoctypeLabelByKey(key: string, locale?: string) {
    return this.localizeMetadata(this.data.metadata[key], locale);
  }

  getDoctypeLabelByType(type: string, locale?: string) {
    return this.getDoctypeLabelByKey(type.replace('type_', ''), locale);
  }

  localizeMetadata(metadata: LabelMetadataExtended, locale?: string): LabelMetadataLocalized {
    return {
      ...metadata,
      title: $t(metadata.title, locale),
    };
  }

  getDirectChildrenIds(id: string) {
    if (this.isTypeOther(id)) {
      return [];
    }

    const doctypeLabel = this.getDoctypeLabelById(id);
    const childrenKeys: string[] = [];
    this.traverse((node) => {
      if (node.labelKey === doctypeLabel.key && node.children) {
        childrenKeys.push(...node.children.map((c) => c.labelKey));
      }
    });

    return childrenKeys.map((k) => this.getDoctypeLabelByKey(k).id);
  }

  getDeepChildrenIds(id: string) {
    if (this.isTypeOther(id)) {
      return [];
    }

    const doctypeLabel = this.getDoctypeLabelById(id);
    const childrenKeys: string[] = [doctypeLabel.key];
    this.traverse((node) => {
      if (childrenKeys.includes(node.labelKey) && node.children) {
        childrenKeys.push(...node.children.map((c) => c.labelKey));
      }
    });

    return childrenKeys.filter((k) => k !== doctypeLabel.key).map((k) => this.getDoctypeLabelByKey(k).id);
  }

  traverse(callback: (node: LabelStructureNode) => void, tree: LabelStructureNode[] = this.data.structure) {
    for (const parent of tree) {
      callback(parent);
      if (parent.children) {
        this.traverse(callback, parent.children);
      }
    }
  }

  async updateDocumentType(docId: UUID, newDoctype: string) {
    const document = documentService.getDocumentById(docId);
    if (!document) {
      return;
    }
    documentService.setDocumentMetadata({ docId, update: { DOCTYPE: newDoctype }, undo: { DOCTYPE: document.metadata.DOCTYPE.value } });
  }

  async removeDocumentType(docId: UUID) {
    const document = documentService.getDocumentById(docId);
    if (!document) {
      return;
    }
    documentService.setDocumentMetadata({ docId, update: { DOCTYPE: 'type_other' }, undo: { DOCTYPE: document.metadata.DOCTYPE.value } });
  }
}

export const doctypeService = new DoctypeService();
