import { cloneDeep } from 'lodash';

import { $t } from '@/app/i18n/i18n.service';
import ACCESS_EXTERNAL_USER_GRANUALITY from '@/common/entities/ACCESS_EXTERNAL_USER_GRANUALITY.json';
import APP_ERRORS from '@/common/entities/APP_ERRORS.json';
import COLLAB_TICKET_EVENTS from '@/common/entities/COLLAB_TICKET_EVENTS.json';
import COLLAB_TICKET_PRIORITIES from '@/common/entities/COLLAB_TICKET_PRIORITIES.json';
import COLLAB_TICKET_STATUSES from '@/common/entities/COLLAB_TICKET_STATUSES.json';
import COPILOT_FORMAT_TWEAKS_DATA from '@/common/entities/COPILOT_FORMAT_TWEAKS.json';
import COPILOT_THOUGHTS from '@/common/entities/COPILOT_THOUGHTS.json';
import DIAGNOSE_QUALIFICATIONS from '@/common/entities/DIAGNOSE_QUALIFICATIONS.json5';
import DIAGNOSES from '@/common/entities/DIAGNOSES.json';
import DOC_FOLDERS from '@/common/entities/DOC_FOLDERS.json';
import DOC_METADATA from '@/common/entities/DOC_METADATA.json';
import DOCLIST_DEFAULT_ANNOTATION_MAPPINGS from '@/common/entities/DOCLIST_DEFAULT_ANNOTATION_MAPPINGS.json5';
import FEEDBACK_CATEGORIES from '@/common/entities/FEEDBACK_CATEGORIES.json';
import LEGALCASE_METADATA from '@/common/entities/LEGALCASE_METADATA.json';
import NOTIFICATION_MESSAGES from '@/common/entities/NOTIFICATION_MESSAGES.json';
import SEARCH_SUGGESTIONS_DATA from '@/common/entities/SEARCH_SUGGESTIONS.json';
import TENANT_DATA_REGIONS from '@/common/entities/TENANT_DATA_REGIONS.json5';
import TENANT_ENVIRONMENTS from '@/common/entities/TENANT_ENVIRONMENTS.json';
import TENANT_FEATURES from '@/common/entities/TENANT_FEATURES.json';
import USER_BILLING_STATUSES from '@/common/entities/USER_BILLING_STATUSES.json';
import USER_PROFESSIONS from '@/common/entities/USER_PROFESSIONS.json5';
import USER_ROLES from '@/common/entities/USER_ROLES.json5';
import USER_TAGS from '@/common/entities/USER_TAGS.json5';
import WORKINABILITY_TAGS from '@/common/entities/WORKINABILITY_TAGS.json';
import { Agent, AnswerTweak, ContextStrategy, MultipleAnswers } from '@/common/types/api-types/copilot.api.types';
import { MultilingualText, ObjectValues } from '@/common/types/common.types';

// entity service:
// - translates multilingual fields to current locale
// - adds `key` field to each item value, which is the key of the item
// - provides TypeScript definitions for metadata objects

// NOTE: some json configs in current dir are git-ignored - they are defined in BE and copied to FE on `npm install`

// COPILOT
export type CopilotFormatTweakKey = keyof typeof COPILOT_FORMAT_TWEAKS_DATA;
export type CopilotFormatTweak = {
  display: boolean;
  key: string;
  icon: string;
  title: string; // multilingual
};
export type COPILOT_FORMAT_TWEAKS = Record<CopilotFormatTweakKey, CopilotFormatTweak>;

export interface CatalogQuestionBase {
  prompt: string;

  resetHistory: boolean;
  showAsSuggestion: boolean;
  checkPlausibility: boolean;
  addReferences: boolean;

  agent: Agent;
  contextStrategy?: ContextStrategy;
  contextHint?: string;
  multipleAnswers?: MultipleAnswers;
  conditionalOnDocType?: string;
  tweaks?: AnswerTweak[];
  dependencyQuestionKeys: string[];
}
export interface CopilotCatalogQuestionRaw extends CatalogQuestionBase {
  key: string;
  title: MultilingualText;
  description: MultilingualText;
  enabled: boolean;
}
export interface CopilotCatalogQuestionLocalized extends CatalogQuestionBase {
  key: string;
  title: string;
  description: string;
  enabled: boolean;
}
export type COPILOT_PROMPTS = Record<string, CopilotCatalogQuestionRaw>;
export type COPILOT_LOCALIZED_PROMPTS = Record<string, CopilotCatalogQuestionLocalized>;

// OTHER

export type DOC_FOLDER_ITEM = {
  key: string;
  value: string; // id from BE
  title: string;
};

export type CaseMetadataKey = keyof typeof LEGALCASE_METADATA;
type LEGALCASE_METADATA = Record<
  CaseMetadataKey,
  {
    key: CaseMetadataKey;
    title: string;
    options?: {
      [key: string]: {
        key: string;
        title: string;
      };
    };
  }
>;

export type AppErrorKey = keyof typeof APP_ERRORS;
type APP_ERRORS = Record<
  AppErrorKey,
  {
    key: AppErrorKey;
    title: string;
    body: string;
    icon: string;
  }
>;

// NOTE(dp): named local because it's not exact match of metadata field (see document.api.types -> METADATA_KEY)
export type LocalDocMetadataKey = keyof typeof DOC_METADATA;
type DOC_METADATA = Record<
  LocalDocMetadataKey,
  {
    key: LocalDocMetadataKey;
    title: string;
    placeholder?: string;
    icon: string;
    alwaysDisplay?: boolean;
  }
>;

export const userProfessions = {
  CLAIMS_SPECIALIST: 'CLAIMS_SPECIALIST',
  LEGAL_LAWYER: 'LEGAL_LAWYER',
  LEGAL_LAWYER_EXTERNAL: 'LEGAL_LAWYER_EXTERNAL',
  MEDICAL_DOCTOR: 'MEDICAL_DOCTOR',
  MEDICAL_DOCTOR_EXTERNAL: 'MEDICAL_DOCTOR_EXTERNAL',
  MEDICAL_EXPERT: 'MEDICAL_EXPERT',
  UNDERWRITER: 'UNDERWRITER',
  ADMIN: 'ADMIN',
  OTHER: 'OTHER',
} as const;
export type UserProfession = ObjectValues<typeof userProfessions>;
export type UserProfessionValue = {
  key: UserProfession;
  title: string;
  promptDescription: string;
  icon: string;
  default?: boolean;
};
export type USER_PROFESSIONS = {
  [key: string]: UserProfessionValue;
};

export const userRoles = {
  LEGALI_ADMIN_ROLE: 'LEGALI_ADMIN_ROLE',
  TENANT_ADMIN_ROLE: 'TENANT_ADMIN_ROLE',
  TENANT_TECH_ROLE: 'TENANT_TECH_ROLE',
  TENANT_BASIC_ROLE: 'TENANT_BASIC_ROLE',
  EXTERNAL_BASIC_ROLE: 'EXTERNAL_BASIC_ROLE',
  EXTERNAL_ORGANISATION_ROLE: 'EXTERNAL_ORGANISATION_ROLE',
  EXTERNAL_COORDINATOR_ROLE: 'EXTERNAL_COORDINATOR_ROLE',
} as const;
export type UserRole = ObjectValues<typeof userRoles>;

export const userRoleIds = {
  'legali:admin': 'legali:admin',
  'tenant:admin': 'tenant:admin',
  'tenant:tech': 'tenant:tech',
  'tenant:basic': 'tenant:basic',
  'external:basic': 'external:basic',
  'external:readonly': 'external:readonly',
  'external:organisation': 'external:organisation',
  'external:coordinator': 'external:coordinator',
} as const;
export type UserRoleId = ObjectValues<typeof userRoleIds>;

export type USER_ROLES = Record<
  UserRole,
  {
    key: UserRole;
    id: UserRoleId;
    title: string;
    icon: string;
  }
>;

export type BillingStatus = keyof typeof USER_BILLING_STATUSES;
type USER_BILLING_STATUSES = Record<
  BillingStatus,
  {
    key: BillingStatus;
    title: string;
    default?: boolean;
  }
>;

export type ExternalUserAccessGranuality = keyof typeof ACCESS_EXTERNAL_USER_GRANUALITY;
type ACCESS_EXTERNAL_USER_GRANUALITY = Record<
  ExternalUserAccessGranuality,
  {
    key: ExternalUserAccessGranuality;
    title: string;
  }
>;

export type TenantEnvironment = keyof typeof TENANT_ENVIRONMENTS;
type TENANT_ENVIRONMENTS = Record<
  TenantEnvironment,
  {
    key: TenantEnvironment;
    name: string;
    title: string;
    color: string;
    icon: string;
  }
>;

export type TenantFeature = keyof typeof TENANT_FEATURES;
type TENANT_FEATURES = Record<
  TenantFeature,
  {
    key: TenantFeature;
    title: string;
    group: string;
  }
>;

export type TicketStatus = keyof typeof COLLAB_TICKET_STATUSES;
type COLLAB_TICKET_STATUSES = Record<
  TicketStatus,
  {
    key: TicketStatus;
    title: string;
    description?: string;
    color: string;
    icon: string;
    visibleForFiltering: boolean;
  }
>;

export type TicketEventName = keyof typeof COLLAB_TICKET_EVENTS;
type COLLAB_TICKET_EVENTS = Record<
  TicketEventName,
  {
    key: TicketEventName;
    title: string;
  }
>;

type LocalizedString = {
  [key: string]: string;
};

export type NoticiationTemplate = keyof typeof NOTIFICATION_MESSAGES;
type NOTIFICATION_MESSAGES = Record<
  NoticiationTemplate,
  {
    key: NoticiationTemplate;
    subject: LocalizedString;
    messageBody: LocalizedString;
    buttonText: LocalizedString;
    notificationGroup: string;
    notificationTitle: LocalizedString;
    notificationSubTitle: LocalizedString;
  }
>;

export type TicketPriority = keyof typeof COLLAB_TICKET_PRIORITIES;
type COLLAB_TICKET_PRIORITIES = Record<
  TicketPriority,
  {
    key: TicketPriority;
    title: string;
    color: string;
    icon: string;
  }
>;

type TENANT_DATA_REGIONS = {
  [key: string]: {
    key: string;
    title: string;
    flag: string;
  };
};

type USER_TAGS = {
  [key: string]: {
    key: string;
    title: string; // multilingual
    description: string; // multilingual
  };
};

type DefaultAnnotationMapping = {
  key: string;
  color: string;
  text: string; // multilingual
};

type DOCLIST_DEFAULT_ANNOTATION_MAPPINGS = Record<string, DefaultAnnotationMapping>;
export type DIAGNOSE_QUALIFICATIONS = {
  [key: string]: {
    key: string;
    hidden?: boolean;
    titleLong: string; // multilingual
    titleShort: string; // multilingual
    titleShortest: string; // multilingual
  };
};

export type DIAGNOSES_CHAPTER = {
  codeRange: string;
  title: string;
  diagnoses: { code: string; title: string }[];
};

export type WorkInabilityTagKey = keyof typeof WORKINABILITY_TAGS;
export const allWorkInabilityTagKeys = Object.keys(WORKINABILITY_TAGS) as WorkInabilityTagKey[];
export type WORK_INABILITY_TAGS = Record<
  WorkInabilityTagKey,
  {
    key: WorkInabilityTagKey;
    titleLong: string; // multilingual
    titleShort: string; // multilingual
    icon: string;
  }
>;

export type SearchFieldKey = keyof typeof SEARCH_SUGGESTIONS_DATA;
export const searchFieldKeys = Object.keys(SEARCH_SUGGESTIONS_DATA) as SearchFieldKey[];
export type SEARCH_SUGGESTIONS = Record<SearchFieldKey, { title: string; icon: string; iconColor?: string }>;

type FEEDBACK_CATEGORIES = Record<
  TicketPriority,
  {
    key: TicketPriority;
    title: string;
  }
>;

class EntityService {
  public get DOC_FOLDERS(): DOC_FOLDER_ITEM[] {
    return DOC_FOLDERS.map((item) => ({ ...item, title: $t(item.title), key: item.value }));
  }

  public get LEGALCASE_METADATA(): LEGALCASE_METADATA {
    const withLocalizedTitles = this.localizeAndAddKey(LEGALCASE_METADATA, ['title']);
    return Object.fromEntries(
      Object.entries(withLocalizedTitles).map(([key, val]) => [
        key,
        val.options ? { ...val, options: this.localizeAndAddKey(val.options, ['title']) } : val,
      ]),
    ) as LEGALCASE_METADATA;
  }

  public get APP_ERRORS(): APP_ERRORS {
    return this.localizeAndAddKey(APP_ERRORS, ['title', 'body']) as APP_ERRORS;
  }

  public get LEGALCASE_METADATA_TITLES(): Record<string, string> {
    return Object.fromEntries(Object.entries(this.LEGALCASE_METADATA).map(([key, val]) => [key, val.title]));
  }

  public get DOC_METADATA(): DOC_METADATA {
    return this.localizeAndAddKey(DOC_METADATA, ['title', 'placeholder']) as DOC_METADATA;
  }

  public get USER_PROFESSIONS(): USER_PROFESSIONS {
    return this.localizeAndAddKey(USER_PROFESSIONS, ['title']);
  }

  public get USER_ROLES(): USER_ROLES {
    return this.localizeAndAddKey(USER_ROLES, ['title']) as USER_ROLES;
  }

  public get USER_BILLING_STATUSES(): USER_BILLING_STATUSES {
    return this.localizeAndAddKey(USER_BILLING_STATUSES, ['title']) as USER_BILLING_STATUSES;
  }

  public get ACCESS_EXTERNAL_USER_GRANUALITY(): ACCESS_EXTERNAL_USER_GRANUALITY {
    return this.localizeAndAddKey(ACCESS_EXTERNAL_USER_GRANUALITY, ['title']) as ACCESS_EXTERNAL_USER_GRANUALITY;
  }

  public get TENANT_ENVIRONMENTS(): TENANT_ENVIRONMENTS {
    return this.localizeAndAddKey(TENANT_ENVIRONMENTS, ['title']) as TENANT_ENVIRONMENTS;
  }

  public get TENANT_FEATURES(): TENANT_FEATURES {
    return this.localizeAndAddKey(TENANT_FEATURES, ['title']) as TENANT_FEATURES;
  }

  public get COLLAB_TICKET_STATUSES(): COLLAB_TICKET_STATUSES {
    return this.localizeAndAddKey(COLLAB_TICKET_STATUSES, ['title', 'description']) as COLLAB_TICKET_STATUSES;
  }

  public get COLLAB_TICKET_EVENTS(): COLLAB_TICKET_EVENTS {
    return this.localizeAndAddKey(COLLAB_TICKET_EVENTS, ['title']) as COLLAB_TICKET_EVENTS;
  }

  public get COLLAB_TICKET_PRIORITIES(): COLLAB_TICKET_PRIORITIES {
    return this.localizeAndAddKey(COLLAB_TICKET_PRIORITIES, ['title']) as COLLAB_TICKET_PRIORITIES;
  }

  public get TENANT_DATA_REGIONS(): TENANT_DATA_REGIONS {
    return this.localizeAndAddKey(TENANT_DATA_REGIONS, ['title']);
  }

  public get COPILOT_FORMAT_TWEAKS(): COPILOT_FORMAT_TWEAKS {
    return this.localizeAndAddKey(COPILOT_FORMAT_TWEAKS_DATA, ['title']) as COPILOT_FORMAT_TWEAKS;
  }

  public get USER_TAGS(): USER_TAGS {
    return this.localizeAndAddKey(USER_TAGS, ['title', 'description']);
  }

  public get DOCLIST_DEFAULT_ANNOTATION_MAPPINGS(): DOCLIST_DEFAULT_ANNOTATION_MAPPINGS {
    return this.localizeAndAddKey(DOCLIST_DEFAULT_ANNOTATION_MAPPINGS, ['text']);
  }

  public get DIAGNOSE_QUALIFICATIONS(): DIAGNOSE_QUALIFICATIONS {
    return this.localizeAndAddKey(DIAGNOSE_QUALIFICATIONS, ['titleLong', 'titleShort', 'titleShortest']);
  }

  public get DIAGNOSES(): DIAGNOSES_CHAPTER[] {
    return DIAGNOSES.map((chapter) => ({
      ...chapter,
      title: $t(chapter.title),
      diagnoses: chapter.diagnoses.map((d) => ({ ...d, title: $t(d.title) })),
    }));
  }

  public get COPILOT_THOUGHTS(): string[] {
    return COPILOT_THOUGHTS.map($t);
  }

  public get WORK_INABILITY_TAGS(): WORK_INABILITY_TAGS {
    return this.localizeAndAddKey(WORKINABILITY_TAGS, ['titleLong', 'titleShort', 'icon']) as WORK_INABILITY_TAGS;
  }

  public get SEARCH_SUGGESTIONS(): SEARCH_SUGGESTIONS {
    return this.localizeAndAddKey(SEARCH_SUGGESTIONS_DATA, ['title']) as SEARCH_SUGGESTIONS;
  }

  public get FEEDBACK_CATEGORIES(): FEEDBACK_CATEGORIES {
    return this.localizeAndAddKey(FEEDBACK_CATEGORIES, ['title']) as FEEDBACK_CATEGORIES;
  }

  public get NOTIFICATION_MESSAGES(): NOTIFICATION_MESSAGES {
    return this.localizeAndAddKey(NOTIFICATION_MESSAGES, [
      'subject',
      'messageBody',
      'buttonText',
      'notificationTitle',
      'notificationSubTitle',
    ]) as NOTIFICATION_MESSAGES;
  }

  // translates multilingual object like {de: "Hallo", en: "Hello", ...} to the current locale
  // add `key` property
  public localizeAndAddKey(obj: Record<string, any>, fields: string[], keyPropName: string | null = 'key') {
    return Object.fromEntries(
      Object.entries(cloneDeep(obj)).map(([key, val]) => {
        for (const field of fields) {
          if (val[field] && typeof val[field] === 'object') {
            val[field] = $t(val[field]);
          }
        }
        return [key, keyPropName === null ? val : { ...val, [keyPropName]: key }];
      }),
    );
  }
}

export default new EntityService();
