<template>
  <l-menu v-model="active" :close-on-content-click="false" class="search-menu-no-box-shadow" disable-keys>
    <template #activator="{ props }">
      <v-text-field
        ref="input"
        v-model="query"
        :density="dense && 'compact'"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
        class="search-text-field rounded-lg"
        clearable
        variant="solo-filled"
        flat
        hide-details="auto"
        maxlength="100"
        persistent-placeholder
        prepend-inner-icon="mdi-magnify"
        data-testid="search_input"
        v-bind="props"
        @update:model-value="active = true"
        @click:prepend-inner="search(query ?? '', true)"
        @click:clear="onClear"
        @keydown.stop="onKeydown"
      />
    </template>

    <!-- content -->
    <v-card class="card" flat data-testid="search_results" @keydown.stop="onKeydown($event)">
      <div v-show="view === 'loading'">
        <v-list-item>
          <v-list-item-subtitle class="text-wrap">
            {{ `${query}...` }}
          </v-list-item-subtitle>
        </v-list-item>
      </div>

      <div v-show="view === 'default'" class="suggestions-default">
        <v-list-item v-if="historyComputed.length === 0">
          <v-list-item-subtitle>
            {{ $t('App.Bar.InCaseSearch.startTypingToViewSuggestions') }}
          </v-list-item-subtitle>
        </v-list-item>
        <Suggestions
          v-else
          key="historySuggestions"
          v-model:selected="selected"
          :suggestions="historyComputed"
          :header="$t('Common.recentlySearched')"
          removable
          @click:remove="removeFromHistory($event.suggestion)"
          @click:suggestion="search($event)"
        />
      </div>

      <!-- query-based suggestions -->
      <perfect-scrollbar>
        <div v-show="view === 'suggestions'" class="suggestions-query" data-testid="search_suggestions">
          <Suggestions
            v-for="(suggestion, key) in suggestions"
            :key="`suggestion_` + key"
            v-model:query="query"
            v-model:selected="selected"
            :suggestions="suggestion"
            :header="SEARCH_SUGGESTIONS[key].title"
            :data-testid="`search_${key}Suggestions`"
            @click:suggestion="search($event)"
          />
        </div>
      </perfect-scrollbar>

      <div v-show="view === 'no-suggestions'" class="suggestions-query">
        <v-list-item>
          <v-list-item-subtitle class="text-wrap">
            {{ $t('App.Bar.InCaseSearch.noResultsFor', [query]) }}
          </v-list-item-subtitle>
        </v-list-item>
      </div>

      <v-divider />

      <v-list-item v-show="view === 'suggestions'" density="compact">
        <template #prepend>
          <v-icon>mdi-keyboard-return</v-icon>
        </template>

        <v-list-item-subtitle>
          {{ onEnterText }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-card>
  </l-menu>
</template>

<script lang="ts">
import { debounce, DebouncedFunc } from 'lodash';
import { defineComponent, PropType } from 'vue';

import { $t } from '@/app/i18n/i18n.service';
import Suggestions from '@/case-detail/search/components/Suggestions.vue';
import { SearchSuggestion } from '@/case-detail/search/services/search.service';
import { BroadcastEventEmitParams } from '@/common/services/broadcast.events';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import entityService, { SearchFieldKey, searchFieldKeys } from '@/common/services/entity.service';

type FetchSuggestionsFn = (query: string, fieldKey: SearchFieldKey) => Promise<SearchSuggestion[]>;

export default defineComponent({
  components: {
    Suggestions,
  },

  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/no-unused-properties
    fetch: {
      type: Function as PropType<FetchSuggestionsFn>,
      required: true,
    },
    placeholder: {
      type: String,
      default: $t('App.Bar.InCaseSearch.defaultPlaceholder'),
    },
    historyPrefix: {
      type: String,
      default: 'SEARCH',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearOnSearch: {
      type: Boolean,
      default: false,
    },
    noEmptyQuerySearch: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['searched', 'clear'],

  data() {
    return {
      loading: false,
      query: null as null | string,
      active: false,
      selected: null as null | string,
      selectedIndex: -1,
      history: [] as string[],
      suggestions: Object.fromEntries(searchFieldKeys.map((f) => [f, [] as SearchSuggestion[]])) as Record<SearchFieldKey, SearchSuggestion[]>,
      debSearch: null as null | DebouncedFunc<
        (
          me: {
            fetch: FetchSuggestionsFn;
            handleResults: (suggestions: SearchSuggestion[]) => void;
            suggestions: Record<SearchFieldKey, SearchSuggestion[]>;
          },
          searchQuery: string,
        ) => void
      >,
      SEARCH_SUGGESTIONS: entityService.SEARCH_SUGGESTIONS,
    };
  },

  computed: {
    historyLocalStorageKey() {
      return `${this.historyPrefix}_HISTORY`;
    },
    view() {
      if (!this.active) {
        return null;
      }
      if (!this.query) {
        return 'default';
      }
      if (this.loading) {
        return 'loading';
      }
      if (this.query && this.allSuggestions.length > 0) {
        return 'suggestions';
      }
      if (this.query && this.allSuggestions.length === 0) {
        return 'no-suggestions';
      }
      return null;
    },
    onEnterText() {
      return !!this.selected && !!this.all[this.selectedIndex]
        ? this.$t('App.Bar.InCaseSearch.showResultsForItemWithEnterShortcut', { item: this.all[this.selectedIndex].suggestion })
        : this.$t('App.Bar.InCaseSearch.showAllResultsWithEnterShortcut');
    },
    historyComputed(): SearchSuggestion[] {
      return this.history.map((h) => ({
        id: `HISTORY__${h}`,
        suggestion: h,
        field: 'fulltext',
      }));
    },
    allSuggestions() {
      return Object.values(this.suggestions).flat();
    },
    all(): SearchSuggestion[] {
      switch (this.view) {
        case 'default':
          return [...this.historyComputed];
        case 'suggestions':
          return this.allSuggestions;
        case 'no-suggestions':
          return [];
        default:
          return [];
      }
    },
  },

  watch: {
    view() {
      this.resetSelections();
    },
    query(query) {
      this.resetSelections();

      if (!this.isValid(query)) {
        this.resetSuggestions();
        return;
      }

      // debounced request
      this.loading = true;
      if (this.debSearch) {
        this.debSearch.cancel();
      }

      // get last token from query
      const queryTokens = query.trim().split(/\s+/);
      const lastQueryToken = queryTokens[queryTokens.length - 1];

      // Note(ndv): maybe we simplify this, define the suggested fields somewhere, dynamically define data props etc.
      this.debSearch = debounce((me, searchQuery) => {
        for (const key of searchFieldKeys) {
          me.fetch(searchQuery, key)
            .then((result) => (me.suggestions[key] = result))
            .finally(() => me.handleResults(me.suggestions[key]));
        }
      }, 200);
      this.debSearch(this, lastQueryToken);
    },
  },

  mounted() {
    // load history if existing
    if (localStorage.getItem(this.historyLocalStorageKey) !== null) {
      this.history = JSON.parse(localStorage.getItem(this.historyLocalStorageKey) ?? '');
    }

    broadcastEventBus.subscribe('SEARCH_RESET_EVENT', this.handleSearchResetEvent);
    broadcastEventBus.subscribe('UPDATE_SMART_SEARCH_QUERY_EVENT', this.handleUpdateSmartSearchQueryEvent);
  },
  beforeUnmount() {
    broadcastEventBus.unsubscribe('SEARCH_RESET_EVENT', this.handleSearchResetEvent);
    broadcastEventBus.unsubscribe('UPDATE_SMART_SEARCH_QUERY_EVENT', this.handleUpdateSmartSearchQueryEvent);
  },
  methods: {
    search(suggestion: SearchSuggestion | string, onEnter = false) {
      // compute value
      let sugg = typeof suggestion === 'string' ? { field: 'fulltext', suggestion: suggestion.trim() } : suggestion;
      if (onEnter && this.selectedIndex > -1) {
        sugg = this.all[this.selectedIndex];
      }

      // no empty query search if required
      if (this.noEmptyQuerySearch && sugg.suggestion.length === 0) {
        return;
      }

      // update input value
      if (!this.clearOnSearch && sugg.field === 'fulltext') {
        this.query = sugg.suggestion;
      } else {
        this.query = null;
      }
      // update history if necessary
      if (sugg.field === 'fulltext') {
        this.updateHistory(sugg.suggestion);
      }

      this.active = false;
      // emit event
      this.$emit('searched', sugg);

      // reset selections
      this.resetSelections();
    },
    onClear() {
      this.resetSelections();
      this.resetSuggestions();
      this.$emit('clear');
    },
    onKeydown(event: KeyboardEvent) {
      if (event.code === 'Enter') {
        this.search((event.target as HTMLInputElement).value, true);
      } else if (event.code === 'Escape') {
        this.active = false;
      } else if (event.code === 'ArrowUp') {
        this.prev();
      } else if (event.code === 'ArrowDown') {
        this.next();
      }
    },
    prev() {
      const prevIndex = this.selectedIndex - 1 < 0 ? this.all.length - 1 : this.selectedIndex - 1;
      // NOTE(ndv): this can happen when the suggestions menu is closed
      if (!this.all[prevIndex]) return;
      this.selected = this.all[prevIndex].id;
      this.selectedIndex = prevIndex;
    },
    next() {
      const nextIndex = this.selectedIndex + 1 >= this.all.length ? 0 : this.selectedIndex + 1;
      // NOTE(ndv): this can happen when the suggestions menu is closed
      if (!this.all[nextIndex]) return;
      this.selected = this.all[nextIndex].id;
      this.selectedIndex = nextIndex;
    },
    updateHistory(query: string) {
      // only the latest 3 queries are kept (and only from full-text searches)
      if (this.history.includes(query) || !this.isValid(query)) return;
      const h = [...this.history];
      h.unshift(query);
      // update local state
      this.history = h.slice(0, Math.min(3, h.length));
      // update localstorage
      localStorage.setItem(this.historyLocalStorageKey, JSON.stringify(this.history));
    },
    removeFromHistory(query: string) {
      // update local state
      this.history = this.history.filter((q) => q !== query);
      // update localstorage
      localStorage.setItem(this.historyLocalStorageKey, JSON.stringify(this.history));
    },
    resetSelections() {
      // Note(ndv): without $nextTick (or delay) the selected value is never set, because it is cleared too fast
      this.$nextTick(() => {
        this.selected = null;
        this.selectedIndex = -1;
      });
    },
    resetSuggestions() {
      this.suggestions = Object.fromEntries(searchFieldKeys.map((f) => [f, [] as SearchSuggestion[]])) as Record<SearchFieldKey, SearchSuggestion[]>;

      for (const key of searchFieldKeys) {
        this.suggestions[key] = [];
      }
    },
    reset() {
      if (this.query) {
        this.query = null;
      }
      this.resetSuggestions();
    },
    isValid(query: string) {
      return !!query;
    },
    // eslint-disable-next-line vue/no-unused-properties
    handleResults(results: SearchSuggestion[]) {
      if (results.length > 0) {
        this.loading = false;
      }
    },
    handleSearchResetEvent() {
      this.reset();
    },
    handleUpdateSmartSearchQueryEvent(event: BroadcastEventEmitParams['UPDATE_SMART_SEARCH_QUERY_EVENT']) {
      this.reset();
      this.query = event.query;
    },
  },
});
</script>

<style lang="scss" scoped>
.card {
  height: auto;
  background: rgb(var(--v-theme-surface));
}

.suggestions-default,
.suggestions-query {
  height: auto;
  max-height: 50vh;
  background: rgb(var(--v-theme-surface));
}

.search-text-field :deep(.v-input__slot::before) {
  border: none;
}

.search-menu-no-box-shadow :deep(.v-menu__content) {
  box-shadow: none !important;
  border: none !important;
}

.text-wrap {
  word-break: break-all;
}
</style>
