import { WorkInabilityTagKey } from '@/common/services/entity.service';
import { UUID } from '@/common/types/common.types';

export enum Status {
  REVIEW = 'REVIEW',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum Extractor {
  EXTRACTION = 'EXTRACTION',
  MANUAL = 'MANUAL',
}

// See WorkInabilityEO.java
export interface WorkInability {
  id: UUID; // UUID
  fromDate: string;
  toDate: string | null;
  percent: number;
  sourceFileId: UUID; // UUID
  page: number;
  tags: WorkInabilityTagKey[];
  author: string;
  legalCaseId: UUID; // UUID
  status: Status;
  extractor: Extractor;
  log: string;
}

export interface WorkInabilityRequest {
  fromDate: string;
  toDate: string | null;
  percent: number;
  sourceFileId: UUID;
  page: number;
  tags: string[];
  status?: Status;
}
