import { ObjectValues } from '@/common/types/common.types';

export const PERMISSIONS = {
  // Feature access
  ACCESS_CASE_LIST: 'ACCESS_CASE_LIST',
  ACCESS_CASE_DETAIL: 'ACCESS_CASE_DETAIL',
  ACCESS_CASE_DETAIL_EXPORT: 'ACCESS_CASE_DETAIL_EXPORT',
  ACCESS_CASE_DETAIL_COLLABORATION: 'ACCESS_CASE_DETAIL_COLLABORATION',
  ACCESS_COLLABORATION_OVERVIEW: 'ACCESS_COLLABORATION_OVERVIEW',
  ACCESS_CASE_DETAIL_NOTEBOOK: 'ACCESS_CASE_DETAIL_NOTEBOOK',
  ACCESS_CASE_DETAIL_LEGALCASE_ACCESS: 'ACCESS_CASE_DETAIL_LEGALCASE_ACCESS',
  ACCESS_CASE_DETAIL_METADATA: 'ACCESS_CASE_DETAIL_METADATA',
  ACCESS_WORKSPACE_SETTINGS: 'ACCESS_WORKSPACE_SETTINGS',
  ACCESS_ORGANIZATION_SETTINGS: 'ACCESS_ORGANIZATION_SETTINGS',
  ACCESS_AUDIT_LOG: 'ACCESS_AUDIT_LOG',
  ACCESS_REPORTING: 'ACCESS_REPORTING',
  ACCESS_EXTENDED_TENANT_MENU: 'ACCESS_EXTENDED_TENANT_MENU',
  ACCESS_ANALYTICS: 'ACCESS_ANALYTICS',

  // LegalCase Actions
  // LEGALCASE_R_ALL: no legalcase authentication, i.e. access groups are not checked
  LEGALCASE_R_ALL: 'LEGALCASE_R_ALL',
  // LEGALCASE_R_DEFAULT: access all legalcases without or with matching AG on the user
  LEGALCASE_R_DEFAULT: 'LEGALCASE_R_DEFAULT',
  LEGALCASE_UD: 'LEGALCASE_UD',
  LEGALCASE_U_CLOSE: 'LEGALCASE_U_CLOSE',
  LEGALCASE_U_OWNER_META: 'LEGALCASE_U_OWNER_META',
  LEGALCASE_ACCESS_CRUD: 'LEGALCASE_ACCESS_CRUD',
  LEGALCASE_ACCESS_DELEGATE: 'LEGALCASE_ACCESS_DELEGATE',

  // SourceFile Actions
  SOURCEFILE_RUD: 'SOURCEFILE_RUD',
  SOURCEFILE_C: 'SOURCEFILE_C',

  // Ticket Actions
  TICKET_R_ALL: 'TICKET_R_ALL',
  TICKET_LIFE_CYCLE_ALL: 'TICKET_LIFE_CYCLE_ALL',
  TICKET_LIFE_CYCLE_DEFAULT: 'TICKET_LIFE_CYCLE_DEFAULT',
  TICKET_LIFE_CYCLE_EXTERNAL: 'TICKET_LIFE_CYCLE_EXTERNAL',

  // Other Actions
  EXPORT_UD_PAGINATION: 'EXPORT_UD_PAGINATION',
  EXPORT_U_CONFIG: 'EXPORT_U_CONFIG',
  USER_CRUD: 'USER_CRUD',
  AGENT_EVENT_CRUD: 'AGENT_EVENT_CRUD',
  TENANT_U_CONFIG: 'TENANT_U_CONFIG',
  USER_SWITCH_ROLE: 'USER_SWITCH_ROLE',
  NOTEBOOK_RU_ALL: 'NOTEBOOK_RU_ALL',
  NOTEBOOK_R_DEFAULT: 'NOTEBOOK_R_DEFAULT',
  FRONTEND_METADATA_CRUD: 'FRONTEND_METADATA_CRUD',

  // legal-i admin permission, do not give this to anybody!
  LEGALI_ADMIN: 'LEGALI_ADMIN',
} as const;

export type Permission = ObjectValues<typeof PERMISSIONS>;
