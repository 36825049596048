<template>
  <v-list v-if="suggestions.length > 0" :selected="[mutableSelected]" :header="!!header" @update:selected="mutableSelected = $event[0]">
    <v-list-subheader v-if="!!header" class="header">
      {{ header }}
    </v-list-subheader>
    <v-list-item
      v-for="(s, i) of suggestions"
      :key="i"
      :value="s.id"
      density="compact"
      data-testid="suggestion_item"
      :tabindex="-1"
      @click.stop="onClick(s)"
    >
      <template #prepend>
        <v-icon :color="getIconColor(s)" data-testid="suggestion_item_icon">
          {{ getIcon(s) }}
        </v-icon>
      </template>

      <v-list-item-title v-dompurify-html="getBold(s.suggestion)" data-testid="suggestion_item_title" />
      <v-list-item-subtitle v-if="hasSubtitle(s)" data-testid="suggestion_item_subtitle">
        {{ s.suggestionSubtitle }}
      </v-list-item-subtitle>

      <template #append>
        <div v-if="removable" @click.stop="onRemove(s)">
          <v-icon>mdi-close</v-icon>
        </div>
      </template>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { SearchSuggestion } from '@/case-detail/search/services/search.service';
import entityService from '@/common/services/entity.service';

export default defineComponent({
  props: {
    selected: {
      type: String as PropType<string | null>,
      default: null,
    },
    suggestions: {
      type: Array as PropType<SearchSuggestion[]>,
      required: true,
    },
    header: {
      type: String,
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String as PropType<string | null>,
      default: null,
    },
  },

  emits: ['update:selected', 'click:suggestion', 'click:remove'],

  data() {
    return {
      SEARCH_SUGGESTIONS: entityService.SEARCH_SUGGESTIONS,
    };
  },

  computed: {
    mutableSelected: {
      get() {
        return this.selected;
      },
      set(suggestion: string) {
        this.$emit('update:selected', suggestion);
      },
    },
  },

  methods: {
    getIcon(suggestion: SearchSuggestion) {
      return suggestion?.icon ?? this.SEARCH_SUGGESTIONS[suggestion.field].icon;
    },
    getIconColor(suggestion: SearchSuggestion) {
      return suggestion?.iconColor ?? this.SEARCH_SUGGESTIONS[suggestion.field].iconColor ?? '';
    },
    getBold(suggestionText: string) {
      if (this.query === null) {
        return suggestionText;
      }

      const suggestionTextString = suggestionText.toString();

      if (suggestionTextString.toLowerCase() === this.query.toLowerCase()) {
        return suggestionTextString;
      }

      if (suggestionTextString.toLowerCase().indexOf(this.query.toLowerCase()) === -1) {
        return `<b>${suggestionTextString}</b>`;
      }

      const startInclusive = suggestionTextString.toLowerCase().search(this.query.toLowerCase());
      const endInclusive = startInclusive + this.query.length - 1;
      const before = suggestionTextString.substring(0, startInclusive);
      const after = suggestionTextString.substring(Math.min(endInclusive + 1, suggestionTextString.length), suggestionTextString.length);
      return `<b>${before}</b>${this.query}<b>${after}</b>`;
    },
    hasSubtitle(suggestion: SearchSuggestion) {
      return 'suggestionSubtitle' in suggestion;
    },
    onClick(suggestion: SearchSuggestion) {
      this.$emit('click:suggestion', suggestion);
    },
    onRemove(suggestion: SearchSuggestion) {
      this.$emit('click:remove', suggestion);
    },
  },
});
</script>

<style lang="scss" scoped>
.header {
  height: 1.5rem;
}
</style>
