import OpenAPIClientAxios from 'openapi-client-axios';

import { config } from '@/app/config';
import { Client } from '@/common/generated-types/openapi';
import OPENAPI_DEFINITION from '@/common/generated-types/openapi.json';
import { authService } from '@/common/services/auth/auth.service';

// NOTE(dp): sometimes response is typed as 'any' (e.g. in createExport)
// looks like the issue is on openapicmd lib side because in .json schema there is correct response type
// needs fix

class OpenApiClientService {
  private apiInstance: OpenAPIClientAxios;

  constructor() {
    const definition = OPENAPI_DEFINITION;
    // @ts-expect-error something wrong with SegmentationRequest schema, ignored for now
    this.apiInstance = new OpenAPIClientAxios({ definition });
    this.apiInstance.withServer({ url: config.API.HOST, description: config.ENVIRONMENT });
  }

  public async init() {
    await this.apiInstance.init<Client>();
    this.apiInstance.instance.interceptors.response.use((response: any) => response, authService.responseErrorInterceptor.bind(authService));
    this.apiInstance.instance.interceptors.request.use(authService.jwtInterceptor.bind(authService), authService.errorInterceptor.bind(authService));
  }

  public async getClient(): Promise<Client> {
    return await this.apiInstance.getClient<Client>();
  }
}

export const openApiClientService = new OpenApiClientService();
