<template>
  <v-list v-if="suggestions.length > 0" :selected="[mutableSelected]" :header="!!header" @update:selected="mutableSelected = $event[0]">
    <v-list-subheader v-if="!!header" class="header">
      {{ header }}
    </v-list-subheader>

    <v-list-item v-for="(s, i) of suggestions" :key="i" :value="s.id" density="compact" @click.stop="onClick(s)">
      <template #prepend>
        <v-icon :color="getIconColor(s)">
          {{ getIcon(s) }}
        </v-icon>
      </template>

      <v-list-item-title v-dompurify-html="getBold(s.suggestion)" />
      <v-list-item-subtitle v-if="hasSubtitle(s)">
        {{ s.suggestionSubtitle }}
      </v-list-item-subtitle>
      <template #append>
        <div v-if="removable" @click.stop="onRemove(s)">
          <v-icon>mdi-close</v-icon>
        </div>
      </template>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
    },
    suggestions: {
      type: Array,
      required: true,
    },
    header: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'mdi-magnify',
    },
    iconColor: {
      type: String,
      default: '',
    },
    removable: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      default: null,
    },
  },
  emits: ['update:selected', 'click:suggestion', 'click:remove'],
  computed: {
    mutableSelected: {
      get() {
        return this.selected;
      },
      set(suggestion) {
        this.$emit('update:selected', suggestion);
      },
    },
  },
  methods: {
    getIcon(suggestion) {
      return suggestion?.icon ? suggestion.icon : this.icon;
    },
    getIconColor(suggestion) {
      return suggestion?.iconColor ? suggestion.iconColor : this.iconColor;
    },
    getBold(suggestionText) {
      if (this.query === null) {
        return suggestionText;
      }

      suggestionText = suggestionText.toString();

      if (suggestionText.toLowerCase() === this.query.toLowerCase()) {
        return suggestionText;
      }

      if (suggestionText.toLowerCase().indexOf(this.query.toLowerCase()) === -1) {
        return `<b>${suggestionText}</b>`;
      }
      const start = suggestionText.toLowerCase().search(this.query.toLowerCase());
      const end = start + this.query.length;
      const before = suggestionText.substring(0, start);
      const after = suggestionText.substring(Math.min(end, suggestionText.length - 1), suggestionText.length);
      return `<b>${before}</b>${this.query}<b>${after}</b>`;
    },
    hasSubtitle(suggestion) {
      return 'suggestionSubtitle' in suggestion;
    },
    onClick(suggestion) {
      this.$emit('click:suggestion', suggestion);
    },
    onRemove(suggestion) {
      this.$emit('click:remove', suggestion);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 1.5rem;
}
</style>
