export const highlightMatchedText = (text: string, searchQuery: string) => {
  if (!searchQuery) {
    return text;
  }

  const index = text.toLowerCase().indexOf(searchQuery.toLowerCase());
  if (index === -1) {
    return text;
  }

  const before = text.substring(0, index);
  const match = text.substring(index, index + searchQuery.length);
  const after = text.substring(index + searchQuery.length);

  return `${before}<span class="query-highlight">${match}</span>${after}`;
};
