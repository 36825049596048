import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import { websocketService } from '@/app/services/websocket.service';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { isEmptyHtmlContent } from '@/case-detail/services/editor.service';
import { ticketClient } from '@/case-detail/subviews/collaboration/services/ticket.client';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import detachedWindowService from '@/common/services/detached-window.service';
import { TicketEventName, TicketStatus, UserRoleId } from '@/common/services/entity.service';
import fileService from '@/common/services/file.service';
import userService from '@/common/services/users/user.service';
import { API } from '@/common/types/api.types';
import { TicketEventMetadata } from '@/common/types/api-types/ticket.api.types';
import { UUID } from '@/common/types/common.types';

export type TicketChatTab = 'correspondence' | 'internalChat';
export type TicketType = 'formal' | 'informal';

export type TicketParticipantRole = 'owner' | 'coordinator' | 'expert';

export interface ServiceState {
  newTicketView: boolean;
  selectedTicketId: UUID | null;
  selectedTicketNeedsUpdate: boolean;
  tickets: API.Ticket.Response[];
  initialized: boolean;
  detachedWindows: Window[];
  activeWsSubscriptionTopic: string | null;
}

const initialState: ServiceState = {
  newTicketView: false,
  selectedTicketId: null,
  selectedTicketNeedsUpdate: false,
  tickets: [],
  initialized: false,
  detachedWindows: [],
  activeWsSubscriptionTopic: null,
};

class TicketService {
  state: ServiceState;

  currentCase: API.LegalCase.Response | null = null;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
  }

  async init(curCase: API.LegalCase.Response) {
    if (!curCase || !curCase.id) {
      handleError($t('Tickets.invalidCase'));
      return;
    }
    this.currentCase = curCase;
    await this.loadTickets();
    this.subscribeToWebSocketEvents();
    this.state.initialized = true;
  }

  subscribeToWebSocketEvents() {
    if (this.state.activeWsSubscriptionTopic || !this.currentTenantId() || !this.currentCaseId()) {
      return;
    }
    this.state.activeWsSubscriptionTopic = `/topic/${this.currentTenantId()}/${this.currentCaseId()}/tickets`;
    websocketService.subscribeToTopic(this.state.activeWsSubscriptionTopic, (message) => {
      const parsedMessage = JSON.parse(message.body);
      const { ticketId } = parsedMessage;
      this.loadTickets();
      if (ticketId === this.state.selectedTicketId) {
        this.state.selectedTicketNeedsUpdate = true;
      }
    });
  }

  isInitialized() {
    return this.state.initialized;
  }

  currentTenantId() {
    return authService.state.data?.tenant.id as UUID;
  }

  currentCaseId() {
    return detailViewService.getCurrentLegalCaseId() as UUID;
  }

  async backToList() {
    this.state.newTicketView = false;
    this.state.selectedTicketId = null;
    await this.loadTickets();
  }

  getTickets() {
    return this.state.tickets.filter((t) => t.status !== 'ARCHIVED' && t.status !== 'CANCELLED');
  }

  getArchivedTickets() {
    return this.state.tickets.filter((t) => t.status === 'ARCHIVED');
  }

  getCancelledTickets() {
    return this.state.tickets.filter((t) => t.status === 'CANCELLED');
  }

  hasTickets() {
    return this.getTickets().length > 0;
  }

  async reloadSelectedTicket() {
    const { selectedTicketId } = this.state;
    if (!selectedTicketId) return;

    this.backToList();
    await this.loadTickets();
    this.setSelectedTicket(selectedTicketId);
  }

  setSelectedTicket(ticketId: UUID | null) {
    // try to find existing detached instance first
    if (ticketId) {
      for (const window of this.state.detachedWindows) {
        if (window.name.includes(ticketId)) {
          window.focus();
          return;
        }
      }
    }

    this.state.selectedTicketId = ticketId;

    // set url query params to be able reload page and keep the selected ticket opened
    if (ticketId) {
      detailViewRouteService.persistInQuery({ ticketId });
    } else {
      detailViewRouteService.cleanFromQuery('ticketId');
    }
  }

  openTicket(ticketId: UUID) {
    const currentCaseId = this.currentCaseId();
    const windowName = `collaborationTicketWindow_${currentCaseId}_${ticketId}`;
    if (detachedWindowService.getWindowReference(windowName)) {
      this.openDetachedView(currentCaseId, ticketId);
      return;
    }
    this.setSelectedTicket(ticketId);
  }

  async openDetachedView(currentCaseId: UUID, ticketId: UUID) {
    const url = `/c/${authService.state.data!.tenant.canonicalName}/ticket/${currentCaseId}/${ticketId}/${appService.state.contextId}`;
    const windowName = `collaborationTicketWindow_${currentCaseId}_${ticketId}`;

    const detached = await detachedWindowService.detachWindow(url, windowName);
    if (!detached) {
      return;
    }
    $a.l($a.e.COLLABORATION_TICKET_DETACH);
    this.backToList();
  }

  resetSelectedTicket() {
    this.state.selectedTicketId = null;
  }

  async loadTickets() {
    this.state.tickets = (await ticketClient.loadCaseTickets(this.currentCaseId())).toSorted((a, b) => dayjs(b.created).diff(dayjs(a.created)));
  }

  async loadTicket(ticketId: UUID) {
    return await ticketClient.loadTicket(this.currentCaseId(), ticketId);
  }

  async createTicket(ticket: API.Ticket.CreateRequest) {
    const ticketResponse = await ticketClient.createTicket(ticket, this.currentCaseId());

    if (ticketResponse) {
      this.state.tickets = [...this.state.tickets, ticketResponse];
      this.state.newTicketView = false;
      this.setSelectedTicket(ticketResponse.id);
    }

    return ticketResponse;
  }

  async emitEvent<E extends TicketEventName>(ticketId: UUID, event: E, userId: string, metadata: TicketEventMetadata[E]) {
    const ticketResponse = await ticketClient.emitEvent(this.currentCaseId(), ticketId, event, userId, metadata);

    const index = this.state.tickets.findIndex((t) => t.id === ticketId);
    this.state.tickets[index] = ticketResponse;

    return ticketResponse;
  }

  async updateMessage(ticketId: UUID, eventId: UUID, newMessage: string) {
    if (isEmptyHtmlContent(newMessage)) {
      return null;
    }
    const ticket = this.state.tickets.find((t) => t.id === ticketId);
    if (!ticket) {
      return null;
    }
    const event = ticket.events.find((e) => e.id === eventId);
    if (!event) {
      return null;
    }

    const ticketResponse = await ticketClient.updateMessage(this.currentCaseId(), ticketId, {
      ...event,
      metadata: { ...event.metadata, content: newMessage },
    });
    const index = this.state.tickets.findIndex((t) => t.id === ticketId);
    this.state.tickets[index] = ticketResponse;
    return ticketResponse;
  }

  async deleteMessage(ticketId: UUID, eventId: UUID) {
    const ticket = this.state.tickets.find((t) => t.id === ticketId);
    if (!ticket) {
      return null;
    }
    const event = ticket.events.find((e) => e.id === eventId);
    if (!event) {
      return null;
    }

    const ticketResponse = await ticketClient.deleteMessage(this.currentCaseId(), ticketId, eventId);
    const index = this.state.tickets.findIndex((t) => t.id === ticketId);
    this.state.tickets[index] = ticketResponse;
    return ticketResponse;
  }

  async deleteTicket(ticket: API.Ticket.Response) {
    const deleted = await ticketClient.deleteTicket(ticket);
    if (!deleted) return;

    this.backToList();
    this.state.tickets = [...this.state.tickets.filter((t) => t.id !== ticket.id)];
  }

  isActiveTicket(ticketStatus: TicketStatus) {
    return ticketStatus !== 'ANSWERED' && ticketStatus !== 'ARCHIVED';
  }

  async destroy() {
    this.state.selectedTicketId = null;
    this.state.tickets = [];
    this.state.initialized = false;
    this.currentCase = null;
    if (this.state.activeWsSubscriptionTopic) {
      websocketService.unsubscribeFromTopic(this.state.activeWsSubscriptionTopic);
      this.state.activeWsSubscriptionTopic = null;
    }
  }

  // FILES

  async addAttachments(ticketId: UUID, fieldId: 'question' | 'answer' | UUID, files: File[], emitEvent?: boolean, userId?: string) {
    await Promise.all(
      files.map(async (f) => {
        const attachment = await ticketClient.addAttachment(this.currentCaseId(), ticketId, fieldId, f);
        if (emitEvent && userId) {
          await this.emitEvent(ticketId, 'ADD_ATTACHMENT', userId, {
            fileId: attachment.id,
            filename: attachment.description,
            ticketFieldReference: fieldId,
          });
        }
      }),
    );
  }

  async deleteAttachment(ticketId: UUID, attachment: API.Ticket.Attachment, emitEvent?: boolean, userId?: string) {
    await ticketClient.deleteAttachment(this.currentCaseId(), ticketId, attachment);
    if (emitEvent && userId) {
      await this.emitEvent(ticketId, 'DELETE_ATTACHMENT', userId, {
        fileId: attachment.id,
        filename: attachment.description,
        ticketFieldReference: attachment.ticketFieldReference,
      });
    }
  }

  async downloadPdf(attachment: API.Ticket.Attachment) {
    appService.info($t('Common.File.preparingDownload'));
    fileService.download(attachment.fileUri, attachment.description);
  }

  getAllowedMediaTypes() {
    return 'application/vnd.ms-excel,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  }

  getFileIcon(filetype: string) {
    switch (filetype) {
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'excel'; // NOTE(dp): also we have slightly different `xls`

      case 'application/pdf':
        return 'pdf';

      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'word'; //  NOTE(dp): also we have slightly different `doc`

      case 'application/zip':
        return 'zip';

      default:
        return 'file';
    }
  }

  validateFiles(files: File[]) {
    const allowedMediaTypes = this.getAllowedMediaTypes().split(',');
    const invalidFiles = files?.filter((f) => !allowedMediaTypes.includes(f?.type));
    if (invalidFiles && invalidFiles?.length > 0) {
      handleError($t('Tickets.invalidFileTypes', [invalidFiles.map((f) => f.name).join(',')]));
      return false;
    }
    return true;
  }

  isCoordinativeRole(role: UserRoleId | undefined): boolean {
    return role === 'external:organisation' || role === 'external:coordinator';
  }

  getOrganisationUserIdforUser(userId: string | undefined): string {
    const user = userService.getUser(userId);
    const users = userService.getUsers();
    const orgUser = users.find((u) => user?.homeTenantId === u.homeTenantId && u.role === 'external:organisation');
    return orgUser?.user_id || '';
  }
}

export const ticketService = new TicketService();
export const TicketServiceClass = TicketService;
