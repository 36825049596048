<template>
  <v-dialog v-model="appService.state.testEmailDialog" max-width="650px" persistent>
    <v-card>
      <v-card-title class="text-h6">Send test email notifications</v-card-title>

      <v-card-text>
        <!-- Recipient Autocomplete -->
        <v-autocomplete
          v-model="selectedUserId"
          v-model:search-input="searchRecipient"
          :items="recipientOptions"
          item-title="name"
          item-value="user_id"
          :label="$t('Tickets.recipientFilterLabel')"
          :placeholder="$t('LegaliAdmin.Test.NotificationTest.nameFiledPlaceholder')"
          clearable
          hide-details
          :custom-filter="customFilter"
        >
          <template #item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.raw.email" :title="item.raw.name"></v-list-item>
          </template>
        </v-autocomplete>

        <!-- Locale Autocomplete -->
        <v-autocomplete
          v-model="form.locale"
          v-model:search-input="searchLocale"
          :items="localeOptions"
          item-title=""
          :item-value="(ListItem: Locale) => ListItem.toString('_')"
          :label="$t('LegaliAdmin.Test.NotificationTest.localeFieldLabel')"
          :placeholder="$t('LegaliAdmin.Test.NotificationTest.localeFieldPlaceholder')"
          clearable
          hide-details
          :custom-filter="customFilter"
        ></v-autocomplete>

        <!-- Notification Key Dropdown -->
        <v-select
          v-model="form.notificationKey"
          :items="notificationKeys"
          label="Notification Key"
          placeholder="Select Notification Key"
          clearable
          hide-details
        ></v-select>

        <!-- Divider -->
        <v-divider class="mt-4"></v-divider>

        <!-- Optional Override Email Field with Toggle -->
        <div class="d-flex align-center">
          <v-text-field
            v-model="emailListString"
            :label="$t('LegaliAdmin.Test.NotificationTest.emailTextFieldLabel')"
            :disabled="!enableOverrideEmail"
            :hint="$t('LegaliAdmin.Test.NotificationTest.emailTextFieldHint')"
            :persistent-hint="!enableOverrideEmail"
            hide-details
          ></v-text-field>
          <v-switch
            v-model="enableOverrideEmail"
            v-tippy="$t('LegaliAdmin.Test.NotificationTest.overwriteEmailTooltip')"
            inset
            hide-details
            color="primary"
            class="pl-4"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" variant="text" @click="closeDialog">Cancel</v-btn>
        <v-btn color="primary" varian="flat" @click="submitForm">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { i18nService } from '@/app/i18n/i18n.service';
import { Locale } from '@/app/i18n/locale';
import appService from '@/app/services/app.service';
import { TestEmailNotificationRequest } from '@/common/generated-types/openapi';
import entityService from '@/common/services/entity.service';
import { openApiClientService } from '@/common/services/openapi.client.service';
import userService from '@/common/services/users/user.service';

export default defineComponent({
  name: 'TestEmailNotificationDialog',
  data() {
    return {
      appService,
      selectedUserId: null,
      form: {
        recipientId: '',
        locale: Locale.default.toString('_') as unknown as TestEmailNotificationRequest['locale'],
        notificationKey: 'CASE_READY',
        recipientEmailAddresses: [],
      } as TestEmailNotificationRequest,

      emailListString: '',
      localeOptions: i18nService.supportedLocales(),
      notificationKeys: Object.keys(entityService.NOTIFICATION_MESSAGES),

      // Search inputs for free typing
      searchRecipient: '',
      searchLocale: '',
      searchEmail: '',

      // Optional override email
      enableOverrideEmail: false,
    };
  },

  computed: {
    recipientOptions() {
      return userService.state.users;
    },
  },

  watch: {
    selectedUserId: {
      handler(userId) {
        if (userId) {
          const user = userService.getUser(userId);
          this.form.recipientId = user?.user_id ?? '';
          this.form.locale = Locale.fromString(user?.userDetails?.locale ?? '').toString('_');
          this.emailListString = user?.email ?? '';
          this.form.recipientEmailAddresses.push(user?.email ?? '');
        }
      },
    },
  },

  methods: {
    // Custom filter to allow free text and matching
    customFilter(item: string, queryText: string): boolean {
      const text = item.toLowerCase();
      const query = queryText.toLowerCase();
      return text.includes(query);
    },

    // Submit handler
    async submitForm() {
      if (this.enableOverrideEmail && this.emailListString) {
        this.form.recipientEmailAddresses = this.emailListString.split(',').map((email: string) => email.trim());
      }
      const payload = {
        ...this.form,
      } as TestEmailNotificationRequest;

      const client = await openApiClientService.getClient();
      try {
        await client.sendTestEmail(null, payload);
      } catch (error) {
        handleError(this.$t('LegaliAdmin.Test.NotificationTest.errorMessage'), error);
      }

      this.resetForm(); // Clear form after submission
      this.appService.state.testEmailDialog = false; // Close dialog
    },

    // Cancel/close dialog
    closeDialog() {
      this.appService.state.testEmailDialog = false;
      this.resetForm(); // Reset form when canceled
    },

    // Reset form data
    resetForm() {
      this.form = {
        recipientId: '',
        locale: Locale.default.toString('_') as unknown as TestEmailNotificationRequest['locale'],
        notificationKey: 'CASE_READY',
        recipientEmailAddresses: [],
      };
      this.searchRecipient = '';
      this.searchLocale = '';
      this.searchEmail = '';
      this.enableOverrideEmail = false;
      this.selectedUserId = null;
      this.emailListString = '';
    },
  },
});
</script>

<style scoped></style>
