<template>
  <div :class="dropzoneClass" @mouseleave="showDropZone(false)" @drop.prevent="dropFile" @dragover.prevent="showDropZone(true)">
    <v-progress-linear v-if="isLoading" indeterminate rounded />
    <v-progress-linear v-else-if="isUploading" indeterminate rounded />
    <v-file-input
      v-else
      ref="fileInput"
      v-model="localFiles"
      accept=".pdf"
      counter
      :hint="$t('CaseList.AddEditCase.placeFilesHere')"
      :label="$t('CaseList.AddEditCase.documentsRequirement', [$n(PDF_PAGE_LIMIT)])"
      data-testid="input_file"
      multiple
      @change="onInputChange"
      @click:clear="
        newSourceFile.originalFileUris = [];
        newSourceFile.uploadFilenames = [];
        invalidFiles = [];
      "
    >
      <template #selection="{ fileNames }">
        <div class="d-flex align-center flex-wrap ga-1">
          <template v-for="(fileName, index) in fileNames" :key="fileName">
            <v-chip v-if="index < 3" class="me-2" color="primary" label size="small">
              {{ fileName }}
            </v-chip>
            <span v-else-if="index === 3" class="text-body-2 mx-2">{{ $t('CaseList.AddEditCase.restOfFiles', [$n(localFiles.length - 3)]) }}</span>
          </template>
        </div>
      </template>
    </v-file-input>
    <div v-if="invalidFiles.length > 0 && !isUploading" class="pl-8 text-body-2 text-error">
      {{ $t('CaseList.AddEditCase.documentsInvalid') }}
      <ul>
        <li v-for="file in invalidFiles" :key="file.name">
          <InteractiveTooltip
            v-if="file.error.includes($n(2000))"
            :title="$t('CaseList.AddEditCase.tooltipTitle')"
            :text="$t('CaseList.AddEditCase.tooltipText', [$n(PDF_PAGE_LIMIT)])"
            :button-text="$t('CaseList.AddEditCase.tooltipBtnText')"
            article-id="10256145"
            placement="bottom"
          >
            <strong>{{ file.name }}: </strong> {{ file.error }}
          </InteractiveTooltip>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { PDFDocument } from 'pdf-lib';
import { defineComponent } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import appService from '@/app/services/app.service';
import InteractiveTooltip from '@/common/components/tooltip/components/InteractiveTooltip.vue';
import fileService from '@/common/services/file.service';

export const PDF_PAGE_LIMIT = 2000;

export interface FileInfo {
  name: string;
  numPages: number;
  encrypted: boolean;
  error: string;
}

export default defineComponent({
  components: {
    InteractiveTooltip,
  },
  props: {
    newSourceFile: {
      type: Object,
      required: true,
    },
  },

  emits: ['uploading', 'error'],

  data() {
    return {
      isLoading: false as boolean,
      isUploading: false as boolean,
      localFiles: [] as File[],
      uploadPercentage: 0 as number,
      dropzoneClass: '' as string,
      fileInputErrorMessages: [] as string[],
      invalidFiles: [] as FileInfo[],
      PDF_PAGE_LIMIT,
    };
  },

  watch: {
    newSourceFile(to) {
      // NOTE: no event is trigged on dialog close, therefore watch prop change.
      if (to.uploadFilenames.length === 0) {
        this.localFiles = [];
        this.isUploading = false;
      }
    },
  },

  methods: {
    async uploadFile(files: File[]) {
      if (!files) return;

      this.fileInputErrorMessages = [];

      if (files.length > 100) {
        appService.error(this.$t('CaseList.AddEditCase.documentsRequirement'));
        this.newSourceFile.originalFileUris = [];
        this.newSourceFile.uploadFilenames = [];
        this.localFiles = [];
        return;
      }
      // init arrays if necessary
      if (!this.newSourceFile.originalFileUris) {
        this.newSourceFile.originalFileUris = [];
      }
      if (!this.newSourceFile.uploadFilenames) {
        this.newSourceFile.uploadFilenames = [];
      }
      if (!files) return;

      this.isLoading = true;

      // validate PDFs
      this.invalidFiles = (await Promise.all(files.map((file) => this.validateFile(file)))).filter((fileInfo) => fileInfo.error);

      // filter files that are not valid
      this.localFiles = this.localFiles.filter((file) => !this.invalidFiles.some((invalidFile) => invalidFile.name === file.name));
      const filesToUpload = files.filter((file) => !this.invalidFiles.some((invalidFile) => invalidFile.name === file.name));

      this.isLoading = false;
      this.isUploading = true;
      this.$emit('uploading', this.isUploading);
      for (let i = 0; i < filesToUpload.length; i++) {
        try {
          const presignedUploadURIResponse = await fileService.upload(filesToUpload[i]);
          this.newSourceFile.originalFileUris.push(presignedUploadURIResponse.tempFilename);
          this.newSourceFile.uploadFilenames.push(filesToUpload[i].name);
        } catch (e) {
          this.newSourceFile.originalFileUris = [];
          this.newSourceFile.uploadFilenames = [];
          this.localFiles = [];

          this.uploadPercentage = 100;
          this.isUploading = false;
          this.$emit('uploading', this.isUploading);

          handleError(this.$t('CaseList.AddEditCase.filesCanNotBeUploaded', [files[i].name]), e);
          return;
        }
      }

      this.isUploading = false;
      this.$emit('uploading', this.isUploading);
      this.uploadPercentage = 100;
    },
    async validateFile(file: File): Promise<FileInfo> {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = async () => {
          const typedArray = new Uint8Array(fileReader.result as ArrayBuffer);
          const fileInfo: FileInfo = {
            name: file.name,
            numPages: 0,
            encrypted: false,
            error: '',
          };

          try {
            const pdf = await PDFDocument.load(typedArray, { ignoreEncryption: true });
            fileInfo.numPages = pdf.getPageCount();
            fileInfo.encrypted = pdf.isEncrypted;

            if (pdf.isEncrypted) {
              fileInfo.error = this.$t('CaseList.AddEditCase.documentProtected', [file.name]);
            } else if (fileInfo.numPages > PDF_PAGE_LIMIT) {
              fileInfo.error = this.$t('CaseList.AddEditCase.documentPageLimit', [this.$n(PDF_PAGE_LIMIT)]);
            }
          } catch (error) {
            fileInfo.error = this.$t('CaseList.AddEditCase.documentCorrupted', [file.name]);
          }

          resolve(fileInfo);
        };

        fileReader.readAsArrayBuffer(file);
      });
    },
    onInputChange(event: Event) {
      const { files } = event.target as HTMLInputElement;
      if (files) {
        this.uploadFile([...files]);
      }
    },
    showDropZone(e: boolean) {
      this.dropzoneClass = e ? 'dropzone' : '';
    },
    dropFile(e: DragEvent) {
      this.showDropZone(false);
      const droppedFiles = e.dataTransfer?.files;

      if (!droppedFiles || droppedFiles.length === 0) return;
      const files: File[] = [];
      for (let i = 0; i < droppedFiles.length; i++) {
        if (droppedFiles[i].type === 'application/pdf') {
          files.push(droppedFiles[i]);
        } else {
          appService.info(this.$t('CaseList.AddEditCase.onlyPDFsAreSupported'));
        }
      }
      if (files.length === 0) return;
      if (files.length > 100) {
        appService.error(this.$t('CaseList.AddEditCase.documentsRequirement'));
        this.localFiles = [];
        this.newSourceFile.originalFileUris = [];
        this.newSourceFile.uploadFilenames = [];
        return;
      }
      this.localFiles = [...this.localFiles, ...files];
      this.uploadFile(files);
    },
  },
});
</script>

<style lang="scss" scoped>
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.dropzone {
  background-color: rgb(var(--v-theme-surfaceVariant));
  border: 1px solid rgb(var(--v-theme-primary));
}
</style>
