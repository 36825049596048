import { cloneDeep } from 'lodash';
import { reactive } from 'vue';

import { broadcastEventBus } from '@/common/services/broadcast.service';
import preferencesService from '@/common/services/preferences.service';
import { API } from '@/common/types/api.types';

interface ServiceState {
  sorting: API.LegalCase.ListSorting;
}

const initialState: ServiceState = {
  sorting: 'TITLE_ASC',
};

interface SortMode {
  icon: string;
  sortFn: (a: API.LegalCase.ListResponse, b: API.LegalCase.ListResponse) => number;
}

const compareAndPunishEmpty = (a: string, b: string) => {
  if (a === '' && b !== '') return 1;
  if (b === '' && a !== '') return -1;
  return a.localeCompare(b);
};

const sortModes: Record<API.LegalCase.ListSorting, SortMode> = {
  LAST_FIRST_NAME_ASC: {
    icon: 'mdi-sort-alphabetical-ascending',
    sortFn: (a: API.LegalCase.ListResponse, b: API.LegalCase.ListResponse) => {
      const [aLast, bLast] = [a.pii.PII_LASTNAME.value, b.pii.PII_LASTNAME.value];
      const [aFirst, bFirst] = [a.pii.PII_FIRSTNAME.value, b.pii.PII_FIRSTNAME.value];
      const [aLabel, bLabel] = [a.displayLabel, b.displayLabel];
      return compareAndPunishEmpty(aLast, bLast) || compareAndPunishEmpty(aFirst, bFirst) || compareAndPunishEmpty(aLabel, bLabel);
    },
  },
  // First name, Last last
  TITLE_ASC: {
    icon: 'mdi-sort-alphabetical-ascending',
    sortFn: (a: API.LegalCase.ListResponse, b: API.LegalCase.ListResponse) => {
      const [aLast, bLast] = [a.pii.PII_LASTNAME.value, b.pii.PII_LASTNAME.value];
      const [aFirst, bFirst] = [a.pii.PII_FIRSTNAME.value, b.pii.PII_FIRSTNAME.value];
      const [aLabel, bLabel] = [a.displayLabel, b.displayLabel];
      return compareAndPunishEmpty(aFirst, bFirst) || compareAndPunishEmpty(aLast, bLast) || compareAndPunishEmpty(aLabel, bLabel);
    },
  },
  LAST_UPDATE_DESC: {
    icon: 'mdi-sort-clock-descending',
    sortFn: (a: API.LegalCase.ListResponse, b: API.LegalCase.ListResponse) => {
      if (a.updated && b.updated) {
        return b.updated.localeCompare(a.updated);
      }
      if (!a.updated && b.updated) {
        return 1;
      }
      if (a.updated && !b.updated) {
        return -1;
      }
      return 0;
    },
  },
  REF_ASC: {
    icon: 'mdi-numeric',
    sortFn: (a: API.LegalCase.ListResponse, b: API.LegalCase.ListResponse) => compareAndPunishEmpty(a.reference, b.reference),
  },
};

class LegalCaseSortService {
  state: ServiceState;

  sortModes = sortModes;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
    preferencesService.runAfterLoad(() => this.loadSavedSorting());
  }

  loadSavedSorting() {
    this.state.sorting = preferencesService.state.caseListPreferences?.caseListSortMode as API.LegalCase.ListSorting;
  }

  getSorting() {
    return this.state.sorting;
  }

  setSorting(value: API.LegalCase.ListSorting) {
    this.state.sorting = value;
    preferencesService.updatePreferences({ caseListPreferences: { caseListSortMode: value } });
    broadcastEventBus.emit('LEGALCASE_SORT_CHANGE_EVENT', {});
  }

  getSortedCases(cases: API.LegalCase.ListResponse[]) {
    return cases.toSorted((a, b) => this.sortFn(a, b));
  }

  sortFn(a: API.LegalCase.ListResponse, b: API.LegalCase.ListResponse) {
    const { sortFn } = sortModes[this.state.sorting];
    return sortFn(a, b);
  }
}

export default new LegalCaseSortService();
export const LegalCaseServiceClass = LegalCaseSortService;
