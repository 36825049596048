<template>
  <div class="d-flex flex-column interactive-tooltip-content pa-1">
    <div v-if="title" class="text-h6">{{ title }}</div>
    <div class="text-body-1 py-2">{{ text }}</div>
    <div
      v-if="showButton"
      type="button"
      class="v-btn v-theme--light bg-primary v-btn--density-default v-btn--size-small v-btn--variant-flat flex-grow-1"
      @click="$emit('button-click')"
    >
      {{ buttonText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['button-click'],

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.interactive-tooltip-content {
  min-width: 200px;
  max-width: 350px;
}
</style>
